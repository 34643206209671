export const WORDS = [
  "adega",
  "aptas",
  "tinta",
  "falso",
  "picos",
  "atuar",
  "pista",
  "burro",
  "fazer",
  "quiçá",
  "pizza",
  "quina",
  "chãos",
  "guião",
  "pinta",
  "banal",
  "corpo",
  "deter",
  "tênue",
  "abono",
  "fluxo",
  "aliás",
  "pavio",
  "limão",
  "ávido",
  "caqui",
  "finos",
  "obter",
  "óvulo",
  "graus",
  "líder",
  "cisão",
  "estes",
  "suíte",
  "hidro",
  "super",
  "sinto",
  "lábio",
  "gruta",
  "tonta",
  "magro",
  "festa",
  "pagam",
  "grupo",
  "geral",
  "placa",
  "telas",
  "pouca",
  "atual",
  "poder",
  "assim",
  "inata",
  "germe",
  "bossa",
  "presa",
  "torta",
  "lento",
  "criar",
  "tórax",
  "onças",
  "abadá",
  "tufão",
  "mulas",
  "ruína",
  "beber",
  "boina",
  "polpa",
  "magia",
  "nessa",
  "crase",
  "pinho",
  "gordo",
  "cocos",
  "bazar",
  "capim",
  "moção",
  "lemes",
  "logar",
  "santo",
  "tosar",
  "latão",
  "latas",
  "ginga",
  "nível",
  "dessa",
  "limbo",
  "honra",
  "notar",
  "maior",
  "caspa",
  "euros",
  "grade",
  "pagão",
  "clone",
  "comum",
  "ritmo",
  "verde",
  "leiga",
  "braço",
  "mares",
  "secos",
  "sogra",
  "lousa",
  "ótimo",
  "sócia",
  "parma",
  "preta",
  "maçãs",
  "ninja",
  "fosca",
  "dever",
  "tanga",
  "unida",
  "coifa",
  "todos",
  "gêmea",
  "gatas",
  "malta",
  "trupe",
  "pulga",
  "homem",
  "untar",
  "irado",
  "secar",
  "fungo",
  "rojão",
  "surdo",
  "lábia",
  "pilha",
  "monge",
  "bolos",
  "selar",
  "calos",
  "atriz",
  "bônus",
  "areia",
  "skate",
  "gerar",
  "devir",
  "cegos",
  "exame",
  "fugir",
  "souto",
  "ouvir",
  "aluno",
  "voraz",
  "vasos",
  "verbo",
  "padre",
  "netos",
  "canal",
  "pazes",
  "antes",
  "crepe",
  "corré",
  "crime",
  "assis",
  "milho",
  "meios",
  "tédio",
  "topar",
  "vagão",
  "metal",
  "lenda",
  "lomba",
  "puras",
  "duque",
  "clima",
  "terça",
  "débil",
  "usina",
  "choça",
  "belas",
  "aliar",
  "nudez",
  "rival",
  "drama",
  "treze",
  "fruta",
  "temor",
  "piano",
  "cosmo",
  "ninar",
  "bruxo",
  "babar",
  "magra",
  "aguda",
  "leigo",
  "fácil",
  "colar",
  "tempo",
  "mitos",
  "ratos",
  "chave",
  "hindu",
  "incas",
  "nulos",
  "opção",
  "obeso",
  "gorda",
  "caixa",
  "pólen",
  "bocal",
  "preto",
  "adiar",
  "liceu",
  "grama",
  "raras",
  "fraco",
  "sarro",
  "misto",
  "ralar",
  "mesas",
  "cueca",
  "harpa",
  "girar",
  "vagos",
  "magna",
  "vocês",
  "gripe",
  "luvas",
  "frear",
  "calvo",
  "costa",
  "almas",
  "fedor",
  "vagar",
  "chuva",
  "macho",
  "lados",
  "sacos",
  "fêmea",
  "flora",
  "nervo",
  "pódio",
  "lagos",
  "vinte",
  "carta",
  "média",
  "babás",
  "feita",
  "micro",
  "gelar",
  "arder",
  "pesar",
  "naval",
  "esqui",
  "ações",
  "damas",
  "varal",
  "balde",
  "acida",
  "bucho",
  "fêmur",
  "torto",
  "caros",
  "tabus",
  "tiros",
  "mamãe",
  "papai",
  "tênis",
  "rimar",
  "bifes",
  "bacia",
  "reler",
  "horto",
  "xampu",
  "cujos",
  "ilesa",
  "dados",
  "nadar",
  "mimos",
  "musgo",
  "karma",
  "copas",
  "ervas",
  "figos",
  "ramos",
  "ávida",
  "viver",
  "brisa",
  "dogma",
  "feios",
  "fofas",
  "melão",
  "miúda",
  "areal",
  "pisar",
  "recém",
  "rasos",
  "fatal",
  "optar",
  "suave",
  "fruto",
  "árduo",
  "ponto",
  "vírus",
  "vetar",
  "tensa",
  "somar",
  "muita",
  "bumba",
  "feliz",
  "burra",
  "anéis",
  "noção",
  "desta",
  "cílio",
  "nobre",
  "rezar",
  "sujar",
  "sutiã",
  "erros",
  "ricas",
  "série",
  "malte",
  "árdua",
  "alhos",
  "lidos",
  "laico",
  "jejum",
  "tenso",
  "marte",
  "leais",
  "alçar",
  "mamar",
  "ótico",
  "lotar",
  "frevo",
  "barco",
  "lesão",
  "sujos",
  "lúmen",
  "bambu",
  "cinza",
  "errar",
  "tutor",
  "botar",
  "aluna",
  "nesta",
  "tocar",
  "cones",
  "grata",
  "saída",
  "guiar",
  "vazar",
  "puros",
  "úteis",
  "trevo",
  "panda",
  "metro",
  "coeso",
  "picar",
  "amada",
  "cabal",
  "fiapo",
  "bocão",
  "fuzuê",
  "furor",
  "pires",
  "curar",
  "folia",
  "algas",
  "altar",
  "fumar",
  "tripé",
  "forte",
  "praia",
  "sírio",
  "sinos",
  "mundo",
  "pecar",
  "corja",
  "ótima",
  "lixar",
  "idoso",
  "carma",
  "fossa",
  "caras",
  "negar",
  "lírio",
  "dunas",
  "coral",
  "toldo",
  "delta",
  "bingo",
  "golfe",
  "farda",
  "pagos",
  "acaso",
  "aquém",
  "sueca",
  "matos",
  "fusca",
  "ração",
  "teias",
  "horta",
  "ameno",
  "mercê",
  "naves",
  "cervo",
  "patos",
  "dutos",
  "certa",
  "viril",
  "gambá",
  "retro",
  "áurea",
  "cupim",
  "febre",
  "autos",
  "valsa",
  "norte",
  "ligar",
  "cerne",
  "ídolo",
  "juízo",
  "mudos",
  "filho",
  "trios",
  "juíza",
  "perto",
  "talco",
  "sunga",
  "russa",
  "votar",
  "herói",
  "muito",
  "sueco",
  "turca",
  "feudo",
  "major",
  "ciúme",
  "goela",
  "marra",
  "laços",
  "reger",
  "juros",
  "abrir",
  "feroz",
  "moída",
  "modos",
  "nozes",
  "sócio",
  "moeda",
  "roçar",
  "tinto",
  "dedos",
  "rasas",
  "sedes",
  "cenas",
  "salsa",
  "estas",
  "reúso",
  "telão",
  "refil",
  "plena",
  "comer",
  "amplo",
  "lidar",
  "fator",
  "armar",
  "trapo",
  "texto",
  "opaca",
  "revés",
  "árido",
  "ético",
  "fórum",
  "dieta",
  "nomes",
  "tribo",
  "êxito",
  "mídia",
  "bruto",
  "belos",
  "sódio",
  "tubos",
  "ópera",
  "salas",
  "ombro",
  "roupa",
  "mútuo",
  "fluir",
  "vossa",
  "vacas",
  "boato",
  "bater",
  "bando",
  "galho",
  "valor",
  "navio",
  "rolar",
  "cubos",
  "tacos",
  "verme",
  "ritos",
  "autor",
  "deusa",
  "bares",
  "turnê",
  "ousar",
  "polir",
  "dizer",
  "vôlei",
  "loiro",
  "letal",
  "calma",
  "hiper",
  "solos",
  "ecoar",
  "impor",
  "museu",
  "vespa",
  "úmida",
  "mirar",
  "ostra",
  "lorde",
  "facão",
  "sábio",
  "bucal",
  "couro",
  "depor",
  "grego",
  "nossa",
  "fugas",
  "cânon",
  "manso",
  "domar",
  "bucha",
  "litro",
  "míope",
  "olhão",
  "amena",
  "ceder",
  "vidas",
  "remar",
  "civil",
  "aroma",
  "gotas",
  "frase",
  "nasal",
  "balão",
  "sauna",
  "bolha",
  "bamba",
  "sadio",
  "cairo",
  "gafes",
  "clica",
  "votos",
  "açude",
  "miolo",
  "terço",
  "favor",
  "surda",
  "antas",
  "medir",
  "sogro",
  "zíper",
  "selim",
  "clara",
  "feras",
  "outra",
  "meses",
  "aérea",
  "abril",
  "banco",
  "louco",
  "alpes",
  "dedão",
  "lição",
  "fogão",
  "vácuo",
  "brasa",
  "todas",
  "longa",
  "lisos",
  "névoa",
  "iscas",
  "peixe",
  "linho",
  "mural",
  "tíbia",
  "pleno",
  "coçar",
  "modas",
  "legal",
  "cedro",
  "irmão",
  "conde",
  "fútil",
  "furar",
  "disso",
  "tetra",
  "turfe",
  "sumir",
  "gueto",
  "porco",
  "longe",
  "galão",
  "outro",
  "usual",
  "bunda",
  "memes",
  "panos",
  "sarja",
  "punho",
  "poças",
  "ofurô",
  "bravo",
  "pinos",
  "ponte",
  "anual",
  "signo",
  "calar",
  "roxas",
  "pelos",
  "tiara",
  "atlas",
  "vulto",
  "breve",
  "poema",
  "camas",
  "advir",
  "rubro",
  "êxodo",
  "novos",
  "libra",
  "penca",
  "amora",
  "vapor",
  "redes",
  "vilão",
  "anzol",
  "murro",
  "letra",
  "dólar",
  "amido",
  "cofre",
  "cesto",
  "bomba",
  "golfo",
  "pular",
  "óssea",
  "ânimo",
  "lagoa",
  "imune",
  "horas",
  "sagaz",
  "loção",
  "lajes",
  "retas",
  "musas",
  "sacar",
  "índia",
  "sexto",
  "feira",
  "casal",
  "arcos",
  "ontem",
  "ganso",
  "longo",
  "rural",
  "olhar",
  "cubas",
  "pampa",
  "globo",
  "perua",
  "selva",
  "leito",
  "trama",
  "rosto",
  "servo",
  "fadas",
  "aulas",
  "lótus",
  "nevar",
  "caçar",
  "jogar",
  "dupla",
  "prece",
  "ágeis",
  "cauda",
  "brega",
  "fados",
  "moela",
  "lerdo",
  "touca",
  "natas",
  "puxar",
  "dardo",
  "túnel",
  "áudio",
  "gibis",
  "ileso",
  "meros",
  "furos",
  "praça",
  "chapa",
  "cópia",
  "surfe",
  "obras",
  "raiar",
  "atroz",
  "sítio",
  "óvnis",
  "latim",
  "dotar",
  "citar",
  "olhos",
  "regar",
  "salmo",
  "arena",
  "pagar",
  "treco",
  "unção",
  "etnia",
  "malas",
  "polvo",
  "novas",
  "aveia",
  "vasto",
  "vovós",
  "russo",
  "lavar",
  "mudar",
  "meias",
  "patas",
  "joias",
  "alvos",
  "giros",
  "rente",
  "nariz",
  "usada",
  "tecer",
  "vício",
  "trenó",
  "papos",
  "duplo",
  "ambos",
  "odiar",
  "velha",
  "bodas",
  "visão",
  "carga",
  "fixar",
  "garra",
  "bobos",
  "setor",
  "esses",
  "louça",
  "bicho",
  "bobas",
  "vinho",
  "lonas",
  "golpe",
  "safra",
  "meigo",
  "benta",
  "arroz",
  "neném",
  "lojas",
  "tchau",
  "sabiá",
  "calda",
  "chalé",
  "moços",
  "suíno",
  "loira",
  "órfão",
  "couve",
  "falir",
  "galos",
  "ímpio",
  "podre",
  "gaita",
  "carne",
  "mecha",
  "daqui",
  "nosso",
  "vazio",
  "prumo",
  "frias",
  "lombo",
  "índio",
  "fotos",
  "épico",
  "águia",
  "ósseo",
  "pisos",
  "civis",
  "gosma",
  "casar",
  "nação",
  "veloz",
  "lisas",
  "algum",
  "potes",
  "doida",
  "saber",
  "justo",
  "jeito",
  "bicos",
  "rolha",
  "geada",
  "refém",
  "raios",
  "manta",
  "casta",
  "local",
  "capão",
  "garça",
  "aéreo",
  "sósia",
  "arame",
  "dique",
  "sabão",
  "elfos",
  "raças",
  "tumba",
  "pipas",
  "sanar",
  "ninfa",
  "brejo",
  "manhã",
  "molas",
  "porém",
  "itens",
  "fobia",
  "julho",
  "crise",
  "vinda",
  "visar",
  "prova",
  "ruiva",
  "vulgo",
  "ondas",
  "fúria",
  "brito",
  "hífen",
  "gêmeo",
  "flúor",
  "menos",
  "times",
  "rever",
  "telha",
  "humor",
  "treta",
  "vídeo",
  "teste",
  "arcar",
  "berço",
  "leste",
  "cárie",
  "folha",
  "fixos",
  "tábua",
  "tocha",
  "ruins",
  "nuvem",
  "zonas",
  "poços",
  "vênus",
  "sarna",
  "aonde",
  "tomar",
  "teses",
  "lugar",
  "palma",
  "preço",
  "pulos",
  "sarau",
  "sarda",
  "bolso",
  "rotor",
  "cível",
  "filha",
  "rouca",
  "besta",
  "fugaz",
  "óbvio",
  "clero",
  "densa",
  "durar",
  "bolão",
  "combo",
  "frade",
  "barba",
  "seita",
  "gerir",
  "etapa",
  "punir",
  "truco",
  "veias",
  "casco",
  "jegue",
  "grãos",
  "selos",
  "hábil",
  "vivos",
  "terno",
  "mosca",
  "oásis",
  "caule",
  "unhas",
  "único",
  "dúzia",
  "macro",
  "papel",
  "plebe",
  "posse",
  "focal",
  "bruta",
  "robôs",
  "sabor",
  "acima",
  "rocha",
  "sacra",
  "lindo",
  "pavão",
  "sigla",
  "touro",
  "justa",
  "cetim",
  "caída",
  "salão",
  "moças",
  "mexer",
  "leque",
  "praxe",
  "lazer",
  "tripa",
  "feixe",
  "rumor",
  "gíria",
  "danos",
  "lenha",
  "redor",
  "macia",
  "frios",
  "artes",
  "cavar",
  "pesos",
  "bonde",
  "quais",
  "becos",
  "múmia",
  "risos",
  "igual",
  "celas",
  "elite",
  "senão",
  "ultra",
  "neles",
  "doido",
  "reluz",
  "jurar",
  "então",
  "cloro",
  "milha",
  "união",
  "lixão",
  "tanto",
  "praga",
  "fogos",
  "tosco",
  "chato",
  "cargo",
  "idosa",
  "larva",
  "bispo",
  "banjo",
  "vasta",
  "quase",
  "meiga",
  "pegar",
  "eixos",
  "termo",
  "pneus",
  "tango",
  "rugir",
  "farsa",
  "valer",
  "morta",
  "zerar",
  "doces",
  "foice",
  "hiena",
  "porca",
  "pedir",
  "sacro",
  "rímel",
  "aspas",
  "vigas",
  "gesto",
  "vazão",
  "haras",
  "sadia",
  "cheio",
  "lilás",
  "tipos",
  "motor",
  "única",
  "táxis",
  "dueto",
  "irmãs",
  "puxão",
  "bocas",
  "carro",
  "grato",
  "pomar",
  "gávea",
  "ápice",
  "nesse",
  "matar",
  "manto",
  "taças",
  "junho",
  "total",
  "caldo",
  "manga",
  "quota",
  "fatia",
  "mista",
  "desde",
  "gente",
  "leite",
  "porre",
  "lince",
  "falsa",
  "tirar",
  "farol",
  "expor",
  "supor",
  "vocal",
  "rolos",
  "ampla",
  "tricô",
  "hinos",
  "lenta",
  "vetor",
  "época",
  "rosas",
  "cravo",
  "pátio",
  "clube",
  "ética",
  "blusa",
  "busto",
  "pegos",
  "rodar",
  "calor",
  "rampa",
  "prata",
  "faixa",
  "ponta",
  "sutis",
  "males",
  "ouros",
  "oeste",
  "lobos",
  "bonés",
  "pavor",
  "baita",
  "astro",
  "ardor",
  "tapar",
  "calva",
  "funil",
  "prado",
  "facas",
  "urnas",
  "maias",
  "genes",
  "circo",
  "trigo",
  "psico",
  "afins",
  "culto",
  "cacos",
  "ótica",
  "ímpar",
  "linha",
  "jovem",
  "chata",
  "cruas",
  "pombo",
  "pudim",
  "cinto",
  "renal",
  "lebre",
  "prole",
  "áureo",
  "meter",
  "totem",
  "diabo",
  "silos",
  "balas",
  "ideal",
  "anjos",
  "rapaz",
  "canoa",
  "cacau",
  "vital",
  "morna",
  "rumos",
  "caber",
  "bodes",
  "capuz",
  "genro",
  "lunar",
  "ciclo",
  "cesta",
  "calmo",
  "seiva",
  "moral",
  "temer",
  "nisso",
  "menor",
  "balsa",
  "mogno",
  "fisco",
  "vozes",
  "palco",
  "adeus",
  "frota",
  "mania",
  "gosto",
  "áries",
  "negro",
  "lutar",
  "prato",
  "andar",
  "altos",
  "lápis",
  "clave",
  "fibra",
  "norma",
  "poeta",
  "velho",
  "graxa",
  "valas",
  "rosca",
  "zelar",
  "ducha",
  "jarra",
  "haste",
  "pobre",
  "pedra",
  "óscar",
  "médio",
  "opaco",
  "bulbo",
  "rudes",
  "subir",
  "vetos",
  "beata",
  "botão",
  "coisa",
  "nisto",
  "fauna",
  "mesma",
  "medos",
  "pedal",
  "tarja",
  "ninho",
  "donos",
  "ácido",
  "falar",
  "moita",
  "vosso",
  "credo",
  "ruivo",
  "rombo",
  "vazia",
  "sorte",
  "grana",
  "turno",
  "pesto",
  "bolsa",
  "cabos",
  "linda",
  "culta",
  "gases",
  "sushi",
  "algoz",
  "pente",
  "cento",
  "tigre",
  "favas",
  "pilão",
  "mágoa",
  "poção",
  "trono",
  "clico",
  "forno",
  "sutil",
  "ideia",
  "dócil",
  "parar",
  "minha",
  "palmo",
  "exata",
  "prosa",
  "razão",
  "sismo",
  "poros",
  "faraó",
  "cinco",
  "vilas",
  "tecno",
  "magos",
  "agora",
  "missa",
  "ditar",
  "sopas",
  "pilar",
  "lares",
  "divas",
  "polar",
  "greve",
  "bruxa",
  "sinal",
  "mamão",
  "trufa",
  "persa",
  "solar",
  "pluma",
  "muros",
  "lente",
  "fases",
  "rabos",
  "rádio",
  "fiada",
  "doada",
  "feias",
  "jogos",
  "podar",
  "farra",
  "altas",
  "sótão",
  "senso",
  "boate",
  "pirar",
  "raros",
  "nunca",
  "gênio",
  "adaga",
  "hotel",
  "idade",
  "baias",
  "urano",
  "peões",
  "censo",
  "gorro",
  "gesso",
  "gatos",
  "rotas",
  "nobel",
  "clipe",
  "sexta",
  "veado",
  "final",
  "áreas",
  "certo",
  "vigor",
  "óxido",
  "páreo",
  "pausa",
  "reais",
  "tonto",
  "virar",
  "pardo",
  "zinco",
  "ursos",
  "farpa",
  "ditos",
  "perda",
  "remos",
  "tenor",
  "pirão",
  "bacon",
  "ordem",
  "sachê",
  "mútua",
  "trens",
  "cheia",
  "mirim",
  "corvo",
  "dorso",
  "mansa",
  "vinil",
  "átomo",
  "repor",
  "ícone",
  "pudor",
  "carpa",
  "canja",
  "fofos",
  "urubu",
  "estar",
  "zeros",
  "piada",
  "extra",
  "seção",
  "perna",
  "mover",
  "viral",
  "naipe",
  "cisto",
  "bloco",
  "cupom",
  "povos",
  "fardo",
  "graça",
  "avelã",
  "palha",
  "sucos",
  "tosca",
  "casos",
  "licor",
  "épica",
  "guria",
  "denso",
  "terra",
  "delas",
  "reter",
  "grega",
  "birra",
  "leões",
  "luxos",
  "miúdo",
  "senha",
  "sonar",
  "raiva",
  "menta",
  "bioma",
  "hiato",
  "haver",
  "brava",
  "buquê",
  "vezes",
  "atrás",
  "fosco",
  "trair",
  "órgão",
  "cinta",
  "cacho",
  "coxas",
  "polos",
  "essas",
  "óleos",
  "banda",
  "lixos",
  "dores",
  "acesa",
  "claro",
  "dublê",
  "álbum",
  "natal",
  "avião",
  "massa",
  "catar",
  "mapas",
  "levar",
  "rigor",
  "pomba",
  "fosso",
  "fraca",
  "banir",
  "copos",
  "âmago",
  "duros",
  "zebra",
  "corda",
  "cabra",
  "morno",
  "turma",
  "móvel",
  "setas",
  "pônei",
  "orixá",
  "laudo",
  "tátil",
  "docas",
  "ricos",
  "dicas",
  "motos",
  "sedas",
  "prazo",
  "agudo",
  "tolos",
  "mimar",
  "finas",
  "posar",
  "nicho",
  "covas",
  "trena",
  "dosar",
  "socos",
  "barão",
  "focar",
  "invés",
  "peste",
  "anais",
  "jaula",
  "régua",
  "ômega",
  "achar",
  "corso",
  "canos",
  "barca",
  "cafés",
  "macio",
  "fatos",
  "ficar",
  "motel",
  "batom",
  "tropa",
  "sério",
  "jarro",
  "vogal",
  "canil",
  "visor",
  "dente",
  "casca",
  "santa",
  "chefe",
  "penta",
  "fusão",
  "reína",
  "galês",
  "mesmo",
  "cisne",
  "lapso",
  "fonte",
  "focos",
  "úmido",
  "ferir",
  "círio",
  "cruel",
  "máfia",
  "exato",
  "capaz",
  "lenço",
  "assar",
  "ainda",
  "campo",
  "enfim",
  "verba",
  "noite",
  "penal",
  "ânsia",
  "sapos",
  "ambas",
  "morar",
  "âmbar",
  "fones",
  "axila",
  "jatos",
  "pompa",
  "ossos",
  "micos",
  "pouco",
];
