export const VALIDGUESSES = [
  "aarus",
  "abaca",
  "abaco",
  "abada",
  "abade",
  "abado",
  "abafa",
  "abafe",
  "abafo",
  "abago",
  "abais",
  "abaju",
  "abala",
  "abale",
  "abalo",
  "abama",
  "abana",
  "abane",
  "abano",
  "abapo",
  "abara",
  "abare",
  "abata",
  "abate",
  "abati",
  "abato",
  "abava",
  "abavo",
  "abdal",
  "abduz",
  "abebe",
  "abeca",
  "abece",
  "abede",
  "abedo",
  "abeis",
  "abeta",
  "abete",
  "abeto",
  "abibe",
  "abica",
  "abico",
  "abies",
  "abiga",
  "abiia",
  "abila",
  "abilo",
  "abios",
  "abita",
  "abite",
  "abito",
  "abius",
  "ablua",
  "ablui",
  "abluo",
  "aboai",
  "aboam",
  "aboar",
  "aboas",
  "aboba",
  "abobe",
  "abobo",
  "aboca",
  "aboce",
  "aboco",
  "aboei",
  "aboem",
  "aboes",
  "abofe",
  "abohm",
  "aboia",
  "aboie",
  "aboio",
  "aboiz",
  "aboja",
  "aboje",
  "abojo",
  "abola",
  "abole",
  "aboli",
  "abolo",
  "aboma",
  "abona",
  "abone",
  "abono",
  "aboou",
  "abram",
  "abras",
  "abrem",
  "abres",
  "abreu",
  "abria",
  "abril",
  "abrir",
  "abris",
  "abriu",
  "abros",
  "absis",
  "abste",
  "abube",
  "abuda",
  "abudo",
  "abula",
  "abule",
  "abulo",
  "abuna",
  "aburu",
  "abusa",
  "abuse",
  "abuso",
  "abuta",
  "abuxo",
  "acaba",
  "acabe",
  "acabo",
  "acaca",
  "acace",
  "acaco",
  "acacu",
  "acada",
  "acade",
  "acado",
  "acaem",
  "acaes",
  "acaia",
  "acaio",
  "acais",
  "acaja",
  "acaju",
  "acala",
  "acale",
  "acalo",
  "acama",
  "acame",
  "acamo",
  "acana",
  "acapa",
  "acape",
  "acapo",
  "acapu",
  "acara",
  "acare",
  "acari",
  "acaro",
  "acaso",
  "acata",
  "acate",
  "acato",
  "acava",
  "acave",
  "acavo",
  "accao",
  "aceba",
  "acebe",
  "acebo",
  "aceda",
  "acede",
  "acedi",
  "acedo",
  "acefe",
  "acejo",
  "acena",
  "acene",
  "aceno",
  "acens",
  "acera",
  "acere",
  "acero",
  "acesa",
  "aceso",
  "aceta",
  "acete",
  "aceto",
  "aceva",
  "aceve",
  "acevo",
  "achai",
  "acham",
  "achar",
  "achas",
  "achei",
  "achem",
  "aches",
  "achim",
  "achoa",
  "achoe",
  "achoo",
  "achou",
  "acibe",
  "acica",
  "acida",
  "acide",
  "acido",
  "acies",
  "acila",
  "acima",
  "acime",
  "acimo",
  "acina",
  "acino",
  "acioa",
  "acisa",
  "aclia",
  "aclis",
  "acmes",
  "acnes",
  "acoai",
  "acoam",
  "acoar",
  "acoas",
  "acoba",
  "acobe",
  "acobo",
  "acoca",
  "acoco",
  "acoda",
  "acode",
  "acodo",
  "acoei",
  "acoem",
  "acoes",
  "acola",
  "acole",
  "acolo",
  "acona",
  "acoou",
  "acopa",
  "acope",
  "acopo",
  "acora",
  "acore",
  "acori",
  "acoro",
  "acova",
  "acove",
  "acovo",
  "acral",
  "acras",
  "acres",
  "acreu",
  "acroa",
  "acrol",
  "acron",
  "acros",
  "actia",
  "actol",
  "actos",
  "actua",
  "actue",
  "actuo",
  "acuai",
  "acuam",
  "acuar",
  "acuas",
  "acuda",
  "acude",
  "acudi",
  "acudo",
  "acuei",
  "acuem",
  "acues",
  "acula",
  "acule",
  "aculo",
  "acume",
  "acumi",
  "acuna",
  "acuos",
  "acuou",
  "acura",
  "acure",
  "acuri",
  "acuro",
  "acusa",
  "acuta",
  "acuti",
  "adaca",
  "adaga",
  "adail",
  "adais",
  "adali",
  "adama",
  "adame",
  "adamo",
  "adano",
  "adega",
  "adego",
  "adeja",
  "adeje",
  "adejo",
  "adela",
  "adelo",
  "adema",
  "adens",
  "adera",
  "adere",
  "aderi",
  "adero",
  "adesa",
  "adeso",
  "adeus",
  "adeva",
  "adhoc",
  "adiai",
  "adiam",
  "adiar",
  "adias",
  "adibe",
  "adica",
  "adico",
  "adida",
  "adido",
  "adiei",
  "adiem",
  "adies",
  "adila",
  "adile",
  "adilo",
  "adimo",
  "adiou",
  "adipa",
  "adipo",
  "adira",
  "adiro",
  "adita",
  "adite",
  "adito",
  "adiva",
  "adjas",
  "adoba",
  "adobe",
  "adobo",
  "adoca",
  "adoce",
  "adoco",
  "adoes",
  "adoli",
  "adolo",
  "adora",
  "adore",
  "adoro",
  "adota",
  "adote",
  "adoto",
  "adova",
  "adoxa",
  "adoxo",
  "adros",
  "aduai",
  "aduam",
  "aduar",
  "aduas",
  "aduba",
  "adube",
  "adubo",
  "aduei",
  "aduem",
  "adues",
  "adufa",
  "adufe",
  "adufo",
  "adula",
  "adule",
  "adulo",
  "aduma",
  "adume",
  "adumo",
  "aduna",
  "adune",
  "aduno",
  "aduns",
  "aduou",
  "adura",
  "adure",
  "aduri",
  "aduro",
  "aduza",
  "aduze",
  "aduzi",
  "aduzo",
  "advem",
  "advim",
  "advir",
  "aecio",
  "aedes",
  "aedos",
  "aerea",
  "aereo",
  "aerva",
  "aetas",
  "afaga",
  "afago",
  "afala",
  "afale",
  "afalo",
  "afama",
  "afame",
  "afamo",
  "afana",
  "afane",
  "afano",
  "afaza",
  "afaze",
  "afazi",
  "afazo",
  "afeai",
  "afear",
  "afeei",
  "afega",
  "afeia",
  "afeie",
  "afeio",
  "afeou",
  "afera",
  "afere",
  "aferi",
  "afero",
  "afeta",
  "afete",
  "afeto",
  "afiai",
  "afiam",
  "afiar",
  "afias",
  "afica",
  "afico",
  "afiei",
  "afiem",
  "afies",
  "afife",
  "afifo",
  "afila",
  "afile",
  "afilo",
  "afina",
  "afine",
  "afino",
  "afins",
  "afiou",
  "afira",
  "afiro",
  "afita",
  "afite",
  "afito",
  "afixa",
  "afixe",
  "afixo",
  "aflai",
  "aflam",
  "aflar",
  "aflas",
  "aflei",
  "aflem",
  "afles",
  "aflou",
  "aflua",
  "aflui",
  "afluo",
  "afoba",
  "afobe",
  "afobo",
  "afofa",
  "afofe",
  "afofo",
  "afoga",
  "afogo",
  "afola",
  "afole",
  "afolo",
  "afona",
  "afora",
  "afore",
  "aforo",
  "afoxe",
  "aftas",
  "afuma",
  "afume",
  "afumo",
  "afura",
  "afusa",
  "afuse",
  "afuso",
  "agabe",
  "agace",
  "agada",
  "agaia",
  "agais",
  "agala",
  "agama",
  "agami",
  "agamo",
  "agana",
  "agane",
  "agano",
  "agape",
  "agara",
  "agare",
  "agaro",
  "agata",
  "agati",
  "agave",
  "ageia",
  "ageis",
  "agema",
  "ageno",
  "agiam",
  "agiar",
  "agias",
  "agido",
  "agino",
  "agios",
  "agira",
  "agita",
  "agite",
  "agito",
  "aglia",
  "agnal",
  "agoge",
  "agogo",
  "agoma",
  "agome",
  "agomo",
  "agona",
  "agono",
  "agora",
  "agrai",
  "agram",
  "agrao",
  "agrar",
  "agras",
  "agraz",
  "agrei",
  "agrem",
  "agres",
  "agria",
  "agror",
  "agros",
  "agrou",
  "aguai",
  "aguam",
  "aguar",
  "aguas",
  "aguca",
  "aguce",
  "aguco",
  "aguda",
  "agude",
  "agudo",
  "aguei",
  "aguem",
  "agues",
  "aguia",
  "aguio",
  "aguou",
  "aguti",
  "aiaca",
  "aiado",
  "aiaia",
  "aiais",
  "aiara",
  "aiava",
  "aibis",
  "aicas",
  "aicmo",
  "aidro",
  "aieis",
  "ainda",
  "ainos",
  "ainsa",
  "aioca",
  "aipim",
  "aipis",
  "aipos",
  "airai",
  "airam",
  "airao",
  "airar",
  "airas",
  "airei",
  "airem",
  "aires",
  "airol",
  "airou",
  "aitao",
  "aitas",
  "aiuba",
  "aiuna",
  "aiune",
  "aiuno",
  "aivao",
  "aivas",
  "ajais",
  "ajaja",
  "ajara",
  "ajare",
  "ajeru",
  "ajuba",
  "ajuda",
  "ajude",
  "ajudo",
  "ajuga",
  "ajula",
  "ajule",
  "ajulo",
  "ajuri",
  "ajuru",
  "ajuso",
  "alaba",
  "alabe",
  "alabo",
  "alaca",
  "alaco",
  "alada",
  "alado",
  "alaes",
  "alafe",
  "alaga",
  "alago",
  "alais",
  "alala",
  "alalo",
  "alama",
  "alamo",
  "alana",
  "alano",
  "alaos",
  "alapa",
  "alape",
  "alapo",
  "alara",
  "alare",
  "alaro",
  "alava",
  "alaza",
  "albas",
  "alboi",
  "albor",
  "album",
  "alcai",
  "alcam",
  "alcar",
  "alcas",
  "alcea",
  "alcei",
  "alcem",
  "alces",
  "alcis",
  "alcou",
  "alcuz",
  "aldol",
  "aldos",
  "aleai",
  "alear",
  "aleas",
  "aleda",
  "alede",
  "aledo",
  "aleei",
  "alefe",
  "alega",
  "alego",
  "aleia",
  "aleie",
  "aleio",
  "aleis",
  "aleli",
  "alelo",
  "alema",
  "alemo",
  "aleno",
  "aleos",
  "aleou",
  "aleta",
  "aleto",
  "alfai",
  "alfam",
  "alfar",
  "alfas",
  "alfei",
  "alfem",
  "alfes",
  "alfil",
  "alfim",
  "alfir",
  "alfis",
  "alfol",
  "alfou",
  "alfoz",
  "alfur",
  "algar",
  "algas",
  "algaz",
  "algia",
  "algol",
  "algor",
  "algoz",
  "algum",
  "algur",
  "alhal",
  "alhas",
  "alhea",
  "alhia",
  "alhos",
  "alhur",
  "aliai",
  "aliam",
  "aliar",
  "alias",
  "alibi",
  "alica",
  "aliei",
  "aliem",
  "alies",
  "alife",
  "alija",
  "alije",
  "alijo",
  "alila",
  "alilo",
  "alime",
  "alios",
  "aliou",
  "alisa",
  "alise",
  "aliso",
  "alita",
  "alito",
  "aliva",
  "alive",
  "alivo",
  "aljuz",
  "almas",
  "almez",
  "almos",
  "almuz",
  "alnas",
  "alnos",
  "aloca",
  "aloco",
  "aloes",
  "alofe",
  "aloja",
  "aloje",
  "alojo",
  "alona",
  "alose",
  "alota",
  "alote",
  "aloto",
  "alpao",
  "alpea",
  "alpes",
  "alsol",
  "altar",
  "altas",
  "altea",
  "alter",
  "altor",
  "altos",
  "aluai",
  "aluam",
  "aluar",
  "aluas",
  "aluda",
  "alude",
  "aludi",
  "aludo",
  "aluei",
  "aluem",
  "alues",
  "alufa",
  "aluga",
  "alugo",
  "aluia",
  "aluir",
  "aluis",
  "aluiu",
  "aluja",
  "alula",
  "alume",
  "aluna",
  "alune",
  "aluno",
  "aluou",
  "aluxa",
  "aluxe",
  "aluxo",
  "alvao",
  "alvar",
  "alvas",
  "alvea",
  "alveo",
  "alvia",
  "alvio",
  "alvor",
  "alvos",
  "amada",
  "amado",
  "amadu",
  "amaga",
  "amago",
  "amais",
  "amala",
  "amame",
  "amamu",
  "amana",
  "amapa",
  "amara",
  "amare",
  "amata",
  "amate",
  "amato",
  "amava",
  "ambao",
  "ambar",
  "ambas",
  "ambel",
  "ambes",
  "ambia",
  "ambie",
  "ambio",
  "ambli",
  "ambom",
  "ambos",
  "ambra",
  "ambro",
  "ambua",
  "ambui",
  "ameai",
  "amear",
  "ameba",
  "ameei",
  "ameia",
  "ameie",
  "ameio",
  "ameis",
  "ameju",
  "amela",
  "amelo",
  "amena",
  "ameno",
  "amens",
  "ameou",
  "amial",
  "amiba",
  "amibo",
  "amida",
  "amido",
  "amiga",
  "amigo",
  "amila",
  "amilo",
  "amima",
  "amime",
  "amimo",
  "amina",
  "amino",
  "amita",
  "amito",
  "amnia",
  "amnio",
  "amoja",
  "amoje",
  "amojo",
  "amola",
  "amole",
  "amolo",
  "amomo",
  "amora",
  "amore",
  "amoro",
  "amota",
  "amote",
  "amoto",
  "amova",
  "amove",
  "amovi",
  "amovo",
  "ampla",
  "amplo",
  "amuai",
  "amuam",
  "amuar",
  "amuas",
  "amuei",
  "amuem",
  "amues",
  "amuos",
  "amuou",
  "amura",
  "amure",
  "amuri",
  "amuro",
  "amuso",
  "anabi",
  "anaca",
  "anace",
  "anaco",
  "anafa",
  "anafe",
  "anafo",
  "anaga",
  "anaia",
  "anais",
  "anaiu",
  "anaja",
  "anaje",
  "aname",
  "anana",
  "anani",
  "anano",
  "anaos",
  "anata",
  "anate",
  "anati",
  "anato",
  "anaza",
  "anaze",
  "anazo",
  "ancas",
  "ancha",
  "ancho",
  "ancia",
  "ancil",
  "ancis",
  "ancos",
  "andai",
  "andam",
  "andar",
  "andas",
  "andei",
  "andem",
  "andes",
  "andim",
  "andoa",
  "andoe",
  "andoo",
  "andor",
  "andos",
  "andou",
  "andre",
  "andua",
  "andus",
  "anebo",
  "anega",
  "anego",
  "aneia",
  "aneis",
  "anejo",
  "anela",
  "anele",
  "anelo",
  "anema",
  "anesa",
  "anete",
  "aneto",
  "anexa",
  "anexe",
  "anexo",
  "anfia",
  "angas",
  "angau",
  "angia",
  "angio",
  "anglo",
  "angor",
  "angra",
  "angus",
  "anhaa",
  "anhas",
  "anhos",
  "aniba",
  "anibu",
  "anila",
  "anile",
  "anilo",
  "anima",
  "anime",
  "animo",
  "anina",
  "anine",
  "anino",
  "anion",
  "anisa",
  "anise",
  "aniso",
  "anixi",
  "anixo",
  "anjao",
  "anjoa",
  "anjos",
  "anoda",
  "anodo",
  "anoes",
  "anoja",
  "anoje",
  "anojo",
  "anona",
  "anori",
  "anosa",
  "anoso",
  "anota",
  "anote",
  "anoto",
  "anova",
  "anove",
  "anovo",
  "ansia",
  "antai",
  "antal",
  "antam",
  "antao",
  "antar",
  "antas",
  "antei",
  "antem",
  "antes",
  "antia",
  "antos",
  "antou",
  "antra",
  "antre",
  "antro",
  "anuai",
  "anual",
  "anuam",
  "anuas",
  "anuem",
  "anuia",
  "anuir",
  "anuis",
  "anuiu",
  "anuja",
  "anula",
  "anule",
  "anulo",
  "anuns",
  "anuos",
  "anuri",
  "anuro",
  "anuus",
  "anzol",
  "anzom",
  "aonde",
  "aoqui",
  "aorta",
  "apaga",
  "apago",
  "apais",
  "apaja",
  "apaje",
  "apajo",
  "apapa",
  "apara",
  "apare",
  "aparo",
  "apaso",
  "apeai",
  "apear",
  "apecu",
  "apeda",
  "apede",
  "apedo",
  "apeei",
  "apega",
  "apego",
  "apeia",
  "apeie",
  "apeio",
  "apeja",
  "apejo",
  "apela",
  "apele",
  "apelo",
  "apena",
  "apene",
  "apeno",
  "apeou",
  "apera",
  "apere",
  "apero",
  "apeta",
  "apete",
  "apeti",
  "apeto",
  "apiao",
  "apice",
  "apicu",
  "apiol",
  "apios",
  "apipa",
  "apipe",
  "apipo",
  "apira",
  "apiro",
  "apita",
  "apite",
  "apito",
  "apitu",
  "apoca",
  "apoda",
  "apode",
  "apodo",
  "apoem",
  "apoes",
  "apoia",
  "apoie",
  "apoio",
  "apois",
  "apoja",
  "apoje",
  "apojo",
  "apolo",
  "apora",
  "aporo",
  "apota",
  "apoto",
  "apraz",
  "aprea",
  "aproa",
  "aproe",
  "aproo",
  "aptai",
  "aptam",
  "aptar",
  "aptas",
  "aptei",
  "aptem",
  "aptes",
  "aptos",
  "aptou",
  "apuai",
  "apuam",
  "apuar",
  "apuas",
  "apuei",
  "apuem",
  "apues",
  "apuia",
  "apuis",
  "apula",
  "apule",
  "apulo",
  "apuou",
  "apupa",
  "apupe",
  "apupo",
  "apura",
  "apure",
  "apuro",
  "aquea",
  "aquem",
  "aqueo",
  "aqueu",
  "arabe",
  "arabi",
  "arabu",
  "araca",
  "aracu",
  "arada",
  "arado",
  "araia",
  "arais",
  "araiu",
  "arama",
  "arame",
  "aramo",
  "arara",
  "arata",
  "arati",
  "aratu",
  "araue",
  "arava",
  "araxa",
  "arbim",
  "arcai",
  "arcal",
  "arcam",
  "arcao",
  "arcar",
  "arcas",
  "arcaz",
  "archa",
  "arche",
  "arcoa",
  "arcos",
  "arcou",
  "arcta",
  "arcte",
  "arcto",
  "arcua",
  "arcue",
  "arcuo",
  "ardam",
  "ardas",
  "ardea",
  "ardei",
  "ardem",
  "arder",
  "ardes",
  "ardeu",
  "ardia",
  "ardil",
  "ardis",
  "ardor",
  "ardua",
  "arduo",
  "areai",
  "areal",
  "areao",
  "arear",
  "areas",
  "areca",
  "arede",
  "areei",
  "areia",
  "areie",
  "areio",
  "areis",
  "areja",
  "areje",
  "arejo",
  "arena",
  "areoa",
  "areou",
  "arepa",
  "arere",
  "areus",
  "arfai",
  "arfam",
  "arfar",
  "arfas",
  "arfei",
  "arfem",
  "arfes",
  "arfis",
  "arfou",
  "argal",
  "argam",
  "argao",
  "argas",
  "argau",
  "argel",
  "argon",
  "argos",
  "argua",
  "argue",
  "argui",
  "arguo",
  "arias",
  "aribe",
  "arica",
  "arico",
  "aricu",
  "arida",
  "arido",
  "aries",
  "arife",
  "arila",
  "arilo",
  "arime",
  "arimo",
  "arina",
  "aripa",
  "aripe",
  "aripo",
  "ariri",
  "arita",
  "ariti",
  "arjao",
  "arjoa",
  "arjoe",
  "armai",
  "armam",
  "armao",
  "armar",
  "armas",
  "armei",
  "armem",
  "armes",
  "armeu",
  "armim",
  "armoa",
  "armos",
  "armou",
  "armur",
  "arnal",
  "arnes",
  "aroes",
  "arola",
  "aroma",
  "arome",
  "aromo",
  "arona",
  "arpai",
  "arpam",
  "arpao",
  "arpar",
  "arpas",
  "arpei",
  "arpem",
  "arpes",
  "arpeu",
  "arpoa",
  "arpoe",
  "arpoo",
  "arpou",
  "arque",
  "arqui",
  "arrai",
  "arral",
  "arrar",
  "arras",
  "arrea",
  "arreu",
  "arria",
  "arrie",
  "arrio",
  "arroz",
  "arrua",
  "arrue",
  "arruo",
  "arses",
  "arsis",
  "arsos",
  "artao",
  "artes",
  "artur",
  "aruai",
  "aruas",
  "aruba",
  "arube",
  "aruca",
  "aruga",
  "arujo",
  "arula",
  "aruma",
  "aruns",
  "arura",
  "aruru",
  "arval",
  "arvoa",
  "arvoe",
  "arvoo",
  "arxar",
  "arxas",
  "arxei",
  "arxes",
  "asada",
  "asado",
  "asais",
  "asara",
  "asaro",
  "asava",
  "ascas",
  "ascia",
  "ascio",
  "ascon",
  "ascos",
  "ascua",
  "aseis",
  "aselo",
  "aseus",
  "asiam",
  "asias",
  "asido",
  "asila",
  "asile",
  "asilo",
  "asios",
  "asira",
  "asmas",
  "asmos",
  "asnal",
  "asnas",
  "asnil",
  "asnos",
  "asoos",
  "aspai",
  "aspam",
  "aspar",
  "aspas",
  "aspei",
  "aspem",
  "aspes",
  "aspis",
  "aspou",
  "aspre",
  "assai",
  "assam",
  "assar",
  "assas",
  "assaz",
  "assea",
  "assei",
  "assem",
  "asses",
  "assim",
  "assis",
  "assoa",
  "assoe",
  "assoo",
  "assos",
  "assou",
  "assua",
  "assue",
  "assuo",
  "aster",
  "astil",
  "astim",
  "astra",
  "astre",
  "astro",
  "astur",
  "ataca",
  "ataco",
  "atada",
  "atado",
  "atais",
  "atana",
  "atane",
  "atano",
  "atapu",
  "atara",
  "atare",
  "atava",
  "ateai",
  "atear",
  "ateei",
  "ateia",
  "ateie",
  "ateio",
  "ateis",
  "atela",
  "atele",
  "atelo",
  "atemo",
  "atens",
  "ateou",
  "atera",
  "atesa",
  "atese",
  "ateso",
  "ateto",
  "ateus",
  "ateve",
  "atica",
  "atice",
  "atico",
  "aticu",
  "atido",
  "atila",
  "atile",
  "atilo",
  "atima",
  "atimo",
  "atina",
  "atine",
  "atino",
  "atins",
  "atira",
  "atire",
  "atiro",
  "atita",
  "atite",
  "atito",
  "ativa",
  "ative",
  "ativo",
  "atlas",
  "atoai",
  "atoam",
  "atoar",
  "atoas",
  "atoba",
  "atoco",
  "atoei",
  "atoem",
  "atoes",
  "atois",
  "atola",
  "atole",
  "atolo",
  "atomo",
  "atona",
  "atono",
  "atoou",
  "atopa",
  "atope",
  "atopo",
  "atora",
  "atore",
  "atoro",
  "atoto",
  "atrai",
  "atras",
  "atril",
  "atrio",
  "atris",
  "atriz",
  "atroa",
  "atroe",
  "atroo",
  "atros",
  "atroz",
  "atuai",
  "atual",
  "atuam",
  "atuar",
  "atuas",
  "atuei",
  "atuem",
  "atues",
  "atufa",
  "atufe",
  "atufo",
  "atuia",
  "atuir",
  "atuis",
  "atuiu",
  "atuns",
  "atuou",
  "atupa",
  "atupe",
  "atupi",
  "atupo",
  "atura",
  "ature",
  "aturo",
  "auaia",
  "auais",
  "auati",
  "aucao",
  "aucas",
  "audaz",
  "audio",
  "augem",
  "auges",
  "augia",
  "augir",
  "augis",
  "augiu",
  "auiba",
  "aujam",
  "aujas",
  "aulam",
  "aulas",
  "aulem",
  "aules",
  "aulia",
  "aulir",
  "aulis",
  "auliu",
  "aulos",
  "aunai",
  "aunam",
  "aunar",
  "aunas",
  "aunei",
  "aunem",
  "aunes",
  "auram",
  "auras",
  "aurea",
  "aurem",
  "aureo",
  "aures",
  "auria",
  "aurir",
  "auris",
  "auriu",
  "ausia",
  "ausio",
  "ausos",
  "austa",
  "auste",
  "austo",
  "autor",
  "autos",
  "autua",
  "autue",
  "autuo",
  "avais",
  "avano",
  "avara",
  "avare",
  "avaro",
  "avati",
  "aveal",
  "aveao",
  "aveia",
  "aveio",
  "aveja",
  "avela",
  "avele",
  "avelo",
  "avena",
  "avens",
  "avexa",
  "avexe",
  "avexo",
  "aveza",
  "aveze",
  "avezo",
  "aviai",
  "aviam",
  "aviao",
  "aviar",
  "avias",
  "avida",
  "avide",
  "avido",
  "aviei",
  "aviem",
  "avier",
  "avies",
  "avila",
  "avile",
  "avilo",
  "avios",
  "aviou",
  "avira",
  "avisa",
  "avise",
  "aviso",
  "avita",
  "avito",
  "aviua",
  "avius",
  "aviva",
  "avive",
  "avivo",
  "avoai",
  "avoam",
  "avoar",
  "avoas",
  "avoca",
  "avoco",
  "avoei",
  "avoem",
  "avoes",
  "avoou",
  "avosa",
  "avose",
  "avoso",
  "axeis",
  "axexe",
  "axial",
  "axila",
  "axilo",
  "axone",
  "axora",
  "axore",
  "axoro",
  "axuas",
  "azada",
  "azado",
  "azaga",
  "azais",
  "azara",
  "azare",
  "azaro",
  "azava",
  "azeda",
  "azede",
  "azedo",
  "azeis",
  "azera",
  "azere",
  "azero",
  "aziar",
  "azias",
  "azida",
  "azigo",
  "azimo",
  "azoai",
  "azoam",
  "azoar",
  "azoas",
  "azoei",
  "azoem",
  "azoes",
  "azois",
  "azola",
  "azoou",
  "azota",
  "azote",
  "azoto",
  "azuis",
  "azula",
  "azule",
  "azulo",
  "baais",
  "babai",
  "babal",
  "babam",
  "babao",
  "babar",
  "babas",
  "babau",
  "babei",
  "babel",
  "babem",
  "babes",
  "bable",
  "baboa",
  "babou",
  "babui",
  "babul",
  "babus",
  "bacar",
  "bachi",
  "bacia",
  "bacio",
  "bacon",
  "bacos",
  "bacus",
  "badal",
  "badas",
  "badem",
  "badia",
  "badil",
  "bador",
  "badur",
  "baeca",
  "baeco",
  "baeta",
  "bafai",
  "bafam",
  "bafar",
  "bafas",
  "bafei",
  "bafem",
  "bafes",
  "bafia",
  "bafio",
  "bafos",
  "bafou",
  "bafum",
  "bagai",
  "bagam",
  "bagar",
  "bagas",
  "bagem",
  "bagoa",
  "bagoo",
  "bagos",
  "bagou",
  "bagre",
  "bagua",
  "bague",
  "bahia",
  "baiai",
  "baiam",
  "baiao",
  "baiar",
  "baias",
  "baiei",
  "baiem",
  "baies",
  "baila",
  "baile",
  "bailo",
  "baioa",
  "baios",
  "baiou",
  "baira",
  "bairu",
  "baita",
  "baixa",
  "baixe",
  "baixo",
  "bajai",
  "bajam",
  "bajar",
  "bajas",
  "bajei",
  "bajem",
  "bajes",
  "bajou",
  "balai",
  "balam",
  "balao",
  "balar",
  "balas",
  "balba",
  "balbo",
  "balca",
  "balda",
  "balde",
  "baldo",
  "balea",
  "balei",
  "balem",
  "bales",
  "balga",
  "balha",
  "balhe",
  "balho",
  "balia",
  "balim",
  "balio",
  "balir",
  "balis",
  "baliu",
  "baloa",
  "balou",
  "balsa",
  "balse",
  "balso",
  "bamba",
  "bambe",
  "bambo",
  "bambu",
  "banal",
  "banca",
  "banco",
  "banda",
  "bande",
  "bandi",
  "bando",
  "banem",
  "banes",
  "banga",
  "bango",
  "bangu",
  "banha",
  "banhe",
  "banho",
  "bania",
  "banir",
  "banis",
  "baniu",
  "banja",
  "banjo",
  "banta",
  "banti",
  "banto",
  "banza",
  "banze",
  "banzo",
  "baoba",
  "bapos",
  "baqua",
  "baque",
  "barao",
  "barau",
  "barba",
  "barbe",
  "barbo",
  "barca",
  "barco",
  "barda",
  "barde",
  "bardo",
  "barem",
  "bares",
  "barga",
  "baria",
  "bario",
  "baris",
  "baroa",
  "barol",
  "barra",
  "barre",
  "barri",
  "barro",
  "barus",
  "basai",
  "basal",
  "basam",
  "basas",
  "basca",
  "basco",
  "basea",
  "basei",
  "basem",
  "bases",
  "basic",
  "basim",
  "basis",
  "basou",
  "basse",
  "basta",
  "baste",
  "basti",
  "basto",
  "batam",
  "batao",
  "batas",
  "batei",
  "batel",
  "batem",
  "bater",
  "bates",
  "bateu",
  "batia",
  "batim",
  "batis",
  "batmo",
  "batom",
  "batos",
  "bauas",
  "bauds",
  "baula",
  "bauna",
  "bauru",
  "baxas",
  "baxim",
  "bazai",
  "bazam",
  "bazar",
  "bazas",
  "bazei",
  "bazem",
  "bazes",
  "bazou",
  "bdela",
  "beaba",
  "beata",
  "beato",
  "bebam",
  "bebas",
  "bebei",
  "bebem",
  "beber",
  "bebes",
  "bebeu",
  "bebia",
  "bebum",
  "becas",
  "becos",
  "becua",
  "bedel",
  "bedem",
  "bedro",
  "bedui",
  "bedum",
  "begue",
  "begum",
  "beica",
  "beico",
  "beija",
  "beije",
  "beijo",
  "beiju",
  "beira",
  "beire",
  "beiro",
  "beisa",
  "belao",
  "belas",
  "belaz",
  "belda",
  "belde",
  "beldo",
  "belem",
  "belfa",
  "belfo",
  "belga",
  "belho",
  "belis",
  "beliz",
  "belos",
  "bemba",
  "bembe",
  "bemol",
  "benca",
  "bende",
  "bendi",
  "bengo",
  "benta",
  "bento",
  "benza",
  "benze",
  "benzi",
  "benzo",
  "beque",
  "berca",
  "berco",
  "beris",
  "berma",
  "berna",
  "berne",
  "beroe",
  "berol",
  "beros",
  "berra",
  "berre",
  "berro",
  "berta",
  "bespa",
  "bespe",
  "bessa",
  "bessi",
  "besta",
  "beste",
  "besto",
  "betai",
  "betam",
  "betao",
  "betar",
  "betas",
  "betei",
  "betel",
  "betem",
  "betes",
  "betia",
  "betle",
  "betol",
  "betou",
  "betre",
  "betus",
  "bezar",
  "bezoa",
  "bezoo",
  "biaba",
  "biana",
  "biaro",
  "biata",
  "bibes",
  "bibio",
  "bibis",
  "bibla",
  "biboi",
  "bicai",
  "bical",
  "bicam",
  "bicar",
  "bicas",
  "bicha",
  "biche",
  "bicho",
  "bicos",
  "bicou",
  "bidao",
  "bides",
  "bidos",
  "bidua",
  "biduo",
  "bidus",
  "biela",
  "bieno",
  "bifai",
  "bifam",
  "bifar",
  "bifas",
  "bifei",
  "bifem",
  "bifes",
  "bifou",
  "bigas",
  "bigle",
  "bigua",
  "bigue",
  "biguo",
  "bigus",
  "bijia",
  "bijus",
  "biles",
  "bilha",
  "bilho",
  "bilia",
  "bilis",
  "bilra",
  "bilre",
  "bilro",
  "bimar",
  "bimba",
  "bimbe",
  "bimbo",
  "bimos",
  "binai",
  "binam",
  "binar",
  "binas",
  "binda",
  "binei",
  "binem",
  "bines",
  "binga",
  "bingo",
  "binou",
  "bioba",
  "bioco",
  "bioma",
  "biose",
  "biota",
  "bique",
  "biras",
  "birba",
  "biris",
  "biroa",
  "biros",
  "birra",
  "birre",
  "birro",
  "birus",
  "bisai",
  "bisam",
  "bisao",
  "bisar",
  "bisas",
  "bisbi",
  "bisca",
  "bisco",
  "bisei",
  "bisel",
  "bisem",
  "bises",
  "bisoa",
  "bisou",
  "bispa",
  "bispe",
  "bispo",
  "bisso",
  "bitai",
  "bitam",
  "bitar",
  "bitas",
  "bitei",
  "bitem",
  "biter",
  "bites",
  "bitou",
  "bitus",
  "bivas",
  "bivia",
  "bivio",
  "bixas",
  "blase",
  "blaus",
  "blefa",
  "blefe",
  "blefo",
  "blesa",
  "bleso",
  "blitz",
  "bloco",
  "bluco",
  "blues",
  "blusa",
  "blush",
  "boaba",
  "boaca",
  "boafe",
  "boana",
  "boata",
  "boate",
  "boato",
  "bobai",
  "bobal",
  "bobam",
  "bobar",
  "bobas",
  "bobea",
  "bobei",
  "bobem",
  "bobes",
  "bobis",
  "boboa",
  "bobos",
  "bobou",
  "bocai",
  "bocal",
  "bocam",
  "bocao",
  "bocar",
  "bocas",
  "bocei",
  "bocem",
  "boces",
  "bocha",
  "boche",
  "bocia",
  "bocim",
  "bocio",
  "bocos",
  "bocou",
  "bocue",
  "bodai",
  "bodar",
  "bodas",
  "bodei",
  "bodem",
  "bodes",
  "bodou",
  "bofai",
  "bofam",
  "bofar",
  "bofas",
  "bofei",
  "bofem",
  "bofes",
  "bofou",
  "bogai",
  "bogam",
  "bogar",
  "bogas",
  "bogos",
  "bogou",
  "bogue",
  "boiai",
  "boiam",
  "boiar",
  "boias",
  "boibi",
  "boica",
  "boice",
  "boico",
  "boiei",
  "boiem",
  "boies",
  "boiga",
  "boiil",
  "boina",
  "boiou",
  "boiru",
  "bojam",
  "bojar",
  "bojas",
  "bojei",
  "bojos",
  "bojou",
  "bojui",
  "bolai",
  "bolam",
  "bolao",
  "bolar",
  "bolas",
  "bolba",
  "bolbo",
  "bolca",
  "bolce",
  "bolco",
  "boldo",
  "bolei",
  "bolem",
  "boles",
  "bolha",
  "bolho",
  "bolor",
  "bolos",
  "bolou",
  "bolsa",
  "bolso",
  "bomba",
  "bombe",
  "bombo",
  "bonda",
  "bonde",
  "bondo",
  "bones",
  "bonga",
  "bongo",
  "bonho",
  "bonus",
  "bonza",
  "bonzo",
  "books",
  "boque",
  "boral",
  "boras",
  "borax",
  "borca",
  "borco",
  "borda",
  "borde",
  "bordo",
  "borga",
  "boris",
  "borla",
  "borno",
  "boros",
  "borra",
  "borre",
  "borro",
  "bosca",
  "bossa",
  "bosta",
  "boste",
  "bosto",
  "botai",
  "botam",
  "botao",
  "botar",
  "botas",
  "botei",
  "botem",
  "botes",
  "botim",
  "botou",
  "bouba",
  "bouca",
  "bouce",
  "bouco",
  "bouga",
  "boxea",
  "boxes",
  "braba",
  "brabo",
  "braca",
  "braco",
  "brada",
  "brade",
  "brado",
  "braga",
  "brama",
  "brame",
  "brami",
  "bramo",
  "brasa",
  "brava",
  "bravo",
  "breai",
  "break",
  "breal",
  "breba",
  "breca",
  "breco",
  "breda",
  "bredo",
  "brefo",
  "brega",
  "breia",
  "breio",
  "breja",
  "brejo",
  "breou",
  "breta",
  "breus",
  "breve",
  "briba",
  "brida",
  "briga",
  "brigo",
  "brins",
  "briol",
  "brion",
  "brios",
  "brisa",
  "brise",
  "briso",
  "brita",
  "brite",
  "brito",
  "briza",
  "broas",
  "broca",
  "broco",
  "bromo",
  "brota",
  "brote",
  "broto",
  "broxa",
  "bruco",
  "brugo",
  "bruma",
  "brumo",
  "bruna",
  "brune",
  "bruni",
  "bruno",
  "bruta",
  "bruto",
  "bruxa",
  "bruxe",
  "bruxo",
  "buama",
  "buara",
  "buase",
  "bubao",
  "bubos",
  "bucal",
  "bucha",
  "bucho",
  "buchu",
  "bucia",
  "bucla",
  "bucle",
  "bucos",
  "bucra",
  "bucre",
  "bucus",
  "budas",
  "buena",
  "bueno",
  "bufai",
  "bufam",
  "bufao",
  "bufar",
  "bufas",
  "bufei",
  "bufem",
  "bufes",
  "bufou",
  "bugio",
  "bugla",
  "bugle",
  "bugre",
  "buiam",
  "buido",
  "builo",
  "buira",
  "bujao",
  "bujes",
  "bujis",
  "bujoa",
  "bulai",
  "bulam",
  "bular",
  "bulas",
  "bulbo",
  "bulei",
  "bulem",
  "bules",
  "bulha",
  "bulia",
  "bulir",
  "bulis",
  "buliu",
  "bulou",
  "bumba",
  "bumbe",
  "bumbo",
  "bunda",
  "bunha",
  "bunhe",
  "bunho",
  "buque",
  "burca",
  "burco",
  "burda",
  "burdo",
  "burel",
  "bures",
  "burga",
  "burgo",
  "buril",
  "buris",
  "burla",
  "burle",
  "burlo",
  "burnu",
  "burra",
  "burre",
  "burro",
  "bursa",
  "busca",
  "busco",
  "busil",
  "busto",
  "butea",
  "buteo",
  "butia",
  "butim",
  "butir",
  "butra",
  "butre",
  "butua",
  "buvar",
  "buvas",
  "buxal",
  "buxos",
  "buzia",
  "buzie",
  "buzio",
  "buzos",
  "caaba",
  "caaco",
  "caacu",
  "caaee",
  "caama",
  "caami",
  "caapi",
  "caaxi",
  "cabal",
  "cabas",
  "cabau",
  "cabaz",
  "cabei",
  "cabem",
  "caber",
  "cabes",
  "cabia",
  "cabis",
  "cabiu",
  "cabos",
  "caboz",
  "cabra",
  "cabre",
  "cabro",
  "cabul",
  "cabus",
  "cacai",
  "cacam",
  "cacao",
  "cacar",
  "cacas",
  "cacau",
  "cacea",
  "cacei",
  "cacem",
  "caces",
  "cacha",
  "cache",
  "cacho",
  "cachu",
  "cacim",
  "caciz",
  "cacoa",
  "cacoe",
  "cacoo",
  "cacos",
  "cacou",
  "cacta",
  "cacto",
  "cacua",
  "cadai",
  "caddy",
  "cades",
  "cados",
  "cadoz",
  "caete",
  "cafes",
  "cafiz",
  "cafra",
  "cafre",
  "cafta",
  "cafua",
  "cafus",
  "cafuz",
  "cagai",
  "cagam",
  "cagao",
  "cagar",
  "cagas",
  "cagom",
  "cagou",
  "cague",
  "caiai",
  "caiam",
  "caiar",
  "caias",
  "caiba",
  "caibo",
  "caica",
  "caico",
  "caicu",
  "caida",
  "caido",
  "caiei",
  "caiem",
  "caies",
  "caima",
  "cains",
  "caiou",
  "caipa",
  "caira",
  "caire",
  "cairi",
  "cairo",
  "caite",
  "caiva",
  "caixa",
  "cajao",
  "cajas",
  "cajia",
  "cajua",
  "cajui",
  "cajus",
  "calai",
  "calam",
  "calao",
  "calar",
  "calas",
  "calau",
  "calca",
  "calce",
  "calco",
  "calda",
  "calde",
  "caldo",
  "calei",
  "calem",
  "cales",
  "calha",
  "calhe",
  "calho",
  "calim",
  "calis",
  "calix",
  "caliz",
  "calma",
  "calme",
  "calmo",
  "caloa",
  "calom",
  "calor",
  "calos",
  "calou",
  "calpa",
  "calta",
  "calum",
  "calus",
  "calva",
  "calve",
  "calvo",
  "camai",
  "camal",
  "camam",
  "camao",
  "camar",
  "camas",
  "camau",
  "camba",
  "cambe",
  "cambo",
  "camei",
  "camem",
  "cames",
  "camia",
  "camol",
  "camou",
  "campa",
  "campe",
  "campi",
  "campo",
  "canal",
  "canas",
  "canaz",
  "canca",
  "canda",
  "cande",
  "candi",
  "cando",
  "canez",
  "canga",
  "cangi",
  "cango",
  "canha",
  "canho",
  "canil",
  "canis",
  "canja",
  "canje",
  "canjo",
  "canoa",
  "canoe",
  "canon",
  "canos",
  "cansa",
  "canse",
  "canso",
  "canta",
  "cante",
  "canto",
  "canza",
  "caoba",
  "caois",
  "capai",
  "capam",
  "capao",
  "capar",
  "capas",
  "capaz",
  "capei",
  "capem",
  "capes",
  "capia",
  "capim",
  "capoa",
  "capos",
  "capou",
  "capro",
  "capta",
  "capte",
  "capto",
  "caput",
  "capuz",
  "caqui",
  "caraa",
  "carao",
  "caras",
  "carbo",
  "carda",
  "carde",
  "cardo",
  "carel",
  "careu",
  "carga",
  "cargo",
  "caria",
  "carie",
  "carii",
  "caril",
  "cario",
  "caris",
  "cariu",
  "cariz",
  "carla",
  "carma",
  "carme",
  "carmo",
  "carna",
  "carne",
  "carno",
  "caroa",
  "caroe",
  "caros",
  "carpa",
  "carpe",
  "carpi",
  "carpo",
  "carra",
  "carre",
  "carro",
  "carta",
  "carte",
  "carus",
  "carva",
  "casai",
  "casal",
  "casam",
  "casao",
  "casar",
  "casas",
  "casba",
  "casca",
  "casco",
  "casei",
  "casem",
  "cases",
  "casos",
  "casou",
  "caspa",
  "cassa",
  "casse",
  "casso",
  "casta",
  "casti",
  "casto",
  "catai",
  "catam",
  "catao",
  "catar",
  "catas",
  "catau",
  "catch",
  "catei",
  "catem",
  "cates",
  "catia",
  "catis",
  "catle",
  "catou",
  "catre",
  "catur",
  "cauba",
  "caubi",
  "cauda",
  "cauim",
  "cauis",
  "caule",
  "cauna",
  "cauno",
  "caure",
  "cauri",
  "causa",
  "cause",
  "causo",
  "cauta",
  "cauto",
  "cavai",
  "cavam",
  "cavao",
  "cavar",
  "cavas",
  "cavea",
  "cavei",
  "cavem",
  "caves",
  "cavia",
  "cavos",
  "cavou",
  "cavus",
  "caxas",
  "caxim",
  "cazol",
  "ceado",
  "ceais",
  "ceara",
  "ceata",
  "ceava",
  "cebos",
  "cebus",
  "cecal",
  "cecao",
  "cecas",
  "cecem",
  "ceces",
  "cecos",
  "cecum",
  "cedam",
  "cedas",
  "cedei",
  "cedem",
  "ceder",
  "cedes",
  "cedeu",
  "cedia",
  "cedro",
  "ceeis",
  "cefeu",
  "cegai",
  "cegam",
  "cegar",
  "cegas",
  "cegos",
  "cegou",
  "cegue",
  "ceiam",
  "ceias",
  "ceiba",
  "ceiem",
  "ceies",
  "ceifa",
  "ceife",
  "ceifo",
  "ceira",
  "ceita",
  "ceiva",
  "ceive",
  "ceivo",
  "celas",
  "celga",
  "celha",
  "celia",
  "celos",
  "celsa",
  "celso",
  "celta",
  "celte",
  "cemba",
  "cenas",
  "cengo",
  "cenho",
  "cenos",
  "cenra",
  "censo",
  "centa",
  "cento",
  "ceomo",
  "ceote",
  "cepas",
  "cepos",
  "ceque",
  "cerai",
  "ceram",
  "cerar",
  "ceras",
  "cerca",
  "cerce",
  "cerco",
  "cerda",
  "cerdo",
  "cerea",
  "cerei",
  "cerem",
  "cereo",
  "ceres",
  "ceria",
  "cerio",
  "cerna",
  "cerne",
  "cerni",
  "cerno",
  "ceroa",
  "cerol",
  "ceros",
  "cerou",
  "cerra",
  "cerre",
  "cerro",
  "certa",
  "certe",
  "certo",
  "cerva",
  "cervo",
  "cerzi",
  "cesao",
  "cesar",
  "cesio",
  "cessa",
  "cesse",
  "cesso",
  "cesta",
  "cesto",
  "cetim",
  "cetis",
  "cetol",
  "cetra",
  "cetro",
  "cevai",
  "cevam",
  "cevao",
  "cevar",
  "cevas",
  "cevei",
  "cevem",
  "ceves",
  "cevoa",
  "cevos",
  "cevou",
  "chabo",
  "chabu",
  "chaca",
  "chace",
  "chaco",
  "chaem",
  "chaga",
  "chago",
  "chaja",
  "chala",
  "chale",
  "chalo",
  "chama",
  "chame",
  "chamo",
  "chana",
  "chaos",
  "chapa",
  "chape",
  "chapo",
  "chara",
  "chata",
  "chate",
  "chato",
  "chaua",
  "chava",
  "chave",
  "chavo",
  "chebe",
  "checa",
  "checo",
  "cheda",
  "chede",
  "chefa",
  "chefe",
  "chefs",
  "chega",
  "chego",
  "cheia",
  "cheio",
  "chela",
  "chena",
  "cheta",
  "chiai",
  "chiam",
  "chiao",
  "chiar",
  "chias",
  "chiba",
  "chibe",
  "chibo",
  "chica",
  "chice",
  "chico",
  "chiei",
  "chiem",
  "chies",
  "chifu",
  "chila",
  "chile",
  "china",
  "chine",
  "chino",
  "chins",
  "chios",
  "chiou",
  "chipa",
  "chipo",
  "chira",
  "chiru",
  "chita",
  "chito",
  "choca",
  "choco",
  "chogo",
  "chola",
  "chona",
  "chone",
  "chono",
  "chope",
  "chora",
  "chore",
  "choro",
  "chota",
  "choto",
  "chova",
  "chove",
  "chovi",
  "chovo",
  "chuca",
  "chuce",
  "chuco",
  "chues",
  "chufa",
  "chufe",
  "chufo",
  "chuis",
  "chula",
  "chule",
  "chulo",
  "chuna",
  "chupa",
  "chupe",
  "chupo",
  "churi",
  "chuta",
  "chute",
  "chuto",
  "chuva",
  "chuxo",
  "ciada",
  "ciado",
  "ciais",
  "ciamo",
  "ciana",
  "ciano",
  "ciara",
  "ciato",
  "ciava",
  "cibai",
  "cibam",
  "cibar",
  "cibas",
  "cibei",
  "cibem",
  "cibes",
  "cibos",
  "cibou",
  "cicas",
  "cicia",
  "cicie",
  "cicio",
  "cicla",
  "ciclo",
  "cidao",
  "cidno",
  "cidra",
  "cidro",
  "cieis",
  "ciena",
  "ciese",
  "cifai",
  "cifam",
  "cifar",
  "cifas",
  "cifei",
  "cifem",
  "cifes",
  "cifos",
  "cifou",
  "cifra",
  "cifre",
  "cifro",
  "cigas",
  "cigno",
  "cilha",
  "cilhe",
  "cilho",
  "cilio",
  "cimao",
  "cimas",
  "cimba",
  "cimol",
  "cimos",
  "cinca",
  "cinco",
  "cinda",
  "cinde",
  "cindi",
  "cindo",
  "cines",
  "cinge",
  "cingi",
  "cinja",
  "cinjo",
  "cinor",
  "cinta",
  "cinte",
  "cinto",
  "cinza",
  "cinze",
  "cinzo",
  "cioba",
  "cioco",
  "ciosa",
  "cioso",
  "cioto",
  "cipoa",
  "cipoe",
  "cipoo",
  "cipos",
  "circo",
  "ciria",
  "cirio",
  "cirne",
  "cirpa",
  "cirra",
  "cirre",
  "cirro",
  "cirus",
  "cirza",
  "cirze",
  "cirzo",
  "cisam",
  "cisao",
  "cisar",
  "cisas",
  "cisca",
  "cisco",
  "cisei",
  "cisel",
  "cisem",
  "cises",
  "cisio",
  "cisma",
  "cisme",
  "cismo",
  "cisne",
  "cisou",
  "cispa",
  "cispe",
  "cispo",
  "cista",
  "cisto",
  "citai",
  "citam",
  "citar",
  "citas",
  "citei",
  "citem",
  "cites",
  "citos",
  "citou",
  "citro",
  "ciuma",
  "ciume",
  "ciumo",
  "ciuro",
  "civel",
  "civil",
  "civis",
  "clada",
  "clade",
  "clama",
  "clame",
  "clamo",
  "clara",
  "claro",
  "clava",
  "clave",
  "clavo",
  "clean",
  "clero",
  "clica",
  "click",
  "clico",
  "clido",
  "clima",
  "clina",
  "clino",
  "clipa",
  "clipe",
  "clise",
  "cliva",
  "clive",
  "clivo",
  "clona",
  "clone",
  "clono",
  "clora",
  "clore",
  "clori",
  "cloro",
  "close",
  "clown",
  "clube",
  "cluva",
  "cnico",
  "cnute",
  "coach",
  "coaco",
  "coada",
  "coado",
  "coage",
  "coagi",
  "coais",
  "coaja",
  "coajo",
  "coala",
  "coamo",
  "coana",
  "coano",
  "coara",
  "coata",
  "coate",
  "coati",
  "coato",
  "coava",
  "coaxa",
  "coaxe",
  "coaxo",
  "cobio",
  "cobra",
  "cobre",
  "cobri",
  "cobro",
  "cobus",
  "cocai",
  "cocal",
  "cocam",
  "cocao",
  "cocar",
  "cocas",
  "cocei",
  "cocem",
  "coces",
  "cocha",
  "coche",
  "cocho",
  "cocoa",
  "cocos",
  "cocou",
  "cocre",
  "cocto",
  "codao",
  "codas",
  "codea",
  "codeo",
  "codes",
  "codex",
  "codio",
  "codoa",
  "codos",
  "coeis",
  "coemo",
  "coera",
  "coere",
  "coeri",
  "coero",
  "coesa",
  "coeso",
  "coeva",
  "coevo",
  "cofea",
  "cofia",
  "cofie",
  "cofio",
  "cofos",
  "cofre",
  "cogia",
  "cogie",
  "cogio",
  "coiba",
  "coibe",
  "coibi",
  "coibo",
  "coice",
  "coifa",
  "coife",
  "coifo",
  "coilo",
  "coima",
  "coime",
  "coimo",
  "coiol",
  "coios",
  "coira",
  "coiro",
  "coisa",
  "coise",
  "coiso",
  "coita",
  "coite",
  "coito",
  "colai",
  "colam",
  "colar",
  "colas",
  "colei",
  "colem",
  "coleo",
  "coles",
  "colga",
  "colgo",
  "colha",
  "colhe",
  "colhi",
  "colho",
  "colim",
  "colio",
  "colir",
  "colma",
  "colme",
  "colmo",
  "colon",
  "color",
  "colos",
  "colou",
  "colpa",
  "colza",
  "comam",
  "comao",
  "comas",
  "comba",
  "combi",
  "combo",
  "comei",
  "comem",
  "comer",
  "comes",
  "comeu",
  "comia",
  "comua",
  "comum",
  "conas",
  "conca",
  "conda",
  "conde",
  "condi",
  "condo",
  "cones",
  "conga",
  "congo",
  "conha",
  "conho",
  "conio",
  "conja",
  "conos",
  "conta",
  "conte",
  "conto",
  "cooba",
  "coobe",
  "coobo",
  "copai",
  "copal",
  "copam",
  "copar",
  "copas",
  "copei",
  "copel",
  "copem",
  "copes",
  "copia",
  "copie",
  "copio",
  "copla",
  "copos",
  "copou",
  "copra",
  "copta",
  "copto",
  "coque",
  "corai",
  "coral",
  "coram",
  "corao",
  "corar",
  "coras",
  "corba",
  "corca",
  "corco",
  "corda",
  "cordo",
  "corei",
  "corem",
  "cores",
  "coreu",
  "corga",
  "corgo",
  "coria",
  "corja",
  "corla",
  "cormo",
  "corna",
  "corne",
  "corno",
  "coroa",
  "coroe",
  "coroo",
  "coros",
  "corou",
  "corpo",
  "corra",
  "corre",
  "corri",
  "corro",
  "corsa",
  "corso",
  "corta",
  "corte",
  "corto",
  "corva",
  "corvo",
  "cosam",
  "cosas",
  "cosca",
  "cosco",
  "cosei",
  "cosem",
  "coser",
  "coses",
  "coseu",
  "cosia",
  "cosmo",
  "cospe",
  "cossa",
  "cosso",
  "costa",
  "costo",
  "cotai",
  "cotam",
  "cotao",
  "cotar",
  "cotas",
  "cotei",
  "cotem",
  "cotes",
  "cotia",
  "cotie",
  "cotil",
  "cotim",
  "cotio",
  "cotis",
  "cotos",
  "cotou",
  "cotra",
  "coube",
  "couca",
  "couce",
  "couco",
  "couma",
  "coupe",
  "coura",
  "couro",
  "cousa",
  "couse",
  "couso",
  "couta",
  "coute",
  "couto",
  "couve",
  "coval",
  "covao",
  "covas",
  "cover",
  "covid",
  "covil",
  "covis",
  "covos",
  "coxal",
  "coxas",
  "coxes",
  "coxia",
  "coxim",
  "coxos",
  "cozam",
  "cozas",
  "cozei",
  "cozem",
  "cozer",
  "cozes",
  "cozeu",
  "cozia",
  "craca",
  "crack",
  "crama",
  "crame",
  "cramo",
  "crase",
  "crato",
  "craua",
  "crava",
  "crave",
  "cravo",
  "crawl",
  "creda",
  "crede",
  "credo",
  "creem",
  "crega",
  "creia",
  "creie",
  "creio",
  "crela",
  "crema",
  "creme",
  "cremo",
  "crena",
  "crene",
  "creno",
  "crepe",
  "crera",
  "creta",
  "creto",
  "creve",
  "criai",
  "criam",
  "criar",
  "crias",
  "crica",
  "crico",
  "crido",
  "criei",
  "criem",
  "cries",
  "crila",
  "crime",
  "crina",
  "crino",
  "criou",
  "crise",
  "criva",
  "crive",
  "crivo",
  "croas",
  "croca",
  "croco",
  "croia",
  "croma",
  "crome",
  "cromo",
  "crono",
  "crons",
  "croxa",
  "cruas",
  "cruca",
  "cruel",
  "cruga",
  "cruma",
  "crume",
  "cruor",
  "crupa",
  "crupe",
  "crush",
  "cruta",
  "cruza",
  "cruze",
  "cruzo",
  "cuaca",
  "cuada",
  "cuaga",
  "cubai",
  "cubam",
  "cubar",
  "cubas",
  "cubei",
  "cubem",
  "cubes",
  "cubia",
  "cubie",
  "cubio",
  "cubla",
  "cubos",
  "cubou",
  "cubra",
  "cubre",
  "cubro",
  "cucai",
  "cucam",
  "cucar",
  "cucas",
  "cuche",
  "cucia",
  "cucos",
  "cucou",
  "cucus",
  "cudos",
  "cudzu",
  "cueca",
  "cuera",
  "cufea",
  "cuiao",
  "cuias",
  "cuica",
  "cuico",
  "cuida",
  "cuide",
  "cuido",
  "cuini",
  "cuins",
  "cuipe",
  "cuira",
  "cuita",
  "cuite",
  "cuito",
  "cujas",
  "cujos",
  "cujus",
  "cules",
  "culex",
  "culpa",
  "culpe",
  "culpo",
  "culta",
  "culto",
  "cumai",
  "cumas",
  "cumba",
  "cumbe",
  "cumbo",
  "cumbu",
  "cumes",
  "cumim",
  "cumis",
  "cunas",
  "cunca",
  "cunco",
  "cunea",
  "cuneo",
  "cunha",
  "cunhe",
  "cunho",
  "cunia",
  "cunta",
  "cupai",
  "cupao",
  "cupas",
  "cupes",
  "cupez",
  "cupia",
  "cupim",
  "cupio",
  "cupis",
  "cupom",
  "cupus",
  "cuque",
  "curai",
  "curam",
  "curar",
  "curas",
  "curau",
  "curda",
  "curdo",
  "curei",
  "curem",
  "cures",
  "curia",
  "curie",
  "curio",
  "curis",
  "curou",
  "curra",
  "curre",
  "curro",
  "curry",
  "cursa",
  "curse",
  "curso",
  "curta",
  "curte",
  "curti",
  "curto",
  "curua",
  "curul",
  "curus",
  "curva",
  "curve",
  "curvo",
  "cusco",
  "cuspa",
  "cuspe",
  "cuspi",
  "cuspo",
  "cussa",
  "cusso",
  "custa",
  "custe",
  "custo",
  "cuter",
  "cutes",
  "cutia",
  "cutis",
  "cuvia",
  "cuvus",
  "cuxas",
  "cuxiu",
  "czara",
  "dabom",
  "dabua",
  "dacao",
  "dacar",
  "daces",
  "dacia",
  "dacio",
  "dacma",
  "dacos",
  "dadas",
  "dador",
  "dados",
  "dafne",
  "dagas",
  "daiao",
  "dairo",
  "dalas",
  "dalem",
  "dalia",
  "damao",
  "damar",
  "damas",
  "damba",
  "damos",
  "damui",
  "danai",
  "danam",
  "danar",
  "danas",
  "danca",
  "dance",
  "danco",
  "danda",
  "dande",
  "dandi",
  "dando",
  "danei",
  "danem",
  "danes",
  "danio",
  "danos",
  "danou",
  "danta",
  "dante",
  "daqui",
  "darao",
  "daras",
  "darci",
  "darda",
  "darde",
  "dardo",
  "darei",
  "darem",
  "dares",
  "darga",
  "daria",
  "dario",
  "darma",
  "darto",
  "datai",
  "datal",
  "datam",
  "datar",
  "datas",
  "datei",
  "datem",
  "dates",
  "datil",
  "datou",
  "dauco",
  "dauma",
  "davam",
  "davas",
  "daves",
  "deada",
  "deado",
  "deaes",
  "deaos",
  "debai",
  "debam",
  "debar",
  "debas",
  "debei",
  "debem",
  "debes",
  "debil",
  "debou",
  "decai",
  "decha",
  "decho",
  "decil",
  "decis",
  "decks",
  "decoa",
  "decoe",
  "decoo",
  "decor",
  "dedai",
  "dedal",
  "dedam",
  "dedao",
  "dedar",
  "dedas",
  "dedei",
  "dedem",
  "dedes",
  "dedos",
  "dedou",
  "deduz",
  "degas",
  "deias",
  "deilo",
  "deita",
  "deite",
  "deito",
  "deixa",
  "deixe",
  "deixo",
  "dejua",
  "delas",
  "delay",
  "delem",
  "deles",
  "delia",
  "delir",
  "delis",
  "deliu",
  "delta",
  "demao",
  "dembe",
  "dembo",
  "demos",
  "dende",
  "dendo",
  "dengo",
  "denis",
  "densa",
  "dense",
  "denso",
  "denta",
  "dente",
  "dento",
  "deoes",
  "deona",
  "depoe",
  "depor",
  "depos",
  "depre",
  "depus",
  "dequa",
  "deque",
  "deram",
  "deras",
  "derbi",
  "derby",
  "derem",
  "deres",
  "derma",
  "derme",
  "desar",
  "desca",
  "desce",
  "desci",
  "desco",
  "desda",
  "desde",
  "desdo",
  "despe",
  "despi",
  "dessa",
  "desse",
  "desso",
  "desta",
  "deste",
  "desva",
  "detem",
  "deter",
  "deusa",
  "devam",
  "devas",
  "devei",
  "devem",
  "dever",
  "deves",
  "deveu",
  "devia",
  "devim",
  "devio",
  "devir",
  "diaba",
  "diabo",
  "diada",
  "diade",
  "diafa",
  "diana",
  "diate",
  "diaza",
  "dicai",
  "dicam",
  "dicao",
  "dicar",
  "dicas",
  "dicaz",
  "diche",
  "dicho",
  "dicou",
  "didal",
  "didia",
  "dieca",
  "dieno",
  "diese",
  "dieta",
  "difia",
  "digam",
  "digar",
  "digas",
  "digna",
  "digne",
  "digno",
  "dilua",
  "dilui",
  "diluo",
  "dimas",
  "dinar",
  "dinas",
  "dinca",
  "dinda",
  "dinga",
  "dingo",
  "dinos",
  "diodo",
  "diois",
  "diola",
  "dioma",
  "dioso",
  "diple",
  "dique",
  "dirao",
  "diras",
  "direi",
  "diria",
  "diros",
  "dirui",
  "disca",
  "disco",
  "disga",
  "disna",
  "dispa",
  "dispo",
  "disse",
  "disso",
  "dista",
  "diste",
  "disto",
  "ditai",
  "ditam",
  "ditar",
  "ditas",
  "ditei",
  "ditem",
  "dites",
  "ditos",
  "ditou",
  "ditua",
  "divaa",
  "divas",
  "divos",
  "dixes",
  "dizei",
  "dizem",
  "dizer",
  "dizes",
  "dizia",
  "djins",
  "doada",
  "doado",
  "doais",
  "doamo",
  "doara",
  "doava",
  "dobai",
  "dobam",
  "dobar",
  "dobas",
  "dobei",
  "dobem",
  "dobes",
  "dobla",
  "doble",
  "dobou",
  "dobra",
  "dobre",
  "dobro",
  "docai",
  "docal",
  "docam",
  "docar",
  "docas",
  "docei",
  "docem",
  "doces",
  "docil",
  "docou",
  "docto",
  "dodoi",
  "dodos",
  "doeis",
  "doemo",
  "doera",
  "dogal",
  "doges",
  "dogma",
  "dogre",
  "dogua",
  "dogue",
  "doiam",
  "doias",
  "doida",
  "doido",
  "doilo",
  "doira",
  "doire",
  "doiro",
  "dolar",
  "dolce",
  "dolma",
  "dolor",
  "dolos",
  "domai",
  "domam",
  "domar",
  "domas",
  "domei",
  "domem",
  "domes",
  "domos",
  "domou",
  "donas",
  "donde",
  "dondo",
  "donga",
  "dongo",
  "donos",
  "dopai",
  "dopam",
  "dopar",
  "dopas",
  "dopei",
  "dopem",
  "dopes",
  "dopou",
  "doque",
  "doras",
  "dores",
  "doria",
  "dorio",
  "doris",
  "dorme",
  "dormi",
  "dorna",
  "dorso",
  "dosai",
  "dosam",
  "dosar",
  "dosas",
  "dosei",
  "dosem",
  "doses",
  "dosou",
  "dotai",
  "dotal",
  "dotam",
  "dotar",
  "dotas",
  "dotei",
  "dotem",
  "dotes",
  "dotou",
  "douda",
  "doudo",
  "doula",
  "douns",
  "doura",
  "doure",
  "douro",
  "douta",
  "douto",
  "doxas",
  "dozao",
  "draga",
  "drago",
  "drama",
  "dramo",
  "drena",
  "drene",
  "dreno",
  "drias",
  "dribo",
  "drica",
  "drilo",
  "drink",
  "drive",
  "droca",
  "drofa",
  "droga",
  "drogo",
  "dromo",
  "drone",
  "drops",
  "drupa",
  "drusa",
  "druso",
  "duais",
  "duana",
  "dubas",
  "dubia",
  "dubio",
  "dubla",
  "duble",
  "dublo",
  "dubus",
  "ducal",
  "ducao",
  "ducha",
  "duche",
  "ducho",
  "ducto",
  "duela",
  "duele",
  "duelo",
  "dueto",
  "dugao",
  "dulia",
  "dumas",
  "dumba",
  "dunas",
  "dundu",
  "dunfa",
  "dunga",
  "dunia",
  "dupla",
  "duple",
  "duplo",
  "duque",
  "durai",
  "dural",
  "duram",
  "durao",
  "durar",
  "duras",
  "duraz",
  "durei",
  "durem",
  "dures",
  "durez",
  "durio",
  "durma",
  "durmo",
  "duros",
  "durou",
  "dutos",
  "duzia",
  "duzir",
  "duzus",
  "dzeta",
  "ebano",
  "ebeno",
  "ebias",
  "ebola",
  "ebook",
  "ebria",
  "ebrio",
  "ebula",
  "ebule",
  "ebuli",
  "ebulo",
  "ecano",
  "ecese",
  "echar",
  "ecoai",
  "ecoam",
  "ecoar",
  "ecoas",
  "ecoei",
  "ecoem",
  "ecoes",
  "ecoou",
  "ecope",
  "ecran",
  "ecras",
  "ecula",
  "eculo",
  "ecuru",
  "edace",
  "edema",
  "edens",
  "edere",
  "edgar",
  "edico",
  "edipo",
  "edita",
  "edite",
  "edito",
  "edoma",
  "educa",
  "educo",
  "edule",
  "edulo",
  "eduto",
  "eduza",
  "eduze",
  "eduzi",
  "eduzo",
  "efeba",
  "efebo",
  "efeta",
  "efifa",
  "eflua",
  "eflui",
  "efluo",
  "efode",
  "efodo",
  "eforo",
  "efuns",
  "efusa",
  "efuso",
  "egbas",
  "egeia",
  "egide",
  "egito",
  "egria",
  "egrio",
  "egros",
  "eguas",
  "eguns",
  "eicar",
  "eicha",
  "eider",
  "eidos",
  "eiras",
  "eirol",
  "eiros",
  "eirus",
  "eitos",
  "eivai",
  "eivam",
  "eivar",
  "eivas",
  "eivei",
  "eivem",
  "eives",
  "eivou",
  "eixai",
  "eixam",
  "eixar",
  "eixas",
  "eixei",
  "eixem",
  "eixes",
  "eixos",
  "eixou",
  "eixus",
  "ejeta",
  "ejete",
  "ejeto",
  "elado",
  "elafa",
  "elafo",
  "elais",
  "elami",
  "elara",
  "elate",
  "elava",
  "elche",
  "eleda",
  "elege",
  "elegi",
  "eleis",
  "eleja",
  "elejo",
  "elemi",
  "elena",
  "elepe",
  "elera",
  "eleva",
  "eleve",
  "elevo",
  "elfas",
  "elfos",
  "elida",
  "elide",
  "elidi",
  "elido",
  "elige",
  "eligi",
  "elija",
  "elijo",
  "elisa",
  "elite",
  "elixa",
  "elixe",
  "elixo",
  "elmos",
  "eluam",
  "eluas",
  "eluda",
  "elude",
  "eludi",
  "eludo",
  "eluem",
  "eluia",
  "eluir",
  "eluis",
  "eluiu",
  "eluro",
  "emaca",
  "emace",
  "emaco",
  "email",
  "emala",
  "emale",
  "emalo",
  "emana",
  "emane",
  "emano",
  "embai",
  "embes",
  "embui",
  "emeda",
  "emede",
  "emedo",
  "emeio",
  "emela",
  "emele",
  "emelo",
  "emera",
  "emero",
  "emese",
  "emexo",
  "emfim",
  "emica",
  "emico",
  "emira",
  "emita",
  "emite",
  "emiti",
  "emito",
  "emoji",
  "emola",
  "emole",
  "emoli",
  "emolo",
  "empai",
  "empam",
  "empar",
  "empas",
  "empei",
  "empem",
  "empes",
  "empoa",
  "empoe",
  "empoo",
  "empos",
  "empou",
  "emula",
  "emule",
  "emulo",
  "emvao",
  "enano",
  "enase",
  "enata",
  "enate",
  "enato",
  "encha",
  "enche",
  "enchi",
  "encho",
  "endez",
  "endro",
  "endua",
  "enema",
  "eneos",
  "enfia",
  "enfie",
  "enfim",
  "enfio",
  "enfua",
  "enfue",
  "enfuo",
  "engai",
  "engam",
  "engar",
  "engas",
  "engos",
  "engou",
  "engra",
  "engue",
  "enhos",
  "enibu",
  "enito",
  "enjoa",
  "enjoe",
  "enjoo",
  "enoda",
  "enode",
  "enodo",
  "enois",
  "enoja",
  "enoje",
  "enojo",
  "enora",
  "enque",
  "enria",
  "enrie",
  "enrio",
  "ensoa",
  "ensoe",
  "ensoo",
  "entao",
  "enter",
  "entes",
  "enteu",
  "entoa",
  "entoe",
  "entoo",
  "entra",
  "entre",
  "entro",
  "enula",
  "enves",
  "envia",
  "envie",
  "envio",
  "enxia",
  "enxie",
  "enxio",
  "enxos",
  "enxus",
  "enzoi",
  "eolia",
  "eolio",
  "eolos",
  "eones",
  "epica",
  "epico",
  "epoca",
  "epodo",
  "epoxi",
  "epura",
  "epuxa",
  "equeu",
  "equio",
  "equos",
  "erado",
  "erama",
  "erbio",
  "ereba",
  "erebo",
  "ereis",
  "ereos",
  "ereta",
  "ereto",
  "ergam",
  "ergas",
  "ergol",
  "ergue",
  "ergui",
  "erica",
  "erice",
  "erico",
  "erige",
  "erigi",
  "erija",
  "erijo",
  "erina",
  "erino",
  "eripo",
  "ermai",
  "ermam",
  "ermar",
  "ermas",
  "ermei",
  "ermem",
  "ermes",
  "ermos",
  "ermou",
  "eroca",
  "eroda",
  "erode",
  "erodi",
  "erodo",
  "erosa",
  "erose",
  "eroso",
  "erpes",
  "errai",
  "erram",
  "errar",
  "erras",
  "errei",
  "errem",
  "erres",
  "erros",
  "errou",
  "erses",
  "eruca",
  "eruda",
  "erude",
  "erudi",
  "erudo",
  "eruga",
  "eruir",
  "ervai",
  "erval",
  "ervam",
  "ervao",
  "ervar",
  "ervas",
  "ervei",
  "ervem",
  "erves",
  "ervou",
  "escoa",
  "escoe",
  "escol",
  "escoo",
  "esfex",
  "esfia",
  "esfie",
  "esfio",
  "esgar",
  "esima",
  "esipo",
  "esmai",
  "esmam",
  "esmar",
  "esmas",
  "esmei",
  "esmem",
  "esmes",
  "esmoa",
  "esmoe",
  "esmoi",
  "esmoo",
  "esmos",
  "esmou",
  "esoce",
  "espia",
  "espie",
  "espim",
  "espio",
  "espoa",
  "espoe",
  "espoo",
  "espos",
  "espru",
  "espua",
  "espui",
  "espuo",
  "esqui",
  "essas",
  "esses",
  "estai",
  "estao",
  "estar",
  "estas",
  "estau",
  "ester",
  "estes",
  "estia",
  "estie",
  "estil",
  "estim",
  "estio",
  "estol",
  "estos",
  "estou",
  "estra",
  "estre",
  "estro",
  "estua",
  "estue",
  "estuo",
  "esula",
  "esvai",
  "esvao",
  "esvoa",
  "etais",
  "etana",
  "etano",
  "etapa",
  "etego",
  "eteno",
  "ethos",
  "etica",
  "etico",
  "etigo",
  "etila",
  "etile",
  "etilo",
  "etimo",
  "etino",
  "etita",
  "etite",
  "etneu",
  "etnia",
  "etusa",
  "eubeu",
  "euges",
  "euros",
  "evada",
  "evade",
  "evadi",
  "evado",
  "evano",
  "evase",
  "evaza",
  "evaze",
  "evazo",
  "evira",
  "evire",
  "eviro",
  "evita",
  "evite",
  "evito",
  "evoca",
  "evoco",
  "evoes",
  "evola",
  "evole",
  "evolo",
  "exais",
  "exala",
  "exale",
  "exalo",
  "exame",
  "exara",
  "exare",
  "exaro",
  "exata",
  "exato",
  "exiam",
  "exias",
  "exiba",
  "exibe",
  "exibi",
  "exibo",
  "exido",
  "exige",
  "exigi",
  "exija",
  "exijo",
  "exila",
  "exile",
  "exilo",
  "exima",
  "exime",
  "eximi",
  "eximo",
  "exina",
  "exira",
  "exito",
  "exodo",
  "exons",
  "exora",
  "exore",
  "exoro",
  "expia",
  "expie",
  "expio",
  "expoe",
  "expor",
  "expos",
  "expus",
  "exsua",
  "exsue",
  "exsuo",
  "extai",
  "extam",
  "extar",
  "extas",
  "extei",
  "extem",
  "extes",
  "extou",
  "extra",
  "exuis",
  "exula",
  "exule",
  "exulo",
  "exuma",
  "exume",
  "exumo",
  "fabro",
  "facam",
  "facao",
  "facas",
  "facha",
  "facho",
  "facil",
  "facto",
  "fadai",
  "fadam",
  "fadar",
  "fadas",
  "fadei",
  "fadem",
  "fades",
  "fados",
  "fadou",
  "fagar",
  "fagos",
  "faiai",
  "faial",
  "faiam",
  "faiao",
  "faiar",
  "faias",
  "faida",
  "faido",
  "faiei",
  "faiem",
  "faies",
  "faina",
  "fains",
  "faiou",
  "faisa",
  "faixa",
  "faixe",
  "faixo",
  "fajau",
  "fakes",
  "falai",
  "falam",
  "falar",
  "falas",
  "falaz",
  "falca",
  "falco",
  "falda",
  "falei",
  "falem",
  "fales",
  "falha",
  "falhe",
  "falho",
  "falia",
  "falir",
  "falis",
  "faliu",
  "falos",
  "falou",
  "falsa",
  "false",
  "falso",
  "falta",
  "falte",
  "falto",
  "falua",
  "falum",
  "falus",
  "faluz",
  "famas",
  "fanai",
  "fanal",
  "fanam",
  "fanao",
  "fanar",
  "fanas",
  "fanca",
  "fanei",
  "fanem",
  "fanes",
  "fanfa",
  "fanfe",
  "fanfo",
  "fanga",
  "fanha",
  "fanho",
  "fanio",
  "fanos",
  "fanou",
  "faqui",
  "farad",
  "farai",
  "faram",
  "farao",
  "farar",
  "faras",
  "faraz",
  "farda",
  "farde",
  "fardo",
  "farei",
  "farel",
  "farem",
  "fares",
  "fareu",
  "faria",
  "farna",
  "farol",
  "faros",
  "farou",
  "farpa",
  "farpe",
  "farpo",
  "farra",
  "farro",
  "farsa",
  "farta",
  "farte",
  "farto",
  "farum",
  "fasco",
  "fases",
  "fasma",
  "fasor",
  "fasta",
  "fasto",
  "fatal",
  "fatao",
  "fates",
  "fatia",
  "fatie",
  "fatio",
  "fator",
  "fatos",
  "fatua",
  "fatuo",
  "fauce",
  "faula",
  "faule",
  "faulo",
  "fauna",
  "fauno",
  "fauta",
  "favai",
  "faval",
  "favam",
  "favar",
  "favas",
  "favei",
  "favem",
  "faves",
  "favor",
  "favos",
  "favou",
  "faxar",
  "fazei",
  "fazem",
  "fazer",
  "fazes",
  "fazia",
  "febeu",
  "febos",
  "febra",
  "febre",
  "fecal",
  "fecer",
  "fecha",
  "feche",
  "fecho",
  "fecia",
  "fecio",
  "fedam",
  "fedas",
  "fedei",
  "fedem",
  "feder",
  "fedes",
  "fedeu",
  "fedia",
  "fedor",
  "feiao",
  "feias",
  "feija",
  "feila",
  "feios",
  "feira",
  "feire",
  "feiro",
  "feita",
  "feite",
  "feito",
  "feixe",
  "felas",
  "felea",
  "feleo",
  "feles",
  "feleu",
  "felga",
  "felis",
  "feliz",
  "feloa",
  "felos",
  "felpa",
  "felpo",
  "femea",
  "femeo",
  "femur",
  "fenar",
  "fenda",
  "fende",
  "fendi",
  "fendo",
  "fenge",
  "fengi",
  "fenil",
  "fenix",
  "fenjo",
  "fenol",
  "fenos",
  "fento",
  "feral",
  "feras",
  "feraz",
  "ferem",
  "feres",
  "ferga",
  "feria",
  "ferie",
  "ferio",
  "ferir",
  "feris",
  "feriu",
  "feros",
  "feroz",
  "ferpa",
  "ferra",
  "ferre",
  "ferro",
  "ferry",
  "ferto",
  "ferva",
  "ferve",
  "fervi",
  "fervo",
  "festa",
  "feste",
  "festo",
  "fetal",
  "fetao",
  "fetos",
  "feuda",
  "feudo",
  "fevra",
  "fezes",
  "fiada",
  "fiado",
  "fiaia",
  "fiais",
  "fiala",
  "fiapo",
  "fiara",
  "fiaus",
  "fiava",
  "fibra",
  "ficai",
  "ficam",
  "ficar",
  "ficas",
  "ficha",
  "fiche",
  "ficho",
  "ficis",
  "ficos",
  "ficou",
  "ficta",
  "ficto",
  "ficus",
  "fidia",
  "fidos",
  "fieis",
  "fieza",
  "fifia",
  "fifoa",
  "fifos",
  "figas",
  "figla",
  "figle",
  "figos",
  "filai",
  "filam",
  "filao",
  "filar",
  "filas",
  "filei",
  "filem",
  "files",
  "filha",
  "filhe",
  "filho",
  "filia",
  "filie",
  "filio",
  "filma",
  "filme",
  "filmo",
  "filoa",
  "filos",
  "filou",
  "fimas",
  "fimbo",
  "finai",
  "final",
  "finam",
  "finar",
  "finas",
  "finca",
  "finco",
  "finda",
  "finde",
  "findo",
  "finei",
  "finem",
  "fines",
  "finfa",
  "finfe",
  "finfo",
  "finge",
  "fingi",
  "finja",
  "finjo",
  "finos",
  "finou",
  "finta",
  "finte",
  "finto",
  "fiofo",
  "fiota",
  "fiote",
  "fique",
  "firam",
  "firas",
  "firma",
  "firme",
  "firmo",
  "firos",
  "fisco",
  "fises",
  "fisga",
  "fisgo",
  "fitai",
  "fitam",
  "fitao",
  "fitar",
  "fitas",
  "fitei",
  "fitem",
  "fites",
  "fitol",
  "fitos",
  "fitou",
  "fiusa",
  "fiuza",
  "fixai",
  "fixam",
  "fixar",
  "fixas",
  "fixei",
  "fixem",
  "fixes",
  "fixos",
  "fixou",
  "fizer",
  "flaco",
  "flama",
  "flame",
  "flamo",
  "flana",
  "flane",
  "flano",
  "flapa",
  "flape",
  "flash",
  "flata",
  "flato",
  "flava",
  "flavo",
  "flema",
  "fleme",
  "fleta",
  "flete",
  "fleti",
  "fleto",
  "flexa",
  "flexo",
  "floca",
  "floco",
  "flora",
  "flore",
  "flori",
  "floro",
  "flosa",
  "floxo",
  "flozo",
  "fluam",
  "fluas",
  "fluem",
  "fluia",
  "fluir",
  "fluis",
  "fluiu",
  "fluma",
  "flume",
  "fluor",
  "flush",
  "fluta",
  "fluxo",
  "fobai",
  "fobam",
  "fobar",
  "fobas",
  "fobei",
  "fobem",
  "fobes",
  "fobia",
  "fobos",
  "fobou",
  "focai",
  "focal",
  "focam",
  "focar",
  "focas",
  "focio",
  "focos",
  "focou",
  "fodam",
  "fodas",
  "fodei",
  "fodem",
  "foder",
  "fodes",
  "fodeu",
  "fodia",
  "foeta",
  "fofai",
  "fofam",
  "fofao",
  "fofar",
  "fofas",
  "fofei",
  "fofem",
  "fofes",
  "fofos",
  "fofou",
  "fogal",
  "fogao",
  "fogar",
  "fogem",
  "foges",
  "fogoa",
  "fogos",
  "foica",
  "foice",
  "foico",
  "foila",
  "foina",
  "foita",
  "foito",
  "fojos",
  "folao",
  "folar",
  "folas",
  "foles",
  "folga",
  "folgo",
  "folha",
  "folhe",
  "folho",
  "folia",
  "folie",
  "folio",
  "folis",
  "fomes",
  "fomos",
  "fonas",
  "fones",
  "fonjo",
  "fonon",
  "fonos",
  "fonte",
  "foque",
  "foral",
  "foram",
  "foras",
  "forca",
  "force",
  "forco",
  "forda",
  "fordo",
  "forem",
  "fores",
  "foria",
  "forja",
  "forje",
  "forjo",
  "forma",
  "forme",
  "formi",
  "formo",
  "forna",
  "forne",
  "forni",
  "forno",
  "foros",
  "forra",
  "forre",
  "forro",
  "forte",
  "forum",
  "fosca",
  "fosco",
  "fosia",
  "fossa",
  "fosse",
  "fosso",
  "foste",
  "fotas",
  "fotoa",
  "foton",
  "fotos",
  "fouca",
  "fouce",
  "fouco",
  "fovea",
  "foyer",
  "fozes",
  "fraca",
  "fraco",
  "frade",
  "fraga",
  "frago",
  "frama",
  "frase",
  "freai",
  "frear",
  "freda",
  "freei",
  "frega",
  "frege",
  "freia",
  "freie",
  "freio",
  "freis",
  "frema",
  "freme",
  "fremi",
  "fremo",
  "frena",
  "frene",
  "freno",
  "freon",
  "freou",
  "fresa",
  "frese",
  "freso",
  "freta",
  "frete",
  "freto",
  "frevo",
  "frial",
  "frias",
  "friez",
  "frige",
  "frigi",
  "frija",
  "frijo",
  "frila",
  "frima",
  "frino",
  "frios",
  "frisa",
  "frise",
  "friso",
  "frita",
  "frite",
  "frito",
  "friul",
  "froco",
  "frola",
  "frolo",
  "front",
  "frota",
  "froto",
  "fruam",
  "fruas",
  "fruem",
  "fruia",
  "fruir",
  "fruis",
  "fruiu",
  "fruta",
  "frute",
  "fruto",
  "fruxu",
  "fubas",
  "fucai",
  "fucam",
  "fucar",
  "fucas",
  "fucei",
  "fucem",
  "fuces",
  "fucos",
  "fucou",
  "fueta",
  "fufia",
  "fufio",
  "fugai",
  "fugam",
  "fugar",
  "fugas",
  "fugaz",
  "fugia",
  "fugir",
  "fugis",
  "fugiu",
  "fugou",
  "fugue",
  "fujam",
  "fujao",
  "fujas",
  "fulao",
  "fular",
  "fulas",
  "fulda",
  "fulge",
  "fulgi",
  "fuloa",
  "fuloe",
  "fuloo",
  "fulos",
  "fulva",
  "fulvo",
  "fumai",
  "fumal",
  "fumam",
  "fumao",
  "fumar",
  "fumas",
  "fumea",
  "fumei",
  "fumem",
  "fumeo",
  "fumes",
  "fumos",
  "fumou",
  "funca",
  "funce",
  "funda",
  "funde",
  "fundi",
  "fundo",
  "funel",
  "funeu",
  "funga",
  "fungo",
  "funho",
  "funil",
  "funis",
  "funja",
  "funje",
  "fuoes",
  "furai",
  "fural",
  "furam",
  "furao",
  "furar",
  "furas",
  "furco",
  "furda",
  "furei",
  "furem",
  "fures",
  "furga",
  "furia",
  "furna",
  "furoa",
  "furoe",
  "furoo",
  "furor",
  "furos",
  "furou",
  "furta",
  "furte",
  "furto",
  "fusao",
  "fusas",
  "fusca",
  "fusco",
  "fusil",
  "fusos",
  "fusta",
  "fuste",
  "fusto",
  "futes",
  "futil",
  "futre",
  "futum",
  "fuzil",
  "fuzis",
  "fuzos",
  "fuzue",
  "gabai",
  "gabam",
  "gabao",
  "gabar",
  "gabas",
  "gabei",
  "gabem",
  "gabes",
  "gabeu",
  "gabia",
  "gabie",
  "gabio",
  "gaboa",
  "gabos",
  "gabou",
  "gabro",
  "gacha",
  "gacho",
  "gadao",
  "gadar",
  "gados",
  "gafai",
  "gafam",
  "gafar",
  "gafas",
  "gafei",
  "gafem",
  "gafes",
  "gafio",
  "gafos",
  "gafou",
  "gagao",
  "gagas",
  "gagau",
  "gagos",
  "gague",
  "gaiai",
  "gaial",
  "gaiam",
  "gaiar",
  "gaias",
  "gaiei",
  "gaiem",
  "gaies",
  "gaios",
  "gaiou",
  "gaipa",
  "gaipo",
  "gairo",
  "gaita",
  "gaite",
  "gaito",
  "gaiva",
  "gaive",
  "gaivo",
  "gajai",
  "gajam",
  "gajao",
  "gajar",
  "gajas",
  "gajei",
  "gajem",
  "gajes",
  "gajoa",
  "gajos",
  "gajou",
  "galai",
  "galam",
  "galao",
  "galar",
  "galas",
  "galax",
  "galba",
  "galbo",
  "galea",
  "galei",
  "galem",
  "gales",
  "galga",
  "galgo",
  "galha",
  "galho",
  "galia",
  "galio",
  "galoa",
  "galos",
  "galou",
  "galpa",
  "galra",
  "galre",
  "galro",
  "gamai",
  "gamam",
  "gamao",
  "gamar",
  "gamas",
  "gamba",
  "gambo",
  "gamei",
  "gamem",
  "games",
  "gamia",
  "gamos",
  "gamou",
  "ganas",
  "ganau",
  "ganca",
  "gance",
  "ganco",
  "ganda",
  "gando",
  "ganem",
  "ganes",
  "ganez",
  "ganga",
  "gango",
  "ganha",
  "ganhe",
  "ganho",
  "gania",
  "ganir",
  "ganis",
  "ganiu",
  "ganja",
  "ganos",
  "gansa",
  "ganso",
  "ganta",
  "ganza",
  "ganze",
  "ganzi",
  "gapoa",
  "gapos",
  "garao",
  "garba",
  "garbo",
  "garca",
  "garco",
  "gares",
  "garfa",
  "garfe",
  "garfo",
  "garga",
  "gargo",
  "garis",
  "garna",
  "garoa",
  "garoe",
  "garoo",
  "garos",
  "garra",
  "garre",
  "garri",
  "garro",
  "garua",
  "gasca",
  "gases",
  "gasma",
  "gasme",
  "gasmo",
  "gaspa",
  "gasta",
  "gaste",
  "gasto",
  "gatai",
  "gatal",
  "gatam",
  "gatao",
  "gatar",
  "gatas",
  "gatei",
  "gatem",
  "gates",
  "gatil",
  "gatis",
  "gatos",
  "gatou",
  "gatum",
  "gauda",
  "gaude",
  "gaudo",
  "gaulo",
  "gaura",
  "gauro",
  "gauss",
  "gavar",
  "gavea",
  "gazal",
  "gazao",
  "gazas",
  "gazea",
  "gazel",
  "gazeo",
  "gazes",
  "gazia",
  "gazil",
  "gazim",
  "gazio",
  "gazis",
  "gazoa",
  "gazos",
  "gazua",
  "geada",
  "geado",
  "geais",
  "geara",
  "geava",
  "gebai",
  "gebam",
  "gebar",
  "gebas",
  "gebem",
  "gebes",
  "gebos",
  "gebou",
  "gebra",
  "gebre",
  "gebro",
  "geeis",
  "geeks",
  "geena",
  "geiam",
  "geias",
  "geiem",
  "geies",
  "geios",
  "gelai",
  "gelam",
  "gelar",
  "gelas",
  "gelba",
  "gelei",
  "gelem",
  "geles",
  "gelfa",
  "gelha",
  "gelma",
  "gelmo",
  "gelou",
  "gelva",
  "gemai",
  "gemam",
  "gemar",
  "gemas",
  "gemea",
  "gemei",
  "gemem",
  "gemeo",
  "gemer",
  "gemes",
  "gemeu",
  "gemia",
  "gemou",
  "genal",
  "gener",
  "genes",
  "genio",
  "genra",
  "genro",
  "gente",
  "geodo",
  "geosa",
  "geoso",
  "gerai",
  "geral",
  "geram",
  "gerar",
  "geras",
  "gerbo",
  "gerei",
  "gerem",
  "geres",
  "geria",
  "gerir",
  "geris",
  "geriu",
  "germe",
  "germo",
  "gerou",
  "gesse",
  "gesso",
  "gesta",
  "geste",
  "gesto",
  "getas",
  "geteu",
  "giaur",
  "gibao",
  "gibas",
  "gibis",
  "giboa",
  "gicao",
  "gicle",
  "gigas",
  "gigia",
  "gigos",
  "gilas",
  "giles",
  "gilia",
  "gimbo",
  "gimno",
  "ginco",
  "ginga",
  "ginge",
  "gingo",
  "ginja",
  "giota",
  "gipso",
  "girai",
  "giram",
  "girao",
  "girar",
  "giras",
  "girei",
  "girem",
  "gires",
  "giria",
  "girio",
  "giros",
  "girou",
  "gizai",
  "gizam",
  "gizar",
  "gizas",
  "gizei",
  "gizem",
  "gizes",
  "gizou",
  "glace",
  "gleba",
  "glena",
  "glias",
  "glifo",
  "globa",
  "globo",
  "glomo",
  "glosa",
  "glose",
  "gloso",
  "glote",
  "gloto",
  "gluco",
  "gluma",
  "gluon",
  "gluta",
  "gneto",
  "gnoma",
  "gnomo",
  "gnose",
  "goana",
  "goano",
  "gobio",
  "gobos",
  "gocha",
  "gocho",
  "godao",
  "godes",
  "godia",
  "goela",
  "goele",
  "goelo",
  "goesa",
  "goete",
  "gofer",
  "gofra",
  "gofre",
  "gofro",
  "gogas",
  "gogoa",
  "gogos",
  "goiai",
  "goiam",
  "goiar",
  "goias",
  "goiem",
  "goies",
  "goiou",
  "goiti",
  "goiva",
  "goive",
  "goivo",
  "golai",
  "golam",
  "golar",
  "golas",
  "golda",
  "golea",
  "golei",
  "golem",
  "goles",
  "golfa",
  "golfe",
  "golfo",
  "golga",
  "golos",
  "golou",
  "golpe",
  "gomai",
  "gomal",
  "gomam",
  "gomar",
  "gomas",
  "gomba",
  "gombo",
  "gomei",
  "gomem",
  "gomes",
  "gomia",
  "gomil",
  "gomis",
  "gomor",
  "gomos",
  "gomou",
  "gonda",
  "gonde",
  "gondo",
  "gonga",
  "gongo",
  "gonio",
  "gonis",
  "gonus",
  "gonzo",
  "gorai",
  "goral",
  "goram",
  "gorar",
  "goras",
  "goraz",
  "gorda",
  "gordo",
  "gorei",
  "gorem",
  "gores",
  "gorga",
  "gorgi",
  "gorja",
  "gorna",
  "gorne",
  "gorni",
  "gorno",
  "goros",
  "gorou",
  "gorra",
  "gorro",
  "gosma",
  "gosme",
  "gosmo",
  "gosta",
  "goste",
  "gosto",
  "gotas",
  "gotos",
  "gotra",
  "gotri",
  "gouda",
  "gouli",
  "gouva",
  "gouve",
  "gouvi",
  "gouvo",
  "gozai",
  "gozam",
  "gozar",
  "gozas",
  "gozei",
  "gozem",
  "gozes",
  "gozil",
  "gozos",
  "gozou",
  "graal",
  "graca",
  "grada",
  "grade",
  "grado",
  "grafa",
  "grafe",
  "grafo",
  "graia",
  "graie",
  "graio",
  "grais",
  "grama",
  "grame",
  "gramo",
  "grana",
  "grane",
  "grani",
  "grano",
  "grans",
  "graos",
  "grapa",
  "grata",
  "grato",
  "graus",
  "grava",
  "grave",
  "gravo",
  "graxa",
  "graxo",
  "grebe",
  "greco",
  "greda",
  "grede",
  "green",
  "grega",
  "grege",
  "grego",
  "greis",
  "grejo",
  "grela",
  "grele",
  "grelo",
  "grena",
  "grepo",
  "greta",
  "grete",
  "greto",
  "greva",
  "greve",
  "grias",
  "grifa",
  "grife",
  "grifo",
  "grijo",
  "grila",
  "grile",
  "grill",
  "grilo",
  "grima",
  "grios",
  "gripa",
  "gripe",
  "gripo",
  "grisa",
  "grise",
  "griso",
  "grisu",
  "grita",
  "grite",
  "grito",
  "griva",
  "grive",
  "grivo",
  "groir",
  "groja",
  "grola",
  "grole",
  "grolo",
  "groma",
  "gromo",
  "gropa",
  "grosa",
  "grose",
  "groso",
  "grota",
  "grous",
  "gruas",
  "gruau",
  "gruda",
  "grude",
  "grudo",
  "gruem",
  "gruia",
  "gruim",
  "gruir",
  "gruis",
  "gruiu",
  "gruja",
  "gruma",
  "grume",
  "grumo",
  "gruna",
  "grupa",
  "grupe",
  "grupo",
  "gruta",
  "guaca",
  "guaco",
  "guacu",
  "guaia",
  "guaie",
  "guaio",
  "guais",
  "guaiu",
  "guaja",
  "guana",
  "guano",
  "guapa",
  "guape",
  "guapo",
  "guara",
  "guare",
  "guari",
  "guaro",
  "guato",
  "guaxe",
  "guaxi",
  "guaxo",
  "gudes",
  "gueba",
  "guebo",
  "guede",
  "gueis",
  "gueja",
  "guere",
  "gueri",
  "gueta",
  "guete",
  "gueto",
  "guexa",
  "guexo",
  "guiai",
  "guiam",
  "guiao",
  "guiar",
  "guias",
  "guibo",
  "guico",
  "guiei",
  "guiem",
  "guies",
  "guiga",
  "guigo",
  "guina",
  "guine",
  "guino",
  "guios",
  "guiou",
  "guipa",
  "guira",
  "guisa",
  "guise",
  "guiso",
  "guita",
  "guizo",
  "gulag",
  "gular",
  "gulas",
  "gumes",
  "gunas",
  "gunda",
  "gundu",
  "gunfa",
  "gunfe",
  "gunfo",
  "gunga",
  "gurda",
  "gures",
  "guria",
  "guris",
  "gurma",
  "gurus",
  "gusas",
  "gusla",
  "guspa",
  "guspi",
  "guspo",
  "gusta",
  "gusto",
  "gutas",
  "habes",
  "habil",
  "hacas",
  "hacer",
  "hacos",
  "hadji",
  "hajam",
  "hajas",
  "hajes",
  "hajis",
  "hajul",
  "halos",
  "halux",
  "hango",
  "hansa",
  "hapax",
  "haras",
  "harda",
  "harem",
  "harlo",
  "harpa",
  "harpe",
  "harpo",
  "harta",
  "harto",
  "hasta",
  "haste",
  "hauca",
  "haure",
  "hauri",
  "havei",
  "haver",
  "havia",
  "heber",
  "hedge",
  "hedra",
  "heire",
  "helia",
  "helio",
  "helix",
  "hemal",
  "hemes",
  "henas",
  "henes",
  "henry",
  "hepar",
  "hepia",
  "hepta",
  "heras",
  "herda",
  "herde",
  "herdo",
  "hereu",
  "heril",
  "heris",
  "herma",
  "heroi",
  "heroo",
  "hertz",
  "heteu",
  "hetol",
  "hevea",
  "hexil",
  "hexis",
  "hexol",
  "hiala",
  "hialo",
  "hiato",
  "hiava",
  "hicsa",
  "hidra",
  "hidro",
  "hiena",
  "hifas",
  "hifen",
  "higra",
  "hilar",
  "hilos",
  "himba",
  "himen",
  "hindi",
  "hindu",
  "hinos",
  "hiper",
  "hipos",
  "hipsi",
  "hirca",
  "hirco",
  "hirta",
  "hirte",
  "hirto",
  "hispa",
  "hispe",
  "hispo",
  "histo",
  "hobby",
  "hoias",
  "holao",
  "holos",
  "hombo",
  "homem",
  "homoa",
  "homum",
  "honor",
  "honra",
  "honre",
  "honro",
  "hopis",
  "horai",
  "horal",
  "horam",
  "horar",
  "horas",
  "horda",
  "horei",
  "horem",
  "hores",
  "horou",
  "horra",
  "horsa",
  "horta",
  "horte",
  "horto",
  "hosca",
  "hosco",
  "hoste",
  "hotel",
  "houve",
  "hovea",
  "https",
  "huari",
  "huido",
  "hulas",
  "hulha",
  "humor",
  "humos",
  "humus",
  "hungo",
  "hunos",
  "huras",
  "huris",
  "hurra",
  "husky",
  "hutus",
  "iabas",
  "iacas",
  "iagua",
  "iaias",
  "iamba",
  "iambo",
  "iamos",
  "iande",
  "iansa",
  "iaque",
  "iaras",
  "iatas",
  "iates",
  "iaves",
  "ibama",
  "ibaro",
  "ibera",
  "ibere",
  "ibero",
  "ibins",
  "ibira",
  "iboga",
  "ibope",
  "icaca",
  "icaco",
  "icacu",
  "icado",
  "icais",
  "icamo",
  "icara",
  "icaro",
  "icava",
  "iceis",
  "icemo",
  "ichao",
  "ichos",
  "icica",
  "icipo",
  "icone",
  "icore",
  "ictis",
  "ictos",
  "ictus",
  "idade",
  "ideai",
  "ideal",
  "idear",
  "ideei",
  "ideia",
  "ideie",
  "ideio",
  "ideou",
  "idola",
  "idolo",
  "idosa",
  "idoso",
  "idrol",
  "ieixa",
  "ienes",
  "iento",
  "ietim",
  "igapo",
  "igara",
  "igbim",
  "igbos",
  "iglus",
  "ignea",
  "igneo",
  "iguai",
  "igual",
  "iguam",
  "iguas",
  "iguei",
  "iguem",
  "igues",
  "iguou",
  "iiaba",
  "ijebu",
  "ilama",
  "ilaro",
  "ileal",
  "ileon",
  "ileos",
  "ilesa",
  "ileso",
  "ilhai",
  "ilhal",
  "ilham",
  "ilhar",
  "ilhas",
  "ilhei",
  "ilhem",
  "ilhes",
  "ilheu",
  "ilhoa",
  "ilhos",
  "ilhou",
  "iliba",
  "ilibe",
  "ilibo",
  "ilica",
  "ilice",
  "ilico",
  "ilida",
  "ilide",
  "ilidi",
  "ilido",
  "iliga",
  "iligo",
  "ilion",
  "ilios",
  "ilota",
  "iluda",
  "ilude",
  "iludi",
  "iludo",
  "ilusa",
  "iluse",
  "ilusi",
  "iluso",
  "iluta",
  "ilute",
  "iluto",
  "imago",
  "imame",
  "imana",
  "imane",
  "imano",
  "imbas",
  "imbes",
  "imbia",
  "imbua",
  "imbui",
  "imbuo",
  "imbus",
  "imida",
  "imido",
  "imiga",
  "imigo",
  "imina",
  "imita",
  "imite",
  "imiti",
  "imito",
  "imola",
  "imole",
  "imolo",
  "imota",
  "imoto",
  "impai",
  "impam",
  "impar",
  "impas",
  "impei",
  "impem",
  "impes",
  "impia",
  "impio",
  "impoe",
  "impol",
  "impor",
  "impos",
  "impou",
  "impus",
  "imune",
  "imuta",
  "imute",
  "imuto",
  "inaja",
  "inala",
  "inale",
  "inalo",
  "inama",
  "inamu",
  "inana",
  "inane",
  "inani",
  "inano",
  "inata",
  "inato",
  "incai",
  "incam",
  "incar",
  "incas",
  "incei",
  "incem",
  "inces",
  "incha",
  "inche",
  "incho",
  "incoa",
  "incoe",
  "incoo",
  "incos",
  "incou",
  "incus",
  "indas",
  "index",
  "indez",
  "india",
  "indio",
  "indol",
  "indos",
  "indri",
  "indua",
  "induz",
  "inera",
  "inere",
  "ineri",
  "inero",
  "infla",
  "infle",
  "inflo",
  "infra",
  "ingai",
  "ingas",
  "ingre",
  "ingua",
  "inhas",
  "iniba",
  "inibe",
  "inibi",
  "inibo",
  "inion",
  "inios",
  "inite",
  "inoma",
  "inora",
  "inore",
  "inoro",
  "inose",
  "inova",
  "inove",
  "inovo",
  "input",
  "insta",
  "inste",
  "insto",
  "insua",
  "intas",
  "inter",
  "intra",
  "intro",
  "intua",
  "intui",
  "intuo",
  "inuba",
  "inube",
  "inubo",
  "inula",
  "inuma",
  "inume",
  "inumo",
  "invar",
  "inves",
  "invia",
  "invio",
  "iodai",
  "iodam",
  "iodar",
  "iodas",
  "iodei",
  "iodem",
  "iodes",
  "iodol",
  "iodos",
  "iodou",
  "iogas",
  "iogue",
  "ioioa",
  "ioios",
  "ioles",
  "iones",
  "ionia",
  "ionio",
  "ionte",
  "iotas",
  "iotio",
  "ipadu",
  "ipeca",
  "ipecu",
  "ipete",
  "ipins",
  "ipres",
  "ipuas",
  "ipuca",
  "iracu",
  "irada",
  "irade",
  "irado",
  "irais",
  "irama",
  "irara",
  "irava",
  "irdes",
  "ireis",
  "iremo",
  "irene",
  "ireno",
  "irere",
  "iriai",
  "irial",
  "iriam",
  "iriar",
  "irias",
  "iriei",
  "iriem",
  "iries",
  "irina",
  "irino",
  "iriou",
  "irira",
  "irisa",
  "irise",
  "iriso",
  "irite",
  "iriva",
  "iriza",
  "irize",
  "irizo",
  "irmao",
  "irmas",
  "irmos",
  "iroca",
  "irona",
  "irone",
  "irosa",
  "iroso",
  "irres",
  "irucu",
  "isaro",
  "isate",
  "isbas",
  "iscai",
  "iscam",
  "iscar",
  "iscas",
  "iscou",
  "islaa",
  "islao",
  "islas",
  "ismos",
  "isola",
  "isole",
  "isolo",
  "isque",
  "issei",
  "istma",
  "istmo",
  "istoe",
  "isuro",
  "itala",
  "italo",
  "itano",
  "itaua",
  "itens",
  "itera",
  "itere",
  "itero",
  "itobi",
  "itral",
  "itria",
  "itrio",
  "itrol",
  "ituas",
  "ituia",
  "ituis",
  "iucas",
  "iuris",
  "iurte",
  "ixode",
  "ixodo",
  "ixora",
  "jabao",
  "jabas",
  "jabes",
  "jaboa",
  "jabos",
  "jabre",
  "jacas",
  "jacea",
  "jacer",
  "jacis",
  "jacra",
  "jacre",
  "jacta",
  "jacte",
  "jacto",
  "jacui",
  "jacus",
  "jades",
  "jaeza",
  "jaeze",
  "jaezo",
  "jagas",
  "jagra",
  "jagre",
  "jaico",
  "jaime",
  "jaina",
  "jaios",
  "jajas",
  "jalao",
  "jalde",
  "jalne",
  "jaloa",
  "jamba",
  "jambe",
  "jambo",
  "jambu",
  "janal",
  "janau",
  "janda",
  "janga",
  "jango",
  "janja",
  "janta",
  "jante",
  "janto",
  "japao",
  "japas",
  "japia",
  "japim",
  "japis",
  "japix",
  "japoa",
  "japue",
  "japus",
  "jaque",
  "jaras",
  "jarda",
  "jarde",
  "jardo",
  "jares",
  "jarra",
  "jarro",
  "jarus",
  "jaspe",
  "jatai",
  "jatam",
  "jatar",
  "jatas",
  "jatei",
  "jatem",
  "jates",
  "jatis",
  "jatos",
  "jatou",
  "jauas",
  "jaula",
  "jauna",
  "javas",
  "javes",
  "javra",
  "javre",
  "javro",
  "jazam",
  "jazas",
  "jazei",
  "jazem",
  "jazer",
  "jazes",
  "jazeu",
  "jazia",
  "jeans",
  "jebas",
  "jebus",
  "jecas",
  "jegra",
  "jegre",
  "jegue",
  "jeico",
  "jeira",
  "jeita",
  "jeite",
  "jeito",
  "jejes",
  "jejua",
  "jejue",
  "jejum",
  "jejuo",
  "jejus",
  "jembe",
  "jemia",
  "jeova",
  "jepia",
  "jepio",
  "jequa",
  "jeque",
  "jequi",
  "jeros",
  "jerra",
  "jerus",
  "jesus",
  "jetai",
  "jetom",
  "jicao",
  "jicui",
  "jigas",
  "jihad",
  "jijus",
  "jiloa",
  "jilos",
  "jimba",
  "jimbo",
  "jinga",
  "jingo",
  "jipao",
  "jipio",
  "jipis",
  "jiqua",
  "jique",
  "jiqui",
  "jirau",
  "jiroe",
  "jitos",
  "joaes",
  "joana",
  "joane",
  "joaos",
  "jocal",
  "jocas",
  "jocos",
  "jogai",
  "jogam",
  "jogao",
  "jogar",
  "jogas",
  "jogos",
  "jogou",
  "jogue",
  "joias",
  "joiba",
  "joica",
  "joina",
  "joios",
  "jolda",
  "joliz",
  "jonga",
  "jongo",
  "jonia",
  "jonio",
  "jonos",
  "jooes",
  "joona",
  "jorao",
  "jorge",
  "jorna",
  "jorne",
  "jorra",
  "jorre",
  "jorro",
  "jotas",
  "jouca",
  "joule",
  "jovem",
  "juami",
  "juari",
  "juati",
  "jubai",
  "jubao",
  "jubas",
  "jucas",
  "jucia",
  "jucus",
  "judas",
  "judeu",
  "judia",
  "judie",
  "judio",
  "judoa",
  "judos",
  "jugai",
  "jugal",
  "jugam",
  "jugar",
  "jugas",
  "jugos",
  "jugou",
  "jugue",
  "juiza",
  "juizo",
  "jules",
  "julga",
  "julgo",
  "julho",
  "jumas",
  "junca",
  "junco",
  "junge",
  "jungi",
  "jungo",
  "junha",
  "junho",
  "junja",
  "junjo",
  "junta",
  "junte",
  "junto",
  "jupao",
  "jupia",
  "jupua",
  "juqui",
  "jurai",
  "juram",
  "jurao",
  "jurar",
  "juras",
  "jurau",
  "jurei",
  "jurem",
  "jures",
  "juris",
  "juroa",
  "juros",
  "jurou",
  "jurus",
  "jusao",
  "justa",
  "juste",
  "justo",
  "jutai",
  "jutas",
  "jutos",
  "juuna",
  "juvas",
  "juvia",
  "kalio",
  "kansa",
  "karma",
  "kebab",
  "kiwis",
  "krill",
  "kyrie",
  "labeu",
  "labia",
  "labil",
  "labio",
  "laboa",
  "labor",
  "labro",
  "lacai",
  "lacam",
  "lacao",
  "lacar",
  "lacas",
  "lacei",
  "lacem",
  "laces",
  "lacha",
  "lacoa",
  "lacol",
  "lacon",
  "lacos",
  "lacou",
  "lacra",
  "lacre",
  "lacro",
  "lacta",
  "lacte",
  "lacto",
  "ladao",
  "ladas",
  "ladim",
  "lados",
  "ladra",
  "ladre",
  "ladro",
  "lagao",
  "lagar",
  "lages",
  "lagoa",
  "lagos",
  "laias",
  "laica",
  "laico",
  "laido",
  "laima",
  "laiva",
  "laive",
  "laivo",
  "lajao",
  "lajas",
  "lajea",
  "lajes",
  "lamas",
  "lamba",
  "lambe",
  "lambi",
  "lambo",
  "lames",
  "lamia",
  "lampa",
  "lampe",
  "lampo",
  "lanar",
  "lanca",
  "lance",
  "lanco",
  "landa",
  "lande",
  "landi",
  "lando",
  "laneo",
  "langa",
  "lango",
  "lanha",
  "lanhe",
  "lanho",
  "lanio",
  "lapao",
  "lapas",
  "lapes",
  "lapim",
  "lapis",
  "lapsa",
  "lapso",
  "lapuz",
  "laque",
  "larda",
  "lardo",
  "lares",
  "lareu",
  "larga",
  "largo",
  "laria",
  "larim",
  "laris",
  "larix",
  "laroz",
  "larva",
  "lasca",
  "lasco",
  "laser",
  "lasia",
  "lassa",
  "lasse",
  "lasso",
  "latam",
  "latao",
  "latas",
  "latea",
  "latei",
  "latem",
  "lateo",
  "later",
  "lates",
  "lateu",
  "latex",
  "latia",
  "latim",
  "latir",
  "latis",
  "latiu",
  "latoa",
  "latos",
  "lauda",
  "laude",
  "laudo",
  "laues",
  "laule",
  "lauta",
  "lauto",
  "lavai",
  "lavam",
  "lavar",
  "lavas",
  "lavei",
  "lavem",
  "laves",
  "lavor",
  "lavou",
  "lavra",
  "lavre",
  "lavro",
  "laxai",
  "laxam",
  "laxar",
  "laxas",
  "laxei",
  "laxem",
  "laxes",
  "laxos",
  "laxou",
  "lazao",
  "lazer",
  "leais",
  "lebas",
  "lebra",
  "lebre",
  "lecos",
  "ledas",
  "ledes",
  "ledor",
  "ledos",
  "legai",
  "legal",
  "legam",
  "legao",
  "legar",
  "legas",
  "legou",
  "legra",
  "legre",
  "legro",
  "legua",
  "legue",
  "leiam",
  "leias",
  "leiga",
  "leigo",
  "leila",
  "leino",
  "leira",
  "leire",
  "leiro",
  "leita",
  "leite",
  "leito",
  "leiva",
  "leixa",
  "leixe",
  "leixo",
  "leles",
  "lelia",
  "lemas",
  "lembo",
  "lemes",
  "lemna",
  "lemos",
  "lempa",
  "lemur",
  "lenam",
  "lenao",
  "lenas",
  "lenco",
  "lenda",
  "lendo",
  "lenea",
  "lenem",
  "lenes",
  "lenha",
  "lenhe",
  "lenho",
  "lenia",
  "lenir",
  "lenis",
  "leniu",
  "lenta",
  "lente",
  "lento",
  "leoas",
  "leoes",
  "leona",
  "lepas",
  "lepes",
  "lepis",
  "lepra",
  "lepto",
  "lequa",
  "leque",
  "leram",
  "lerao",
  "leras",
  "lerca",
  "lerda",
  "lerdo",
  "lerei",
  "lerem",
  "leres",
  "leria",
  "lerna",
  "lesai",
  "lesam",
  "lesao",
  "lesar",
  "lesas",
  "lesei",
  "lesem",
  "leses",
  "lesim",
  "lesma",
  "lesos",
  "lesou",
  "lesse",
  "lesta",
  "leste",
  "lesto",
  "letal",
  "letao",
  "leteu",
  "letos",
  "letra",
  "letre",
  "letro",
  "leuco",
  "levai",
  "levam",
  "levar",
  "levas",
  "levei",
  "levem",
  "leves",
  "levez",
  "levou",
  "lexia",
  "lhama",
  "lhana",
  "lhano",
  "liabo",
  "liaca",
  "liado",
  "liais",
  "liame",
  "liana",
  "liara",
  "liava",
  "libai",
  "libam",
  "libar",
  "libas",
  "libau",
  "libei",
  "libem",
  "liber",
  "libes",
  "libia",
  "libio",
  "libou",
  "libra",
  "libre",
  "libro",
  "libua",
  "licao",
  "licas",
  "liceu",
  "licia",
  "licio",
  "licne",
  "licor",
  "licos",
  "lidai",
  "lidam",
  "lidar",
  "lidas",
  "lidei",
  "lidem",
  "lider",
  "lides",
  "lidia",
  "lidio",
  "lidos",
  "lidou",
  "lieis",
  "lieno",
  "ligai",
  "ligal",
  "ligam",
  "ligar",
  "ligas",
  "ligeu",
  "light",
  "ligia",
  "ligio",
  "ligou",
  "ligue",
  "lilas",
  "lilia",
  "lilio",
  "lilis",
  "limai",
  "limam",
  "limao",
  "limar",
  "limas",
  "limbo",
  "limei",
  "limem",
  "limes",
  "limos",
  "limou",
  "limpa",
  "limpe",
  "limpo",
  "linca",
  "lince",
  "linco",
  "linda",
  "linde",
  "lindo",
  "linea",
  "lineo",
  "linfa",
  "linfe",
  "linfo",
  "linga",
  "lingo",
  "linha",
  "linho",
  "linka",
  "linke",
  "linko",
  "links",
  "lipes",
  "liral",
  "lirao",
  "liras",
  "liria",
  "lirio",
  "lisas",
  "lises",
  "lisia",
  "lisio",
  "lisma",
  "lisme",
  "lismo",
  "lisol",
  "lisos",
  "lissa",
  "lista",
  "liste",
  "listo",
  "litai",
  "litam",
  "litao",
  "litar",
  "litas",
  "litei",
  "litem",
  "lites",
  "litia",
  "litio",
  "litor",
  "litou",
  "litro",
  "lituo",
  "lives",
  "livia",
  "livor",
  "livra",
  "livre",
  "livro",
  "lixai",
  "lixam",
  "lixao",
  "lixar",
  "lixas",
  "lixei",
  "lixem",
  "lixes",
  "lixoa",
  "lixos",
  "lixou",
  "lizai",
  "lizam",
  "lizar",
  "lizas",
  "lizei",
  "lizem",
  "lizes",
  "lizou",
  "loado",
  "loais",
  "loara",
  "loasa",
  "loava",
  "lobal",
  "lobao",
  "lobar",
  "lobaz",
  "lobby",
  "lobos",
  "locai",
  "local",
  "locam",
  "locao",
  "locar",
  "locas",
  "locos",
  "locou",
  "locra",
  "locus",
  "lodao",
  "lodos",
  "loeis",
  "lofts",
  "logar",
  "logea",
  "logia",
  "login",
  "logon",
  "logos",
  "logra",
  "logre",
  "logro",
  "loias",
  "loica",
  "loios",
  "loira",
  "loire",
  "loiro",
  "loisa",
  "lojas",
  "lolia",
  "lolio",
  "lomba",
  "lombe",
  "lombo",
  "lonas",
  "lonca",
  "londo",
  "longa",
  "longe",
  "longo",
  "looks",
  "lopes",
  "loqua",
  "loque",
  "lorca",
  "lorco",
  "lorde",
  "lordo",
  "lores",
  "lorga",
  "loris",
  "loros",
  "lorpa",
  "losia",
  "losna",
  "lotai",
  "lotam",
  "lotar",
  "lotas",
  "lotea",
  "lotei",
  "lotem",
  "lotes",
  "lotos",
  "lotou",
  "lotus",
  "louca",
  "louco",
  "loura",
  "loure",
  "louro",
  "lousa",
  "louva",
  "louve",
  "louvo",
  "loxia",
  "luada",
  "luaia",
  "luaus",
  "lubas",
  "lucha",
  "luche",
  "lucho",
  "lucio",
  "lucos",
  "lucra",
  "lucre",
  "lucro",
  "ludio",
  "ludos",
  "ludra",
  "ludro",
  "lueta",
  "lufai",
  "lufam",
  "lufar",
  "lufas",
  "lufei",
  "lufem",
  "lufes",
  "lufou",
  "lugar",
  "lugre",
  "luisa",
  "lulao",
  "lulas",
  "lulus",
  "lumbo",
  "lumen",
  "lumia",
  "lumie",
  "lumio",
  "lunar",
  "lunas",
  "lunda",
  "lundu",
  "lunga",
  "lupai",
  "lupam",
  "lupar",
  "lupas",
  "lupei",
  "lupem",
  "lupes",
  "lupia",
  "lupos",
  "lupou",
  "lupus",
  "luque",
  "lurai",
  "luram",
  "lurar",
  "luras",
  "lurda",
  "lurei",
  "lurem",
  "lures",
  "lurex",
  "lurgo",
  "luria",
  "lurou",
  "lurta",
  "lusca",
  "lusco",
  "lusos",
  "lutai",
  "lutam",
  "lutar",
  "lutas",
  "lutea",
  "lutei",
  "lutem",
  "luteo",
  "lutes",
  "lutos",
  "lutou",
  "luvas",
  "luxai",
  "luxam",
  "luxar",
  "luxas",
  "luxei",
  "luxem",
  "luxes",
  "luxos",
  "luxou",
  "luzam",
  "luzas",
  "luzem",
  "luzes",
  "luzia",
  "luzio",
  "luzir",
  "luzis",
  "luziu",
  "lycra",
  "mabas",
  "mabiu",
  "macaa",
  "macai",
  "macal",
  "macam",
  "macao",
  "macar",
  "macas",
  "macau",
  "macea",
  "macei",
  "macem",
  "maces",
  "macha",
  "mache",
  "macho",
  "macia",
  "macie",
  "macio",
  "macis",
  "macla",
  "macom",
  "macos",
  "macou",
  "macro",
  "macua",
  "macus",
  "madia",
  "madre",
  "mafau",
  "mafia",
  "mafra",
  "mafua",
  "magal",
  "magas",
  "magda",
  "magia",
  "magis",
  "magma",
  "magna",
  "magne",
  "magno",
  "magoa",
  "magoe",
  "magoo",
  "magos",
  "magra",
  "magro",
  "maiao",
  "maias",
  "maica",
  "maina",
  "maino",
  "maioa",
  "maior",
  "maios",
  "maira",
  "mairu",
  "maita",
  "major",
  "malar",
  "malas",
  "malda",
  "malde",
  "maldo",
  "males",
  "malga",
  "malha",
  "malhe",
  "malho",
  "malim",
  "malos",
  "malsa",
  "malta",
  "malte",
  "malto",
  "malva",
  "malvo",
  "mamae",
  "mamai",
  "mamal",
  "mamam",
  "mamao",
  "mamar",
  "mamas",
  "mamba",
  "mambo",
  "mambu",
  "mamei",
  "mamem",
  "mames",
  "mamoa",
  "mamou",
  "mamua",
  "mamum",
  "manai",
  "manam",
  "manar",
  "manas",
  "manau",
  "manca",
  "manco",
  "manda",
  "mande",
  "mandi",
  "mando",
  "mandu",
  "manea",
  "manei",
  "manel",
  "manem",
  "manes",
  "manga",
  "mango",
  "mangu",
  "manha",
  "manho",
  "mania",
  "manir",
  "manis",
  "maniu",
  "manja",
  "manje",
  "manjo",
  "manju",
  "manos",
  "manou",
  "mansa",
  "manso",
  "manta",
  "mante",
  "manto",
  "manue",
  "manul",
  "maona",
  "maori",
  "mapas",
  "mapea",
  "mapia",
  "mapie",
  "mapio",
  "mapua",
  "maque",
  "maqui",
  "marai",
  "maram",
  "marao",
  "marar",
  "maras",
  "marau",
  "marca",
  "marco",
  "marei",
  "marel",
  "marem",
  "mares",
  "marfa",
  "marfe",
  "marfi",
  "marfo",
  "marga",
  "margo",
  "marim",
  "mario",
  "maris",
  "marma",
  "marme",
  "marmo",
  "marna",
  "marno",
  "maroa",
  "maroo",
  "maros",
  "marou",
  "marra",
  "marre",
  "marro",
  "marsa",
  "marso",
  "marta",
  "marte",
  "marto",
  "marua",
  "marui",
  "masca",
  "masco",
  "maser",
  "massa",
  "masse",
  "masso",
  "masto",
  "matai",
  "matal",
  "matam",
  "matar",
  "matas",
  "match",
  "matei",
  "matem",
  "mater",
  "mates",
  "matia",
  "matis",
  "matiz",
  "matos",
  "matou",
  "matri",
  "matue",
  "mauba",
  "maues",
  "mauis",
  "mauja",
  "maujo",
  "maula",
  "maura",
  "mauza",
  "mavia",
  "maxim",
  "mazar",
  "mazas",
  "mazia",
  "meaco",
  "meada",
  "meado",
  "meago",
  "meais",
  "meano",
  "meaos",
  "meara",
  "meari",
  "meato",
  "meava",
  "mecam",
  "mecas",
  "mecha",
  "meche",
  "mecho",
  "mecia",
  "mecos",
  "mecru",
  "medao",
  "medas",
  "medem",
  "medes",
  "media",
  "medio",
  "medir",
  "medis",
  "mediu",
  "medoa",
  "medol",
  "medos",
  "medra",
  "medre",
  "medro",
  "meeis",
  "meiam",
  "meiao",
  "meias",
  "meiem",
  "meies",
  "meiga",
  "meigo",
  "meios",
  "meiri",
  "melai",
  "melam",
  "melao",
  "melar",
  "melas",
  "melca",
  "melea",
  "melei",
  "melem",
  "meleo",
  "meles",
  "melez",
  "melfa",
  "melfo",
  "melga",
  "melgo",
  "melia",
  "meloa",
  "meloe",
  "melos",
  "melou",
  "melra",
  "melro",
  "membe",
  "membi",
  "memes",
  "memis",
  "menar",
  "menea",
  "menga",
  "mengo",
  "menha",
  "menim",
  "menir",
  "menor",
  "menos",
  "mensa",
  "menso",
  "menta",
  "mente",
  "menti",
  "mento",
  "menus",
  "menza",
  "meolo",
  "meona",
  "meota",
  "meras",
  "merca",
  "merce",
  "merco",
  "merda",
  "merio",
  "merla",
  "merlo",
  "merma",
  "merme",
  "mermo",
  "meros",
  "merua",
  "merui",
  "merus",
  "mesas",
  "mesca",
  "mesco",
  "meses",
  "mesio",
  "mesma",
  "mesmo",
  "meson",
  "mesor",
  "messa",
  "messe",
  "messo",
  "mesta",
  "mesto",
  "metal",
  "metam",
  "metas",
  "metei",
  "metem",
  "meter",
  "metes",
  "meteu",
  "metia",
  "metie",
  "metil",
  "metim",
  "metis",
  "metro",
  "meule",
  "mexam",
  "mexao",
  "mexas",
  "mexei",
  "mexem",
  "mexer",
  "mexes",
  "mexeu",
  "mexia",
  "miada",
  "miado",
  "miaia",
  "miais",
  "miana",
  "miaos",
  "miara",
  "miaus",
  "miava",
  "mibia",
  "micai",
  "micam",
  "micar",
  "micas",
  "micha",
  "miche",
  "micho",
  "micio",
  "micos",
  "micou",
  "micra",
  "micro",
  "micta",
  "micte",
  "micto",
  "midia",
  "mieis",
  "mielo",
  "migai",
  "migam",
  "migar",
  "migas",
  "migma",
  "migou",
  "migra",
  "migre",
  "migro",
  "migue",
  "miina",
  "miite",
  "mijai",
  "mijam",
  "mijao",
  "mijar",
  "mijas",
  "mijei",
  "mijem",
  "mijes",
  "mijos",
  "mijou",
  "mijui",
  "milao",
  "milde",
  "mileo",
  "milha",
  "milhe",
  "milho",
  "mimai",
  "mimam",
  "mimar",
  "mimas",
  "mimei",
  "mimem",
  "mimes",
  "mimis",
  "mimos",
  "mimou",
  "minai",
  "minam",
  "minar",
  "minas",
  "minaz",
  "minei",
  "minem",
  "mines",
  "minga",
  "mingo",
  "mingu",
  "minha",
  "minio",
  "minis",
  "minor",
  "minou",
  "minta",
  "minto",
  "minua",
  "minui",
  "minuo",
  "mioca",
  "miojo",
  "miola",
  "miolo",
  "mioma",
  "miope",
  "miose",
  "mioto",
  "mique",
  "mirai",
  "miram",
  "mirao",
  "mirar",
  "miras",
  "mirei",
  "mirem",
  "mires",
  "mirim",
  "miris",
  "mirou",
  "mirra",
  "mirre",
  "mirro",
  "mirta",
  "mirto",
  "misca",
  "misco",
  "misgo",
  "missa",
  "misse",
  "misso",
  "mista",
  "miste",
  "misto",
  "mitai",
  "mitam",
  "mitar",
  "mitas",
  "mitei",
  "mitem",
  "mites",
  "mitis",
  "mitos",
  "mitou",
  "mitra",
  "mitre",
  "mitro",
  "mitua",
  "miuas",
  "miuca",
  "miuda",
  "miude",
  "miudo",
  "miula",
  "miulo",
  "miura",
  "miuro",
  "miuva",
  "mivas",
  "mixai",
  "mixam",
  "mixar",
  "mixas",
  "mixei",
  "mixem",
  "mixes",
  "mixia",
  "mixos",
  "mixou",
  "mixta",
  "mixto",
  "mnese",
  "moadi",
  "moado",
  "moafa",
  "moais",
  "mobil",
  "mocai",
  "mocam",
  "mocao",
  "mocar",
  "mocas",
  "mocem",
  "moces",
  "mocha",
  "moche",
  "mocho",
  "mocoa",
  "mocos",
  "mocou",
  "mocsa",
  "mocui",
  "modal",
  "modas",
  "modem",
  "modio",
  "modos",
  "modus",
  "moeda",
  "moega",
  "moeis",
  "moela",
  "moera",
  "moeta",
  "mofai",
  "mofam",
  "mofar",
  "mofas",
  "mofei",
  "mofem",
  "mofes",
  "mofos",
  "mofou",
  "mofti",
  "mogao",
  "mogno",
  "mogol",
  "mogor",
  "moiam",
  "moiao",
  "moias",
  "moico",
  "moida",
  "moido",
  "moina",
  "moine",
  "moino",
  "moios",
  "moira",
  "moire",
  "moiro",
  "moita",
  "mojar",
  "molal",
  "molar",
  "molas",
  "molda",
  "molde",
  "moldo",
  "moles",
  "molha",
  "molhe",
  "molho",
  "molim",
  "molio",
  "momos",
  "monam",
  "monas",
  "monca",
  "monco",
  "monda",
  "monde",
  "mondo",
  "monem",
  "mones",
  "monge",
  "mongi",
  "mongu",
  "monha",
  "monhe",
  "monho",
  "monia",
  "monir",
  "monis",
  "moniu",
  "monja",
  "monjo",
  "monos",
  "monso",
  "monta",
  "monte",
  "monto",
  "moqua",
  "moque",
  "moqui",
  "moquo",
  "morai",
  "moral",
  "moram",
  "morao",
  "morar",
  "moras",
  "morbo",
  "morca",
  "morda",
  "morde",
  "mordi",
  "morei",
  "morem",
  "mores",
  "morfa",
  "morfe",
  "morfo",
  "morga",
  "morgo",
  "moria",
  "moril",
  "morim",
  "morma",
  "mormo",
  "morna",
  "morne",
  "morno",
  "morou",
  "morra",
  "morre",
  "morri",
  "morro",
  "morsa",
  "morse",
  "morso",
  "morta",
  "morte",
  "morto",
  "mosba",
  "mosca",
  "mosco",
  "mossa",
  "mosse",
  "mosso",
  "mosto",
  "motas",
  "motel",
  "motes",
  "motim",
  "motor",
  "motos",
  "motum",
  "mouca",
  "mouco",
  "moura",
  "moure",
  "mouro",
  "mouse",
  "mouta",
  "movam",
  "movas",
  "movei",
  "movel",
  "movem",
  "mover",
  "moves",
  "moveu",
  "movia",
  "moxar",
  "moxas",
  "moxos",
  "mozes",
  "muaca",
  "muafa",
  "muafo",
  "muaje",
  "muama",
  "muane",
  "muari",
  "muave",
  "mubia",
  "mubus",
  "mucia",
  "mucol",
  "mucor",
  "mucos",
  "mucro",
  "mucua",
  "mucui",
  "mucum",
  "mucus",
  "mudai",
  "mudam",
  "mudar",
  "mudas",
  "mudei",
  "mudem",
  "mudes",
  "mudez",
  "mudir",
  "mudos",
  "mudou",
  "mudra",
  "mueia",
  "muele",
  "muene",
  "muere",
  "mufas",
  "mufla",
  "mufti",
  "mufui",
  "mugem",
  "muges",
  "mugia",
  "mugil",
  "mugir",
  "mugis",
  "mugiu",
  "mugre",
  "muias",
  "muilo",
  "muita",
  "muito",
  "mujam",
  "mujas",
  "mulas",
  "mulei",
  "muleo",
  "mules",
  "muleu",
  "mulme",
  "mulos",
  "mulsa",
  "mulso",
  "multa",
  "multe",
  "multi",
  "multo",
  "mulum",
  "mumbe",
  "mumia",
  "mumus",
  "munda",
  "munde",
  "mundo",
  "munem",
  "munes",
  "munga",
  "munge",
  "mungi",
  "mungo",
  "mungu",
  "munha",
  "munho",
  "munia",
  "munir",
  "munis",
  "muniu",
  "munja",
  "munjo",
  "munto",
  "munus",
  "muolo",
  "muons",
  "muqua",
  "muque",
  "murai",
  "mural",
  "muram",
  "murar",
  "muras",
  "murca",
  "murco",
  "murei",
  "murem",
  "mures",
  "muria",
  "muros",
  "murou",
  "murra",
  "murro",
  "murso",
  "murta",
  "murua",
  "murui",
  "murus",
  "murza",
  "musal",
  "musas",
  "musca",
  "musco",
  "museu",
  "musga",
  "musgo",
  "musme",
  "musse",
  "musso",
  "mutas",
  "mutia",
  "mutom",
  "mutra",
  "mutre",
  "mutro",
  "mutua",
  "mutue",
  "mutum",
  "mutuo",
  "muuba",
  "muviu",
  "muzua",
  "nabal",
  "nabao",
  "nabla",
  "nablo",
  "nabos",
  "nacao",
  "nacar",
  "nacas",
  "nacho",
  "nacos",
  "nacre",
  "nadai",
  "nadam",
  "nadar",
  "nadas",
  "nadei",
  "nadem",
  "nades",
  "nadir",
  "nados",
  "nadou",
  "nafes",
  "nafir",
  "nafis",
  "nafta",
  "nagao",
  "nagar",
  "nagis",
  "nagoa",
  "nagor",
  "nagos",
  "nagua",
  "nagul",
  "naias",
  "naibe",
  "naifa",
  "naife",
  "naifo",
  "naipa",
  "naipe",
  "naira",
  "naire",
  "najas",
  "nalga",
  "namas",
  "nambi",
  "nambu",
  "nanai",
  "nanal",
  "nanam",
  "nanar",
  "nanas",
  "nando",
  "nandu",
  "nanei",
  "nanem",
  "nanes",
  "nanga",
  "nanis",
  "nanja",
  "nanou",
  "napas",
  "naras",
  "naraz",
  "narco",
  "narda",
  "nardo",
  "nariz",
  "narlo",
  "narra",
  "narre",
  "narro",
  "nasal",
  "nasca",
  "nasce",
  "nasci",
  "nasco",
  "nasia",
  "nasio",
  "nassa",
  "nassi",
  "nasso",
  "nasua",
  "natal",
  "natas",
  "natia",
  "natio",
  "natos",
  "natro",
  "nauas",
  "nauro",
  "nauta",
  "naval",
  "navao",
  "navas",
  "naves",
  "navia",
  "navim",
  "navio",
  "naxia",
  "naxio",
  "nazir",
  "nazis",
  "nebel",
  "nebri",
  "necra",
  "necro",
  "necto",
  "nedia",
  "nedio",
  "nefas",
  "nefro",
  "negai",
  "negam",
  "negao",
  "negar",
  "negas",
  "negos",
  "negou",
  "negra",
  "negro",
  "negue",
  "negus",
  "nejas",
  "nelas",
  "neldo",
  "neles",
  "nelia",
  "nemba",
  "nembo",
  "nemeu",
  "nenas",
  "nende",
  "nendi",
  "nenem",
  "nenen",
  "nenes",
  "nenha",
  "nenho",
  "nenia",
  "nenio",
  "nenos",
  "nente",
  "neons",
  "nepal",
  "neper",
  "neque",
  "neres",
  "neria",
  "nerio",
  "neris",
  "neros",
  "nerto",
  "nervo",
  "nesga",
  "nessa",
  "nesse",
  "nesta",
  "neste",
  "netas",
  "netos",
  "neuma",
  "neura",
  "neuri",
  "neuro",
  "neusa",
  "nevai",
  "nevam",
  "nevao",
  "nevar",
  "nevas",
  "nevei",
  "nevem",
  "neves",
  "nevoa",
  "nevoe",
  "nevoo",
  "nevos",
  "nevou",
  "nevri",
  "nevro",
  "nexos",
  "nhaca",
  "nhata",
  "nhati",
  "nhato",
  "nhele",
  "nhora",
  "niaia",
  "niboe",
  "nicai",
  "nicam",
  "nicar",
  "nicas",
  "nicha",
  "niche",
  "nicho",
  "nicou",
  "nicto",
  "nidor",
  "niela",
  "nielo",
  "nifao",
  "nifes",
  "nigua",
  "nilas",
  "nilgo",
  "nilio",
  "nilos",
  "nimba",
  "nimbe",
  "nimbo",
  "nimia",
  "nimio",
  "ninai",
  "ninam",
  "ninar",
  "ninas",
  "ninei",
  "ninem",
  "nines",
  "ninfa",
  "ninha",
  "ninhe",
  "ninho",
  "ninis",
  "ninja",
  "ninou",
  "niopo",
  "nipas",
  "nipio",
  "nique",
  "nisas",
  "nisca",
  "nisco",
  "nissa",
  "nisso",
  "nisto",
  "niton",
  "nitra",
  "nitre",
  "nitri",
  "nitro",
  "nivea",
  "nivel",
  "niveo",
  "nobel",
  "nobio",
  "nobre",
  "nocao",
  "nocha",
  "nodai",
  "nodal",
  "nodam",
  "nodar",
  "nodas",
  "nodei",
  "nodem",
  "nodes",
  "nodio",
  "nodoa",
  "nodoe",
  "nodoo",
  "nodos",
  "nodou",
  "noema",
  "noese",
  "noete",
  "nogai",
  "nogal",
  "nogao",
  "noias",
  "noira",
  "noite",
  "noiva",
  "noive",
  "noivo",
  "nojos",
  "nomas",
  "nomea",
  "nomes",
  "nomos",
  "nonal",
  "nonas",
  "nonca",
  "nonde",
  "nondo",
  "nones",
  "nonga",
  "nonia",
  "nonio",
  "nonos",
  "nopal",
  "noqua",
  "noque",
  "noras",
  "norca",
  "noria",
  "norio",
  "norma",
  "noros",
  "norso",
  "norte",
  "nosco",
  "nossa",
  "nosso",
  "notai",
  "notal",
  "notam",
  "notar",
  "notas",
  "notei",
  "notem",
  "notes",
  "notos",
  "notou",
  "notra",
  "nouca",
  "noute",
  "novai",
  "noval",
  "novam",
  "novar",
  "novas",
  "novei",
  "novel",
  "novem",
  "noves",
  "novos",
  "novou",
  "noxal",
  "noxia",
  "noxio",
  "nozes",
  "nozul",
  "nubas",
  "nubia",
  "nubil",
  "nubio",
  "nubla",
  "nuble",
  "nublo",
  "nucal",
  "nucao",
  "nucas",
  "nucio",
  "nudez",
  "nudos",
  "nuela",
  "nuelo",
  "nueza",
  "nufar",
  "nugas",
  "nulos",
  "numas",
  "numbu",
  "numes",
  "nunca",
  "nundo",
  "nunes",
  "nuruz",
  "nutai",
  "nutam",
  "nutar",
  "nutas",
  "nutei",
  "nutem",
  "nutes",
  "nutos",
  "nutou",
  "nutra",
  "nutre",
  "nutri",
  "nutro",
  "nuvem",
  "nuveo",
  "nuvre",
  "nuxia",
  "nylon",
  "oasis",
  "obela",
  "obelo",
  "obera",
  "obere",
  "obero",
  "obesa",
  "obeso",
  "obice",
  "obito",
  "oboaz",
  "oboes",
  "obolo",
  "obrai",
  "obram",
  "obrar",
  "obras",
  "obrei",
  "obrem",
  "obres",
  "obrou",
  "obsia",
  "obsta",
  "obste",
  "obsto",
  "obtem",
  "obter",
  "obvem",
  "obvia",
  "obvie",
  "obvim",
  "obvio",
  "obvir",
  "ocado",
  "ocais",
  "ocapi",
  "ocara",
  "ocaso",
  "ocava",
  "ocelo",
  "ochas",
  "ocide",
  "ocima",
  "ocimo",
  "ocios",
  "oclua",
  "oclui",
  "ocluo",
  "ocrea",
  "octas",
  "octil",
  "octis",
  "ocula",
  "ocule",
  "oculo",
  "ocupa",
  "ocupe",
  "ocupo",
  "odara",
  "odeao",
  "odeia",
  "odeie",
  "odeio",
  "odiai",
  "odiar",
  "odias",
  "odiei",
  "odina",
  "odios",
  "odiou",
  "odita",
  "odito",
  "odora",
  "odore",
  "odoro",
  "odres",
  "oeste",
  "ofato",
  "ofega",
  "ofego",
  "ofeso",
  "ofico",
  "ofira",
  "ofita",
  "ofite",
  "ofito",
  "ofria",
  "ofrio",
  "ofris",
  "ofuro",
  "ogano",
  "ogiva",
  "ogras",
  "ogres",
  "ogros",
  "oguns",
  "oiara",
  "oicas",
  "oidio",
  "oigar",
  "oigas",
  "oigou",
  "oigue",
  "oirai",
  "oiram",
  "oirar",
  "oiras",
  "oirei",
  "oirem",
  "oires",
  "oiros",
  "oirou",
  "oitao",
  "oitis",
  "oitoa",
  "oitos",
  "olace",
  "olaia",
  "oleai",
  "olear",
  "oleei",
  "oleia",
  "oleie",
  "oleio",
  "oleno",
  "oleol",
  "oleos",
  "oleou",
  "olgas",
  "olhai",
  "olhal",
  "olham",
  "olhao",
  "olhar",
  "olhas",
  "olhei",
  "olhem",
  "olhes",
  "olhos",
  "olhou",
  "oligo",
  "olina",
  "olira",
  "oliva",
  "olivo",
  "olmos",
  "omado",
  "omasa",
  "omaso",
  "ombro",
  "omega",
  "omina",
  "omine",
  "omino",
  "omiri",
  "omita",
  "omite",
  "omiti",
  "omito",
  "oncao",
  "oncas",
  "ondai",
  "ondam",
  "ondar",
  "ondas",
  "ondea",
  "ondei",
  "ondem",
  "ondes",
  "ondim",
  "ondou",
  "onera",
  "onere",
  "onero",
  "oneta",
  "onfra",
  "onixa",
  "onixe",
  "ontem",
  "oonim",
  "opaca",
  "opaco",
  "opada",
  "opado",
  "opais",
  "opala",
  "opara",
  "opava",
  "opcao",
  "opeis",
  "opera",
  "opere",
  "opero",
  "opiai",
  "opiam",
  "opiar",
  "opias",
  "opido",
  "opiei",
  "opiem",
  "opies",
  "opila",
  "opile",
  "opilo",
  "opima",
  "opimo",
  "opina",
  "opine",
  "opino",
  "opios",
  "opiou",
  "opoem",
  "opoes",
  "opora",
  "optai",
  "optam",
  "optar",
  "optas",
  "optei",
  "optem",
  "optes",
  "optou",
  "oquea",
  "oquei",
  "oquem",
  "oques",
  "oquim",
  "orada",
  "orado",
  "orago",
  "orais",
  "orara",
  "orate",
  "orava",
  "orbes",
  "orcai",
  "orcam",
  "orcar",
  "orcas",
  "orcaz",
  "orcei",
  "orcem",
  "orces",
  "orcos",
  "orcou",
  "ordem",
  "oreai",
  "orear",
  "oreia",
  "oreie",
  "oreio",
  "oreis",
  "oreou",
  "orfao",
  "orfas",
  "orfea",
  "orfia",
  "orfio",
  "orgao",
  "orgia",
  "orgio",
  "orias",
  "orida",
  "orion",
  "orita",
  "orite",
  "orixa",
  "orlai",
  "orlam",
  "orlar",
  "orlas",
  "orlea",
  "orlei",
  "orlem",
  "orles",
  "orlou",
  "ornai",
  "ornam",
  "ornar",
  "ornas",
  "ornei",
  "ornem",
  "ornes",
  "ornis",
  "ornou",
  "oroba",
  "orobo",
  "oroca",
  "orqui",
  "ortos",
  "osada",
  "osana",
  "oscar",
  "oscos",
  "osgas",
  "osmia",
  "osmio",
  "ossea",
  "osseo",
  "ossos",
  "ostai",
  "ostar",
  "ostes",
  "ostio",
  "ostra",
  "ostro",
  "otica",
  "otico",
  "otima",
  "otimo",
  "otins",
  "otita",
  "otite",
  "otona",
  "otoni",
  "otose",
  "ototo",
  "oucam",
  "oucao",
  "oucas",
  "ougai",
  "ougam",
  "ougar",
  "ougas",
  "ougou",
  "ougue",
  "ourai",
  "ouram",
  "ourar",
  "ouras",
  "ourei",
  "ourem",
  "oures",
  "ouros",
  "ourou",
  "ousai",
  "ousam",
  "ousar",
  "ousas",
  "ousei",
  "ousem",
  "ouses",
  "ousia",
  "ousio",
  "ousou",
  "outai",
  "outam",
  "outao",
  "outar",
  "outas",
  "outei",
  "outem",
  "outes",
  "outos",
  "outou",
  "outra",
  "outri",
  "outro",
  "ouvem",
  "ouves",
  "ouvia",
  "ouvir",
  "ouvis",
  "ouviu",
  "ovada",
  "ovado",
  "ovais",
  "ovala",
  "ovale",
  "ovalo",
  "ovana",
  "ovano",
  "ovara",
  "ovava",
  "oveis",
  "ovens",
  "oveos",
  "ovina",
  "ovino",
  "ovnis",
  "ovula",
  "ovule",
  "ovulo",
  "oxala",
  "oxeol",
  "oxida",
  "oxide",
  "oxido",
  "oxina",
  "oxura",
  "ozena",
  "ozona",
  "ozone",
  "paari",
  "pacai",
  "pacao",
  "pacas",
  "pacau",
  "pacho",
  "pacol",
  "pacos",
  "pacta",
  "pacte",
  "pacto",
  "pacus",
  "padar",
  "padas",
  "pades",
  "padeu",
  "padra",
  "padre",
  "padua",
  "padus",
  "paete",
  "pafio",
  "pafos",
  "pagai",
  "pagam",
  "pagao",
  "pagar",
  "pagas",
  "pagel",
  "pager",
  "pagos",
  "pagou",
  "pague",
  "paiao",
  "paias",
  "paica",
  "paido",
  "paina",
  "paiol",
  "paios",
  "paira",
  "paire",
  "pairo",
  "paivo",
  "paixa",
  "pajao",
  "pajea",
  "pajem",
  "pajes",
  "pajeu",
  "pajoa",
  "palao",
  "palar",
  "palas",
  "palco",
  "palea",
  "paleo",
  "paleu",
  "palha",
  "palia",
  "palie",
  "palio",
  "palis",
  "palma",
  "palme",
  "palmo",
  "palop",
  "palor",
  "palpa",
  "palpe",
  "palpo",
  "palra",
  "palre",
  "palro",
  "pamas",
  "pambo",
  "pamoa",
  "pampa",
  "pampo",
  "panai",
  "panal",
  "panam",
  "panao",
  "panar",
  "panas",
  "panca",
  "panco",
  "panda",
  "pando",
  "panei",
  "panem",
  "panes",
  "panga",
  "pango",
  "panha",
  "panja",
  "panos",
  "panou",
  "panri",
  "panro",
  "panto",
  "panus",
  "paolu",
  "papai",
  "papal",
  "papam",
  "papao",
  "papar",
  "papas",
  "papaz",
  "papei",
  "papel",
  "papem",
  "paper",
  "papes",
  "papia",
  "papie",
  "papio",
  "papoa",
  "papos",
  "papou",
  "papus",
  "paque",
  "paqui",
  "parai",
  "paral",
  "param",
  "parao",
  "parar",
  "paras",
  "parau",
  "parca",
  "parco",
  "parda",
  "parde",
  "pardo",
  "parea",
  "parei",
  "parem",
  "pareo",
  "pares",
  "parga",
  "pargo",
  "paria",
  "pario",
  "parir",
  "paris",
  "pariu",
  "parla",
  "parle",
  "parlo",
  "parma",
  "parna",
  "parne",
  "parol",
  "paros",
  "parou",
  "parra",
  "parre",
  "parro",
  "parse",
  "parsi",
  "parta",
  "parte",
  "parti",
  "parto",
  "parui",
  "parum",
  "parus",
  "parva",
  "parvi",
  "parvo",
  "parvu",
  "pasca",
  "pasce",
  "pasci",
  "pasco",
  "pasma",
  "pasme",
  "pasmo",
  "passa",
  "passe",
  "passo",
  "pasta",
  "paste",
  "pasto",
  "patai",
  "patas",
  "patau",
  "pataz",
  "patel",
  "pates",
  "patim",
  "patio",
  "patis",
  "patoa",
  "patos",
  "patri",
  "patua",
  "paual",
  "pauda",
  "pauis",
  "paula",
  "paulo",
  "pauna",
  "paura",
  "pausa",
  "pause",
  "pauso",
  "pauta",
  "paute",
  "pauto",
  "pavao",
  "paves",
  "pavia",
  "pavio",
  "pavoa",
  "pavor",
  "paxas",
  "pazao",
  "pazes",
  "pcdob",
  "peaca",
  "peado",
  "peais",
  "peala",
  "peale",
  "pealo",
  "peara",
  "peava",
  "pebas",
  "pecai",
  "pecam",
  "pecar",
  "pecas",
  "pecem",
  "pecha",
  "peche",
  "pecho",
  "pecos",
  "pecou",
  "pedal",
  "pedem",
  "pedes",
  "pedia",
  "pedir",
  "pedis",
  "pediu",
  "pedra",
  "peeis",
  "pegai",
  "pegam",
  "pegao",
  "pegar",
  "pegas",
  "pegos",
  "pegou",
  "pegue",
  "peiam",
  "peias",
  "peida",
  "peido",
  "peiem",
  "peies",
  "peina",
  "peita",
  "peite",
  "peito",
  "peitu",
  "peius",
  "peixe",
  "pejai",
  "pejam",
  "pejar",
  "pejas",
  "pejei",
  "pejem",
  "pejes",
  "pejis",
  "pejos",
  "pejou",
  "pelai",
  "pelam",
  "pelao",
  "pelar",
  "pelas",
  "pelei",
  "pelem",
  "peles",
  "pelio",
  "pelma",
  "pelor",
  "pelos",
  "pelou",
  "pelta",
  "pelve",
  "pemas",
  "pemba",
  "penai",
  "penal",
  "penam",
  "penao",
  "penar",
  "penas",
  "penca",
  "pence",
  "penda",
  "pende",
  "pendi",
  "pendo",
  "penei",
  "penem",
  "penes",
  "pengo",
  "penha",
  "penia",
  "penis",
  "penny",
  "penol",
  "penos",
  "penou",
  "pensa",
  "pense",
  "penso",
  "penta",
  "pente",
  "peoas",
  "peoes",
  "peona",
  "peota",
  "pepel",
  "pepia",
  "peplo",
  "peque",
  "pequi",
  "peral",
  "peras",
  "perau",
  "perca",
  "perce",
  "perco",
  "perda",
  "perde",
  "perdi",
  "perem",
  "peres",
  "peris",
  "perla",
  "perle",
  "perlo",
  "perna",
  "perne",
  "perno",
  "peroa",
  "perol",
  "peros",
  "perra",
  "perro",
  "persa",
  "perse",
  "perta",
  "perto",
  "perua",
  "perue",
  "perum",
  "peruo",
  "perus",
  "pesai",
  "pesam",
  "pesar",
  "pesas",
  "pesca",
  "pesco",
  "pesei",
  "pesem",
  "peses",
  "pesga",
  "pesgo",
  "pesos",
  "pesou",
  "peste",
  "pesto",
  "petai",
  "petam",
  "petao",
  "petar",
  "petas",
  "petei",
  "petem",
  "petes",
  "petia",
  "petim",
  "petiu",
  "petiz",
  "petos",
  "petou",
  "petra",
  "petum",
  "peuas",
  "peuco",
  "peuga",
  "peuva",
  "pevas",
  "pexao",
  "pezao",
  "pezes",
  "piaba",
  "piabe",
  "piabo",
  "piaca",
  "piada",
  "piado",
  "piafe",
  "piaga",
  "piaia",
  "piais",
  "piala",
  "piale",
  "pialo",
  "piana",
  "piano",
  "piara",
  "piaus",
  "piava",
  "picai",
  "pical",
  "picam",
  "picao",
  "picar",
  "picas",
  "picau",
  "picea",
  "piceo",
  "picha",
  "piche",
  "pichi",
  "picho",
  "picoa",
  "picos",
  "picou",
  "picta",
  "picto",
  "picua",
  "picue",
  "picui",
  "picuo",
  "pidao",
  "pidir",
  "piego",
  "pieis",
  "piela",
  "pielo",
  "piese",
  "pieza",
  "piezo",
  "pifai",
  "pifam",
  "pifao",
  "pifar",
  "pifas",
  "pifei",
  "pifem",
  "pifes",
  "pifia",
  "pifio",
  "pifou",
  "pifra",
  "pifre",
  "pigai",
  "pigal",
  "pigam",
  "pigar",
  "pigas",
  "pigma",
  "pigou",
  "pigro",
  "pigue",
  "piina",
  "piite",
  "pilai",
  "pilam",
  "pilao",
  "pilar",
  "pilas",
  "pilau",
  "pilda",
  "pilde",
  "pildo",
  "pilei",
  "pilem",
  "pileo",
  "piles",
  "pilha",
  "pilhe",
  "pilho",
  "piloa",
  "pilos",
  "pilou",
  "pilra",
  "pimba",
  "pimpa",
  "pimpe",
  "pimpo",
  "pinai",
  "pinam",
  "pinar",
  "pinas",
  "pinca",
  "pince",
  "pinco",
  "pinda",
  "pindi",
  "pinea",
  "pinei",
  "pinel",
  "pinem",
  "pineo",
  "pines",
  "pineu",
  "pinga",
  "pingo",
  "pinha",
  "pinho",
  "pinos",
  "pinou",
  "pinta",
  "pinte",
  "pinto",
  "pinup",
  "pinus",
  "pioes",
  "pioie",
  "piola",
  "piona",
  "pions",
  "piora",
  "piore",
  "pioro",
  "piose",
  "pipal",
  "pipas",
  "piper",
  "pipia",
  "pipie",
  "pipio",
  "pipis",
  "pipos",
  "pipra",
  "piqua",
  "pique",
  "piqui",
  "pirai",
  "piram",
  "pirao",
  "pirar",
  "piras",
  "pirau",
  "pirca",
  "pirei",
  "pirem",
  "pires",
  "pireu",
  "pirex",
  "pirgo",
  "piria",
  "piris",
  "piriz",
  "piroa",
  "pirou",
  "pirre",
  "pirua",
  "pirum",
  "pisai",
  "pisam",
  "pisao",
  "pisar",
  "pisas",
  "pisca",
  "pisco",
  "pisei",
  "pisem",
  "piseo",
  "pises",
  "pisga",
  "pisgo",
  "pisoa",
  "pisoe",
  "pisos",
  "pisou",
  "pista",
  "piste",
  "pisto",
  "pitai",
  "pitam",
  "pitao",
  "pitar",
  "pitas",
  "pitei",
  "pitem",
  "pites",
  "piteu",
  "pitia",
  "pitio",
  "pitis",
  "pitiu",
  "piton",
  "pitos",
  "pitou",
  "pitua",
  "pitui",
  "pitus",
  "piuba",
  "piuca",
  "piugo",
  "piuna",
  "piuns",
  "piuva",
  "pivoa",
  "pivos",
  "pixas",
  "pixel",
  "pixes",
  "pixua",
  "pizza",
  "placa",
  "place",
  "placo",
  "plaga",
  "plana",
  "plane",
  "plano",
  "plato",
  "plebe",
  "plena",
  "pleno",
  "plexo",
  "plica",
  "plico",
  "plida",
  "plide",
  "plido",
  "plomo",
  "plota",
  "plote",
  "ploto",
  "pluga",
  "plugo",
  "pluma",
  "plume",
  "plumo",
  "plush",
  "pluta",
  "pluto",
  "pneia",
  "pneus",
  "poacu",
  "poaia",
  "poais",
  "poare",
  "pobla",
  "pobra",
  "pobre",
  "pocai",
  "pocal",
  "pocam",
  "pocao",
  "pocar",
  "pocas",
  "pocha",
  "pocho",
  "pocos",
  "pocou",
  "podai",
  "podal",
  "podam",
  "podao",
  "podar",
  "podas",
  "podei",
  "podem",
  "poder",
  "podes",
  "podex",
  "podia",
  "podio",
  "podoa",
  "podoi",
  "podou",
  "podre",
  "poeis",
  "poeja",
  "poejo",
  "poema",
  "poera",
  "poeta",
  "poete",
  "poeto",
  "poiai",
  "poial",
  "poiam",
  "poiar",
  "poias",
  "poido",
  "poiei",
  "poiem",
  "poies",
  "poios",
  "poiou",
  "poisa",
  "poise",
  "poita",
  "pojai",
  "pojal",
  "pojam",
  "pojar",
  "pojas",
  "pojei",
  "pojem",
  "pojes",
  "pojos",
  "pojou",
  "polar",
  "polas",
  "polau",
  "polca",
  "polco",
  "polem",
  "polen",
  "poles",
  "polex",
  "polha",
  "polho",
  "polia",
  "polio",
  "polir",
  "polis",
  "poliu",
  "polma",
  "polme",
  "polmo",
  "polos",
  "polpa",
  "polpe",
  "polpo",
  "polua",
  "polui",
  "poluo",
  "polux",
  "polvo",
  "pomar",
  "pomas",
  "pomba",
  "pombe",
  "pombo",
  "pomes",
  "pomos",
  "pompa",
  "ponca",
  "ponce",
  "ponco",
  "ponde",
  "pondo",
  "ponei",
  "ponga",
  "pongo",
  "ponha",
  "ponho",
  "ponis",
  "ponje",
  "ponom",
  "ponta",
  "ponte",
  "ponto",
  "popas",
  "popes",
  "popia",
  "popos",
  "poque",
  "porao",
  "poras",
  "porca",
  "porco",
  "porei",
  "porem",
  "pores",
  "poria",
  "porio",
  "porma",
  "porno",
  "poros",
  "porra",
  "porre",
  "porro",
  "porta",
  "porte",
  "porto",
  "posai",
  "posam",
  "posar",
  "posas",
  "posca",
  "posei",
  "posem",
  "poser",
  "poses",
  "posou",
  "possa",
  "posse",
  "posso",
  "posta",
  "poste",
  "posto",
  "posts",
  "potes",
  "potoa",
  "potos",
  "potra",
  "potro",
  "pouca",
  "pouco",
  "poula",
  "poupa",
  "poupe",
  "poupo",
  "pousa",
  "pouse",
  "pouso",
  "pouta",
  "poute",
  "pouto",
  "povao",
  "povoa",
  "povoe",
  "povoo",
  "povos",
  "praca",
  "prado",
  "praga",
  "prago",
  "praia",
  "praja",
  "prama",
  "prana",
  "prase",
  "prata",
  "prato",
  "prava",
  "pravo",
  "praxe",
  "praza",
  "praze",
  "prazi",
  "prazo",
  "preai",
  "prear",
  "preas",
  "preca",
  "prece",
  "preco",
  "preda",
  "prede",
  "predo",
  "preei",
  "prega",
  "prego",
  "preia",
  "preie",
  "preio",
  "prelo",
  "prema",
  "preme",
  "premi",
  "premo",
  "preou",
  "presa",
  "prese",
  "preso",
  "preta",
  "preto",
  "preve",
  "previ",
  "preza",
  "preze",
  "prezo",
  "prima",
  "prime",
  "primo",
  "prino",
  "prion",
  "prior",
  "prise",
  "priul",
  "priva",
  "prive",
  "privo",
  "proai",
  "proam",
  "proar",
  "proas",
  "proba",
  "probo",
  "proca",
  "proco",
  "proei",
  "proem",
  "proes",
  "prois",
  "proiz",
  "prole",
  "prona",
  "prono",
  "proou",
  "prosa",
  "prose",
  "proso",
  "prota",
  "proto",
  "prova",
  "prove",
  "provi",
  "provo",
  "pruas",
  "pruca",
  "pruem",
  "pruia",
  "pruir",
  "pruis",
  "pruiu",
  "pruma",
  "prume",
  "prumo",
  "pruno",
  "pruns",
  "prura",
  "prure",
  "pruri",
  "pruro",
  "psico",
  "psiia",
  "psila",
  "psilo",
  "psius",
  "psoas",
  "psoco",
  "psora",
  "ptena",
  "ptose",
  "puaca",
  "puaco",
  "puacu",
  "puada",
  "puado",
  "puais",
  "puara",
  "puava",
  "pubai",
  "pubam",
  "pubar",
  "pubas",
  "pubei",
  "pubem",
  "pubes",
  "pubis",
  "pubos",
  "pubou",
  "pucas",
  "pucha",
  "pucos",
  "pucto",
  "puder",
  "pudim",
  "pudla",
  "pudle",
  "pudlo",
  "pudor",
  "pueis",
  "puela",
  "puera",
  "pufes",
  "pugil",
  "pugna",
  "pugne",
  "pugno",
  "puiam",
  "puias",
  "puida",
  "puido",
  "puira",
  "puita",
  "pujai",
  "pujam",
  "pujar",
  "pujas",
  "pujei",
  "pujem",
  "pujes",
  "pujou",
  "pulai",
  "pulam",
  "pulao",
  "pular",
  "pulas",
  "pulei",
  "pulem",
  "pules",
  "pulex",
  "pulga",
  "pulgo",
  "pulha",
  "pulos",
  "pulou",
  "pulpo",
  "pulsa",
  "pulse",
  "pulso",
  "pumas",
  "pumba",
  "punam",
  "punas",
  "punca",
  "punce",
  "punco",
  "punde",
  "punem",
  "punes",
  "punga",
  "punge",
  "pungi",
  "pungo",
  "punha",
  "punho",
  "punia",
  "punir",
  "puniu",
  "pural",
  "puras",
  "pures",
  "purga",
  "purgo",
  "puris",
  "puros",
  "purui",
  "puser",
  "puses",
  "putis",
  "putos",
  "puvia",
  "puvis",
  "puxai",
  "puxam",
  "puxao",
  "puxar",
  "puxas",
  "puxei",
  "puxem",
  "puxes",
  "puxos",
  "puxou",
  "quada",
  "quado",
  "quaga",
  "quais",
  "quale",
  "quara",
  "quare",
  "quark",
  "quaro",
  "quase",
  "quata",
  "quati",
  "queba",
  "quece",
  "queda",
  "quede",
  "quedo",
  "quela",
  "quele",
  "quelo",
  "quemi",
  "quena",
  "queno",
  "quepe",
  "quepi",
  "quera",
  "quere",
  "queri",
  "quero",
  "queto",
  "queza",
  "quiaz",
  "quiba",
  "quibe",
  "quica",
  "quice",
  "quico",
  "quija",
  "quile",
  "quili",
  "quilo",
  "quima",
  "quime",
  "quimo",
  "quina",
  "quine",
  "quino",
  "quipe",
  "quipo",
  "quira",
  "quiri",
  "quita",
  "quite",
  "quiti",
  "quito",
  "quiui",
  "quivi",
  "quixa",
  "quixo",
  "quiza",
  "quota",
  "quote",
  "quoto",
  "raais",
  "rabao",
  "rabaz",
  "rabdo",
  "rabel",
  "rabia",
  "rabie",
  "rabil",
  "rabio",
  "rabis",
  "rabos",
  "racao",
  "racas",
  "racha",
  "rache",
  "racho",
  "racio",
  "racks",
  "racoa",
  "racoe",
  "racoo",
  "racum",
  "radar",
  "radia",
  "radie",
  "radio",
  "raeis",
  "raera",
  "rafai",
  "rafam",
  "rafar",
  "rafas",
  "rafei",
  "rafem",
  "rafes",
  "rafia",
  "rafie",
  "rafio",
  "rafou",
  "ragia",
  "ragio",
  "ragus",
  "raiai",
  "raial",
  "raiam",
  "raiao",
  "raiar",
  "raias",
  "raido",
  "raiei",
  "raiem",
  "raies",
  "raiom",
  "raios",
  "raiou",
  "raiox",
  "raiva",
  "raive",
  "raivo",
  "rajai",
  "rajam",
  "rajao",
  "rajar",
  "rajas",
  "rajei",
  "rajem",
  "rajes",
  "rajos",
  "rajou",
  "ralai",
  "ralam",
  "ralao",
  "ralar",
  "ralas",
  "ralei",
  "ralem",
  "rales",
  "ralha",
  "ralhe",
  "ralho",
  "ralis",
  "ralos",
  "ralou",
  "ramai",
  "ramal",
  "ramas",
  "ramea",
  "rameo",
  "ramis",
  "ramna",
  "ramno",
  "ramos",
  "rampa",
  "ranas",
  "ranca",
  "rance",
  "ranco",
  "randa",
  "ranga",
  "range",
  "rangi",
  "rango",
  "ranha",
  "ranhe",
  "ranho",
  "ranis",
  "ranja",
  "ranjo",
  "ranus",
  "rapai",
  "rapam",
  "rapao",
  "rapar",
  "rapas",
  "rapaz",
  "rapei",
  "rapel",
  "rapem",
  "rapes",
  "rapou",
  "rapta",
  "rapte",
  "rapto",
  "raque",
  "rarai",
  "raram",
  "rarar",
  "raras",
  "rarea",
  "rarei",
  "rarem",
  "rares",
  "raros",
  "rarou",
  "rasai",
  "rasam",
  "rasao",
  "rasar",
  "rasas",
  "rasca",
  "rasco",
  "rasei",
  "rasem",
  "rases",
  "rasga",
  "rasgo",
  "rasos",
  "rasou",
  "raspa",
  "raspe",
  "raspo",
  "rassa",
  "rasta",
  "raste",
  "rasto",
  "ratai",
  "ratam",
  "ratao",
  "ratar",
  "ratas",
  "ratea",
  "ratei",
  "ratel",
  "ratem",
  "rates",
  "ratim",
  "ratio",
  "ratoa",
  "ratos",
  "ratou",
  "raxas",
  "razao",
  "razia",
  "razie",
  "razio",
  "razoa",
  "razoe",
  "razoo",
  "reaca",
  "reage",
  "reagi",
  "reais",
  "reaja",
  "reajo",
  "reame",
  "reata",
  "reate",
  "reato",
  "reava",
  "reave",
  "reavi",
  "reavo",
  "rebai",
  "rebam",
  "rebar",
  "rebas",
  "rebei",
  "rebel",
  "rebem",
  "rebes",
  "reboa",
  "reboe",
  "reboo",
  "rebos",
  "rebou",
  "rebus",
  "recai",
  "recea",
  "recem",
  "recha",
  "recio",
  "recos",
  "recru",
  "recta",
  "recto",
  "recua",
  "recue",
  "recuo",
  "redai",
  "redam",
  "redao",
  "redar",
  "redas",
  "redea",
  "redei",
  "redem",
  "reder",
  "redes",
  "redia",
  "redil",
  "redis",
  "redor",
  "redou",
  "redox",
  "redra",
  "redre",
  "redro",
  "reduz",
  "refaz",
  "refem",
  "refez",
  "refia",
  "refie",
  "refil",
  "refio",
  "refis",
  "refiz",
  "refla",
  "refle",
  "reflo",
  "refra",
  "regai",
  "regal",
  "regam",
  "regar",
  "regas",
  "regei",
  "regem",
  "reger",
  "reges",
  "regeu",
  "regia",
  "regio",
  "regma",
  "regno",
  "regoa",
  "regoe",
  "regoo",
  "regos",
  "regou",
  "regra",
  "regre",
  "regro",
  "regua",
  "regue",
  "reich",
  "reida",
  "reide",
  "reima",
  "reina",
  "reine",
  "reino",
  "reira",
  "reixa",
  "rejam",
  "rejas",
  "relai",
  "relam",
  "relar",
  "relas",
  "relax",
  "relei",
  "relem",
  "reler",
  "reles",
  "releu",
  "relfa",
  "relha",
  "relhe",
  "relho",
  "relia",
  "relou",
  "reluz",
  "relva",
  "relve",
  "relvo",
  "remai",
  "remam",
  "remar",
  "remas",
  "remau",
  "remei",
  "remel",
  "remem",
  "remes",
  "remia",
  "remir",
  "remis",
  "remiu",
  "remoa",
  "remoe",
  "remoi",
  "remoo",
  "remos",
  "remou",
  "renal",
  "renao",
  "renas",
  "renda",
  "rende",
  "rendi",
  "rendo",
  "renga",
  "renge",
  "rengo",
  "renhi",
  "renia",
  "renio",
  "renta",
  "rente",
  "rento",
  "renua",
  "renui",
  "renuo",
  "repas",
  "repes",
  "repoe",
  "repor",
  "repos",
  "repta",
  "repte",
  "repto",
  "repus",
  "reram",
  "rerao",
  "reras",
  "rerei",
  "rerem",
  "reres",
  "reria",
  "reses",
  "resga",
  "resgo",
  "resma",
  "resos",
  "respe",
  "respo",
  "ressa",
  "resse",
  "resta",
  "reste",
  "resto",
  "retai",
  "retal",
  "retam",
  "retar",
  "retas",
  "retei",
  "retem",
  "reter",
  "retes",
  "retor",
  "retos",
  "retou",
  "retre",
  "retro",
  "reuma",
  "reuna",
  "reune",
  "reuni",
  "reuno",
  "reuso",
  "revel",
  "revem",
  "rever",
  "reves",
  "revia",
  "revim",
  "revir",
  "reviu",
  "revoa",
  "revoe",
  "revoo",
  "rexia",
  "rexio",
  "rezai",
  "rezam",
  "rezao",
  "rezar",
  "rezas",
  "rezei",
  "rezem",
  "rezes",
  "rezou",
  "riais",
  "riana",
  "ribai",
  "ribam",
  "ribar",
  "ribas",
  "ribei",
  "ribem",
  "ribes",
  "ribou",
  "ricai",
  "ricam",
  "ricar",
  "ricas",
  "ricei",
  "ricem",
  "rices",
  "ricio",
  "ricos",
  "ricou",
  "ricto",
  "rides",
  "ridor",
  "rieis",
  "rieti",
  "rifai",
  "rifam",
  "rifao",
  "rifar",
  "rifas",
  "rifei",
  "rifem",
  "rifes",
  "rifla",
  "rifle",
  "riflo",
  "rifou",
  "rigel",
  "rigol",
  "rigor",
  "rijai",
  "rijal",
  "rijam",
  "rijao",
  "rijar",
  "rijas",
  "rijei",
  "rijem",
  "rijes",
  "rijos",
  "rijou",
  "rilha",
  "rilhe",
  "rilho",
  "rimai",
  "rimam",
  "rimar",
  "rimas",
  "rimei",
  "rimel",
  "rimem",
  "rimes",
  "rimos",
  "rimou",
  "rinal",
  "rinca",
  "rince",
  "rinco",
  "rindo",
  "ringe",
  "ringi",
  "rinha",
  "rinhe",
  "rinho",
  "rinja",
  "rinjo",
  "rinto",
  "ripai",
  "ripal",
  "ripam",
  "ripar",
  "ripas",
  "ripei",
  "ripem",
  "ripes",
  "ripia",
  "ripio",
  "ripou",
  "rique",
  "riram",
  "rirao",
  "riras",
  "rirei",
  "rirem",
  "rires",
  "riria",
  "risca",
  "risco",
  "risos",
  "risse",
  "risso",
  "riste",
  "ritao",
  "ritio",
  "ritma",
  "ritme",
  "ritmo",
  "ritos",
  "riuta",
  "rival",
  "rixai",
  "rixam",
  "rixar",
  "rixas",
  "rixei",
  "rixem",
  "rixes",
  "rixou",
  "rizai",
  "rizam",
  "rizar",
  "rizas",
  "rizei",
  "rizem",
  "rizes",
  "rizos",
  "rizou",
  "roais",
  "robaz",
  "rober",
  "robes",
  "roble",
  "robos",
  "robur",
  "rocai",
  "rocal",
  "rocam",
  "rocao",
  "rocar",
  "rocas",
  "rocaz",
  "rocei",
  "rocem",
  "roces",
  "rocha",
  "rocia",
  "rocie",
  "rocim",
  "rocio",
  "roclo",
  "rocos",
  "rocou",
  "rodai",
  "rodal",
  "rodam",
  "rodar",
  "rodas",
  "rodea",
  "rodei",
  "rodem",
  "rodes",
  "rodim",
  "rodio",
  "rodos",
  "rodou",
  "roeis",
  "roela",
  "roera",
  "rofos",
  "rogai",
  "rogal",
  "rogam",
  "rogar",
  "rogas",
  "rogos",
  "rogou",
  "rogue",
  "roiam",
  "roias",
  "roido",
  "roixo",
  "rojai",
  "rojam",
  "rojao",
  "rojar",
  "rojas",
  "rojei",
  "rojem",
  "rojes",
  "rojos",
  "rojou",
  "rolai",
  "rolam",
  "rolao",
  "rolar",
  "rolas",
  "rolda",
  "rolde",
  "roldo",
  "rolei",
  "rolem",
  "roles",
  "rolha",
  "rolhe",
  "rolho",
  "rolim",
  "roloa",
  "rolos",
  "rolou",
  "romao",
  "romas",
  "romba",
  "rombo",
  "romeu",
  "rompa",
  "rompe",
  "rompi",
  "rompo",
  "ronca",
  "ronco",
  "ronda",
  "ronde",
  "rondo",
  "ronga",
  "rongo",
  "ronha",
  "ronto",
  "ropia",
  "roque",
  "rorai",
  "roram",
  "rorar",
  "roras",
  "rorei",
  "rorem",
  "rores",
  "rorou",
  "rosai",
  "rosal",
  "rosam",
  "rosao",
  "rosar",
  "rosas",
  "rosca",
  "rosco",
  "rosea",
  "rosei",
  "rosem",
  "roseo",
  "roses",
  "rosna",
  "rosne",
  "rosno",
  "rosou",
  "rosta",
  "roste",
  "rosti",
  "rosto",
  "rotai",
  "rotam",
  "rotar",
  "rotas",
  "rotea",
  "rotei",
  "rotem",
  "rotes",
  "rotia",
  "rotim",
  "rotor",
  "rotos",
  "rotou",
  "rouba",
  "roube",
  "roubo",
  "rouca",
  "rouce",
  "rouco",
  "round",
  "roupa",
  "roupe",
  "roupo",
  "roxas",
  "roxos",
  "ruaca",
  "ruado",
  "ruais",
  "ruana",
  "ruano",
  "ruaos",
  "ruara",
  "ruava",
  "rubea",
  "rubeo",
  "rubia",
  "rubim",
  "rublo",
  "rubor",
  "rubos",
  "rubra",
  "rubro",
  "rucai",
  "rucam",
  "rucar",
  "rucas",
  "rucei",
  "rucem",
  "ruces",
  "rucos",
  "rucou",
  "rudes",
  "rudez",
  "rudos",
  "rueis",
  "ruela",
  "rufai",
  "rufam",
  "rufar",
  "rufas",
  "rufei",
  "rufem",
  "rufes",
  "rufia",
  "rufie",
  "rufio",
  "rufla",
  "ruflo",
  "rufos",
  "rufou",
  "rugai",
  "rugam",
  "rugar",
  "rugas",
  "rugbi",
  "rugby",
  "rugem",
  "ruges",
  "rugia",
  "rugio",
  "rugir",
  "rugis",
  "rugiu",
  "rugou",
  "rugue",
  "ruiam",
  "ruias",
  "ruida",
  "ruide",
  "ruido",
  "ruina",
  "ruine",
  "ruino",
  "ruins",
  "ruira",
  "ruiva",
  "ruivo",
  "rujam",
  "rujas",
  "rular",
  "rules",
  "rulos",
  "rumai",
  "rumam",
  "rumar",
  "rumas",
  "rumba",
  "rumei",
  "rumem",
  "rumen",
  "rumes",
  "rumia",
  "rumie",
  "rumio",
  "rumis",
  "rumor",
  "rumos",
  "rumou",
  "rumpi",
  "runas",
  "rundo",
  "runha",
  "ruoes",
  "ruona",
  "rupai",
  "rupam",
  "rupar",
  "rupas",
  "rupei",
  "rupem",
  "rupes",
  "rupia",
  "rupim",
  "rupou",
  "rupul",
  "rural",
  "rusco",
  "rusga",
  "rusgo",
  "rusma",
  "russa",
  "russe",
  "russo",
  "rusta",
  "ruste",
  "rusti",
  "rusto",
  "rutas",
  "rutim",
  "saara",
  "sabal",
  "sabao",
  "sabas",
  "sabei",
  "sabem",
  "saber",
  "sabes",
  "sabeu",
  "sabia",
  "sabin",
  "sabio",
  "sabir",
  "sabiu",
  "sable",
  "saboa",
  "sabor",
  "sabra",
  "sabre",
  "sacai",
  "sacal",
  "sacam",
  "sacao",
  "sacar",
  "sacas",
  "sacha",
  "sache",
  "sacho",
  "sacia",
  "sacie",
  "sacio",
  "sacis",
  "sacos",
  "sacou",
  "sacra",
  "sacre",
  "sacri",
  "sacro",
  "sacta",
  "sacue",
  "sadao",
  "sadia",
  "sadio",
  "sadra",
  "safai",
  "safam",
  "safao",
  "safar",
  "safas",
  "safei",
  "safem",
  "safeo",
  "safes",
  "safia",
  "safio",
  "safos",
  "safou",
  "safra",
  "safre",
  "safro",
  "safus",
  "sagao",
  "sagas",
  "sagaz",
  "sages",
  "sagez",
  "sagio",
  "sagra",
  "sagre",
  "sagro",
  "sagua",
  "sagui",
  "sagum",
  "sagus",
  "saial",
  "saiam",
  "saiao",
  "saias",
  "saiba",
  "saibo",
  "saico",
  "saida",
  "saide",
  "saido",
  "saiga",
  "saije",
  "saina",
  "saine",
  "saino",
  "saios",
  "saipe",
  "saira",
  "saire",
  "sairi",
  "saita",
  "saiva",
  "saixe",
  "salao",
  "salas",
  "salaz",
  "salda",
  "salde",
  "saldo",
  "salem",
  "salga",
  "salgo",
  "salha",
  "salhe",
  "salho",
  "salia",
  "salio",
  "salix",
  "salmo",
  "saloa",
  "salol",
  "salpa",
  "salsa",
  "salso",
  "salta",
  "salte",
  "salto",
  "salus",
  "salva",
  "salve",
  "salvo",
  "samao",
  "samba",
  "sambe",
  "sambo",
  "samio",
  "samos",
  "sampa",
  "sampe",
  "sampo",
  "samur",
  "sanai",
  "sanam",
  "sanar",
  "sanas",
  "sanca",
  "sanco",
  "sanda",
  "sande",
  "sandi",
  "sando",
  "sanea",
  "sanei",
  "sanem",
  "sanes",
  "sanga",
  "sanha",
  "sanie",
  "sanja",
  "sanje",
  "sanjo",
  "sanou",
  "santa",
  "santo",
  "sanvo",
  "sapai",
  "sapal",
  "sapam",
  "sapao",
  "sapar",
  "sapas",
  "sapei",
  "sapem",
  "sapes",
  "sapia",
  "sapos",
  "sapou",
  "sapro",
  "sapus",
  "saque",
  "saqui",
  "saram",
  "saran",
  "sarao",
  "sarar",
  "saras",
  "sarau",
  "sarca",
  "sarco",
  "sarda",
  "sardo",
  "sarei",
  "sarem",
  "sares",
  "sarga",
  "sargo",
  "saria",
  "saris",
  "sarja",
  "sarje",
  "sarjo",
  "sarna",
  "sarne",
  "sarni",
  "sarno",
  "saroe",
  "saros",
  "sarou",
  "sarpa",
  "sarpe",
  "sarpo",
  "sarra",
  "sarre",
  "sarro",
  "sarta",
  "sarua",
  "sarue",
  "sarus",
  "sassa",
  "sasse",
  "sasso",
  "sataa",
  "satao",
  "satas",
  "sauas",
  "sauba",
  "sauco",
  "sauda",
  "saude",
  "saudo",
  "sauga",
  "sauia",
  "sauis",
  "sauna",
  "sauni",
  "saupe",
  "saute",
  "sauva",
  "saval",
  "savel",
  "savia",
  "saxao",
  "saxea",
  "saxeo",
  "sazao",
  "sazoa",
  "sazoo",
  "scopo",
  "scoto",
  "seada",
  "seara",
  "sebel",
  "sebes",
  "sebos",
  "secai",
  "secam",
  "secao",
  "secar",
  "secas",
  "secia",
  "secie",
  "secio",
  "secos",
  "secou",
  "secta",
  "sedai",
  "sedal",
  "sedam",
  "sedao",
  "sedar",
  "sedas",
  "sedei",
  "sedem",
  "seder",
  "sedes",
  "sedia",
  "sedie",
  "sedio",
  "sedou",
  "seduz",
  "seeda",
  "sefia",
  "segai",
  "segam",
  "segao",
  "segar",
  "segas",
  "seges",
  "segou",
  "segre",
  "segue",
  "segui",
  "seibo",
  "seica",
  "seice",
  "seide",
  "seima",
  "seios",
  "seira",
  "seita",
  "seiva",
  "seive",
  "seivo",
  "seixa",
  "seixo",
  "sejam",
  "sejas",
  "selai",
  "selam",
  "selar",
  "selas",
  "selei",
  "selem",
  "seles",
  "selha",
  "selho",
  "selim",
  "selio",
  "selos",
  "selou",
  "selva",
  "semea",
  "semel",
  "semen",
  "semis",
  "senal",
  "senao",
  "senas",
  "senda",
  "sendo",
  "senes",
  "senga",
  "sengo",
  "senha",
  "senho",
  "senil",
  "senio",
  "senis",
  "senje",
  "senos",
  "senra",
  "senso",
  "senta",
  "sente",
  "senti",
  "sento",
  "sepia",
  "sepse",
  "septa",
  "septo",
  "seque",
  "sequi",
  "seral",
  "serao",
  "seras",
  "serei",
  "serem",
  "seres",
  "serfo",
  "seria",
  "serie",
  "serio",
  "seris",
  "seriu",
  "serja",
  "serna",
  "seroa",
  "seroe",
  "seroo",
  "serpa",
  "serpe",
  "serra",
  "serre",
  "serro",
  "serta",
  "serum",
  "serva",
  "serve",
  "servi",
  "servo",
  "sesea",
  "sesga",
  "sesgo",
  "sesia",
  "sesma",
  "sesme",
  "sesmo",
  "sessa",
  "sesse",
  "sesso",
  "sesta",
  "setas",
  "seter",
  "setes",
  "setia",
  "setim",
  "setor",
  "setos",
  "sevai",
  "sevam",
  "sevar",
  "sevas",
  "sevei",
  "sevem",
  "seves",
  "sevos",
  "sevou",
  "sexos",
  "sexta",
  "sexte",
  "sexto",
  "sezao",
  "sheik",
  "short",
  "shunt",
  "siado",
  "siais",
  "sialo",
  "siame",
  "siara",
  "siava",
  "sibar",
  "sibas",
  "sicas",
  "sicia",
  "siclo",
  "sicon",
  "sicos",
  "sidra",
  "sieis",
  "siena",
  "sifao",
  "sifla",
  "sifle",
  "siflo",
  "sigam",
  "sigas",
  "sigla",
  "sigle",
  "siglo",
  "sigma",
  "signa",
  "signe",
  "signo",
  "sigro",
  "siler",
  "silex",
  "silfa",
  "silfo",
  "silha",
  "silho",
  "silos",
  "silte",
  "silva",
  "silve",
  "silvo",
  "simao",
  "simas",
  "simel",
  "simia",
  "simil",
  "simio",
  "simoa",
  "simum",
  "sinai",
  "sinal",
  "sinam",
  "sinar",
  "sinas",
  "sindi",
  "sindo",
  "sinei",
  "sinem",
  "sines",
  "singa",
  "sinha",
  "sinho",
  "sinis",
  "sinoa",
  "sinos",
  "sinou",
  "sinta",
  "sinto",
  "sinua",
  "sinue",
  "sinuo",
  "sinus",
  "sinxo",
  "sioba",
  "siote",
  "sipai",
  "sipes",
  "sipia",
  "sique",
  "sires",
  "sirfo",
  "sirga",
  "sirgo",
  "siria",
  "sirio",
  "siris",
  "siriu",
  "sirma",
  "siros",
  "sirva",
  "sirvo",
  "sisai",
  "sisal",
  "sisam",
  "sisao",
  "sisar",
  "sisas",
  "sisei",
  "sisem",
  "sises",
  "sisgo",
  "sismo",
  "sisor",
  "sisos",
  "sisou",
  "sisso",
  "sitar",
  "sitia",
  "sitie",
  "sitio",
  "situa",
  "situe",
  "situo",
  "siuba",
  "siusi",
  "sivom",
  "sizau",
  "skate",
  "slide",
  "soada",
  "soado",
  "soais",
  "soaje",
  "soala",
  "soamo",
  "soara",
  "soava",
  "sobas",
  "sobem",
  "sobes",
  "sobeu",
  "sobia",
  "sobie",
  "sobio",
  "sobpe",
  "sobra",
  "sobre",
  "sobro",
  "socai",
  "socam",
  "socao",
  "socar",
  "socas",
  "socha",
  "socia",
  "socie",
  "socio",
  "socla",
  "socoa",
  "socos",
  "socou",
  "sodai",
  "sodam",
  "sodar",
  "sodas",
  "sodei",
  "sodem",
  "sodes",
  "sodio",
  "sodou",
  "sodra",
  "soeis",
  "soemo",
  "soera",
  "sofas",
  "sofia",
  "sofio",
  "sofra",
  "sofre",
  "sofri",
  "sofro",
  "softa",
  "sogai",
  "sogam",
  "sogar",
  "sogas",
  "sogou",
  "sogra",
  "sogre",
  "sogro",
  "sogue",
  "soiam",
  "soias",
  "soida",
  "soido",
  "soila",
  "soins",
  "sojas",
  "solai",
  "solam",
  "solao",
  "solar",
  "solas",
  "solau",
  "solaz",
  "solda",
  "solde",
  "soldo",
  "solea",
  "solei",
  "solem",
  "soles",
  "solfa",
  "solfe",
  "solfo",
  "solha",
  "solhe",
  "solho",
  "solia",
  "solio",
  "soloa",
  "solos",
  "solou",
  "solta",
  "solte",
  "solto",
  "solva",
  "solve",
  "solvi",
  "solvo",
  "somai",
  "somam",
  "somar",
  "somas",
  "somei",
  "somem",
  "somes",
  "somie",
  "somos",
  "somou",
  "sonai",
  "sonal",
  "sonam",
  "sonar",
  "sonas",
  "sonda",
  "sonde",
  "sondo",
  "sonei",
  "sonem",
  "sones",
  "songa",
  "sonha",
  "sonhe",
  "sonho",
  "sonia",
  "sonos",
  "sonou",
  "sonsa",
  "sonso",
  "sonto",
  "sooes",
  "soona",
  "sopao",
  "sopas",
  "sopes",
  "sopia",
  "sopie",
  "sopio",
  "soplo",
  "sopor",
  "sopra",
  "sopre",
  "sopro",
  "soqua",
  "soque",
  "soqui",
  "soquo",
  "sorai",
  "soral",
  "soram",
  "sorar",
  "soras",
  "sorca",
  "sorda",
  "sordo",
  "sorei",
  "sorem",
  "sores",
  "sorex",
  "sorga",
  "sorgo",
  "soria",
  "sorna",
  "sorne",
  "sorno",
  "soror",
  "soros",
  "sorou",
  "sorri",
  "sorta",
  "sorte",
  "sorti",
  "sorva",
  "sorve",
  "sorvi",
  "sorvo",
  "sosia",
  "sosso",
  "sotaa",
  "sotao",
  "sotas",
  "sotia",
  "sotil",
  "sotos",
  "souas",
  "soube",
  "sousa",
  "souta",
  "soute",
  "souto",
  "souza",
  "sovai",
  "sovam",
  "sovar",
  "sovas",
  "sovei",
  "sovem",
  "soves",
  "soveu",
  "sovis",
  "sovou",
  "sozal",
  "spots",
  "spray",
  "sprue",
  "staff",
  "stand",
  "steno",
  "stoma",
  "suacu",
  "suada",
  "suade",
  "suadi",
  "suado",
  "suais",
  "suara",
  "suari",
  "suava",
  "suave",
  "subam",
  "subas",
  "suber",
  "subia",
  "subir",
  "subis",
  "subiu",
  "sucai",
  "sucam",
  "sucao",
  "sucar",
  "sucas",
  "sucho",
  "sucia",
  "sucie",
  "sucio",
  "sucos",
  "sucou",
  "sucto",
  "sudao",
  "sudra",
  "sudre",
  "sudro",
  "sueca",
  "sueco",
  "sueda",
  "suede",
  "sueis",
  "sueta",
  "sueto",
  "sueva",
  "sufis",
  "sufle",
  "sugai",
  "sugam",
  "sugar",
  "sugas",
  "sugou",
  "sugue",
  "suias",
  "suica",
  "suico",
  "suina",
  "suino",
  "suite",
  "sujai",
  "sujam",
  "sujar",
  "sujas",
  "sujei",
  "sujem",
  "sujes",
  "sujos",
  "sujou",
  "sulai",
  "sulam",
  "sular",
  "sulas",
  "sulca",
  "sulco",
  "sulei",
  "sulem",
  "sules",
  "sulfa",
  "sulia",
  "suloa",
  "sulou",
  "sulta",
  "sumam",
  "sumas",
  "sumbo",
  "sumes",
  "sumia",
  "sumir",
  "sumis",
  "sumiu",
  "sumos",
  "sunas",
  "sundo",
  "sunes",
  "sunga",
  "sungo",
  "sunto",
  "suoes",
  "suomi",
  "suona",
  "super",
  "supoe",
  "supor",
  "supos",
  "supra",
  "supre",
  "supri",
  "supro",
  "supus",
  "suqua",
  "suque",
  "suqui",
  "suquo",
  "sural",
  "suras",
  "surca",
  "surco",
  "surda",
  "surde",
  "surdi",
  "surdo",
  "surfa",
  "surfe",
  "surfo",
  "surge",
  "surgi",
  "surim",
  "surio",
  "suris",
  "surja",
  "surjo",
  "suros",
  "surra",
  "surre",
  "surro",
  "surta",
  "surte",
  "surti",
  "surto",
  "surus",
  "susao",
  "sushi",
  "sussa",
  "susta",
  "suste",
  "susto",
  "sutam",
  "sutar",
  "sutas",
  "sutei",
  "sutem",
  "sutes",
  "sutia",
  "sutil",
  "sutis",
  "sutou",
  "sutra",
  "suxai",
  "suxam",
  "suxar",
  "suxas",
  "suxei",
  "suxem",
  "suxes",
  "suxos",
  "suxou",
  "swell",
  "swing",
  "tabao",
  "tabas",
  "tabes",
  "tabis",
  "tabla",
  "taboa",
  "tabos",
  "tabua",
  "tabus",
  "tacai",
  "tacam",
  "tacao",
  "tacar",
  "tacas",
  "tacha",
  "tache",
  "tacho",
  "tacia",
  "tacis",
  "tacoa",
  "tacos",
  "tacou",
  "tacta",
  "tacto",
  "tacua",
  "tacus",
  "taeis",
  "tafia",
  "tafio",
  "taful",
  "tagal",
  "tagaz",
  "tagma",
  "tagna",
  "tagra",
  "tagua",
  "taias",
  "taibo",
  "taibu",
  "taico",
  "taifa",
  "taifo",
  "taiga",
  "taina",
  "taino",
  "taipa",
  "taipe",
  "taipo",
  "taira",
  "taita",
  "taixi",
  "tajal",
  "tajas",
  "talai",
  "talam",
  "talao",
  "talar",
  "talas",
  "talco",
  "talei",
  "talem",
  "taler",
  "tales",
  "talha",
  "talhe",
  "talho",
  "talia",
  "talim",
  "talio",
  "talma",
  "talol",
  "talos",
  "talou",
  "talus",
  "tamao",
  "tamba",
  "tambi",
  "tambo",
  "tambu",
  "tamem",
  "tamia",
  "tamil",
  "tamis",
  "tamom",
  "tamos",
  "tampa",
  "tampe",
  "tampo",
  "tamul",
  "tamuz",
  "tanai",
  "tanam",
  "tanar",
  "tanas",
  "tanau",
  "tanca",
  "tanei",
  "tanem",
  "tanes",
  "tanga",
  "tange",
  "tangi",
  "tango",
  "tanha",
  "tanho",
  "tanis",
  "tanja",
  "tanjo",
  "tanoa",
  "tanoe",
  "tanos",
  "tanou",
  "tansa",
  "tanso",
  "tanta",
  "tanto",
  "taoca",
  "taoso",
  "tapai",
  "tapam",
  "tapar",
  "tapas",
  "tapea",
  "tapei",
  "tapem",
  "tapes",
  "tapia",
  "tapir",
  "tapiu",
  "tapou",
  "tapua",
  "tapui",
  "taque",
  "taqui",
  "tarai",
  "taram",
  "tarar",
  "taras",
  "tarau",
  "tarba",
  "tarca",
  "tarda",
  "tarde",
  "tardo",
  "tarei",
  "tarem",
  "tares",
  "taris",
  "tarja",
  "tarje",
  "tarjo",
  "taroa",
  "tarol",
  "taros",
  "tarou",
  "tarpa",
  "tarre",
  "tarro",
  "tarsa",
  "tarso",
  "tarte",
  "tasca",
  "tasco",
  "tasio",
  "tasna",
  "tasto",
  "tatas",
  "tatea",
  "tatil",
  "tatos",
  "tatua",
  "tatue",
  "tatui",
  "tatuo",
  "tatus",
  "tatze",
  "tauas",
  "tauba",
  "taura",
  "tauro",
  "tauto",
  "tavao",
  "tavas",
  "tavoa",
  "tavua",
  "taxai",
  "taxam",
  "taxar",
  "taxas",
  "taxei",
  "taxem",
  "taxes",
  "taxia",
  "taxis",
  "taxos",
  "taxou",
  "tchas",
  "tchau",
  "teada",
  "teame",
  "teara",
  "tebas",
  "tebeu",
  "tecai",
  "tecal",
  "tecam",
  "tecar",
  "tecas",
  "tecei",
  "tecem",
  "tecer",
  "teces",
  "teceu",
  "tecia",
  "tecla",
  "tecle",
  "teclo",
  "tecno",
  "tecoa",
  "tecos",
  "tecou",
  "tecto",
  "tecum",
  "tedas",
  "tedeu",
  "tedia",
  "tedio",
  "tegao",
  "tegme",
  "tegui",
  "teias",
  "teiga",
  "teigo",
  "teima",
  "teime",
  "teimo",
  "teina",
  "teipa",
  "teipe",
  "teira",
  "teiro",
  "teite",
  "teito",
  "teiua",
  "teius",
  "teixe",
  "teixo",
  "tejia",
  "tejos",
  "tejus",
  "telai",
  "telam",
  "telao",
  "telar",
  "telas",
  "telei",
  "telem",
  "teles",
  "telex",
  "telha",
  "telhe",
  "telho",
  "telim",
  "telio",
  "teliz",
  "telou",
  "telso",
  "temam",
  "temao",
  "temas",
  "temba",
  "tembe",
  "temei",
  "temem",
  "temer",
  "temes",
  "temeu",
  "temia",
  "temio",
  "temne",
  "temor",
  "temos",
  "tempa",
  "tempe",
  "tempo",
  "tenar",
  "tenaz",
  "tenca",
  "tenda",
  "tende",
  "tendi",
  "tendo",
  "tengo",
  "tenha",
  "tenho",
  "tenia",
  "tenis",
  "tenor",
  "tenra",
  "tenro",
  "tensa",
  "tense",
  "tenso",
  "tenta",
  "tente",
  "tento",
  "tenue",
  "teoro",
  "teose",
  "tepes",
  "tepor",
  "teque",
  "terao",
  "teras",
  "terca",
  "terce",
  "terco",
  "terei",
  "terem",
  "teres",
  "tergo",
  "teria",
  "terio",
  "terma",
  "terme",
  "termo",
  "terna",
  "terne",
  "terno",
  "terol",
  "terra",
  "terre",
  "terro",
  "terso",
  "tesai",
  "tesam",
  "tesao",
  "tesar",
  "tesas",
  "tesbi",
  "tesei",
  "tesem",
  "teses",
  "tesio",
  "tesis",
  "tesla",
  "tesos",
  "tesou",
  "tesse",
  "testa",
  "teste",
  "testo",
  "tesum",
  "tetai",
  "tetam",
  "tetar",
  "tetas",
  "tetei",
  "tetem",
  "tetes",
  "teteu",
  "tetim",
  "tetio",
  "tetis",
  "tetos",
  "tetou",
  "tetra",
  "tetro",
  "tetum",
  "teuba",
  "teuda",
  "teudo",
  "teuta",
  "teuto",
  "teves",
  "texto",
  "tezes",
  "tiade",
  "tiago",
  "tiara",
  "tibai",
  "tibam",
  "tibar",
  "tibas",
  "tibei",
  "tibel",
  "tibem",
  "tibes",
  "tibia",
  "tibio",
  "tibis",
  "tibou",
  "ticai",
  "tical",
  "ticam",
  "ticao",
  "ticar",
  "ticas",
  "ticio",
  "ticos",
  "ticou",
  "ticum",
  "tiete",
  "tifas",
  "tifia",
  "tiflo",
  "tifoa",
  "tifos",
  "tigre",
  "tiito",
  "tijia",
  "tijus",
  "tilai",
  "tilam",
  "tilar",
  "tilas",
  "tilda",
  "tilde",
  "tildo",
  "tilea",
  "tilei",
  "tilem",
  "tiles",
  "tilha",
  "tilho",
  "tilia",
  "tilim",
  "tilos",
  "tilou",
  "tilts",
  "timao",
  "timba",
  "timbe",
  "timbo",
  "timbu",
  "timer",
  "times",
  "timia",
  "timio",
  "timol",
  "timor",
  "timos",
  "tinas",
  "tinca",
  "tinea",
  "tinem",
  "tines",
  "tinga",
  "tinge",
  "tingi",
  "tinha",
  "tinho",
  "tinia",
  "tinir",
  "tinis",
  "tiniu",
  "tinja",
  "tinjo",
  "tinor",
  "tinta",
  "tinte",
  "tinto",
  "tioca",
  "tioco",
  "tiote",
  "tipao",
  "tipas",
  "tipio",
  "tipis",
  "tiple",
  "tipoa",
  "tipoi",
  "tipos",
  "tipre",
  "tipus",
  "tique",
  "tiqui",
  "tirai",
  "tiram",
  "tirao",
  "tirar",
  "tiras",
  "tiraz",
  "tirei",
  "tirem",
  "tireo",
  "tires",
  "tiria",
  "tirio",
  "tiris",
  "tiros",
  "tirou",
  "tirso",
  "tirte",
  "tisca",
  "tisco",
  "tisio",
  "tisna",
  "tisne",
  "tisno",
  "tisso",
  "titaa",
  "titao",
  "titas",
  "titia",
  "titim",
  "titio",
  "titor",
  "titue",
  "tiuba",
  "tiupa",
  "tiuva",
  "tiver",
  "tizia",
  "tlaco",
  "tlena",
  "tlins",
  "tmema",
  "tmese",
  "toaca",
  "toada",
  "toado",
  "toais",
  "toara",
  "toava",
  "tobas",
  "toboa",
  "tobos",
  "tocai",
  "tocam",
  "tocar",
  "tocas",
  "tocha",
  "tocho",
  "tocos",
  "tocou",
  "todas",
  "todos",
  "toeis",
  "toesa",
  "toese",
  "toeso",
  "tofel",
  "tofes",
  "tofos",
  "togas",
  "toica",
  "toico",
  "toino",
  "toira",
  "toiro",
  "toita",
  "tojal",
  "tojos",
  "tolai",
  "tolam",
  "tolao",
  "tolar",
  "tolas",
  "tolaz",
  "tolda",
  "tolde",
  "toldo",
  "tolei",
  "tolem",
  "toler",
  "toles",
  "toleu",
  "tolha",
  "tolhe",
  "tolhi",
  "tolho",
  "tolia",
  "tolos",
  "tolus",
  "tomai",
  "tomam",
  "tomao",
  "tomar",
  "tomas",
  "tomba",
  "tombe",
  "tombo",
  "tomei",
  "tomem",
  "tomes",
  "tomio",
  "tomos",
  "tomou",
  "tonai",
  "tonal",
  "tonam",
  "tonar",
  "tonas",
  "tonca",
  "tonei",
  "tonel",
  "tonem",
  "toner",
  "tones",
  "tonga",
  "tongo",
  "tonha",
  "tonia",
  "tonis",
  "tonoa",
  "tonos",
  "tonou",
  "tonsa",
  "tonse",
  "tonso",
  "tonta",
  "tonto",
  "tonus",
  "toona",
  "topai",
  "topam",
  "topar",
  "topas",
  "topaz",
  "topei",
  "topem",
  "topes",
  "topoi",
  "topos",
  "topou",
  "toqua",
  "toque",
  "torai",
  "toral",
  "toram",
  "torao",
  "torar",
  "toras",
  "torax",
  "torba",
  "torca",
  "torce",
  "torci",
  "torco",
  "torda",
  "tordo",
  "torei",
  "torem",
  "tores",
  "torga",
  "torgo",
  "toria",
  "torio",
  "toris",
  "torma",
  "torna",
  "torne",
  "torno",
  "toros",
  "torou",
  "torpe",
  "torra",
  "torre",
  "torro",
  "torsa",
  "torso",
  "torta",
  "torto",
  "torva",
  "torve",
  "torvo",
  "tosai",
  "tosam",
  "tosao",
  "tosar",
  "tosas",
  "tosca",
  "tosco",
  "tosei",
  "tosem",
  "toses",
  "tosos",
  "tosou",
  "tosse",
  "tossi",
  "tosso",
  "tosta",
  "toste",
  "tosto",
  "total",
  "totas",
  "totem",
  "totos",
  "touca",
  "touco",
  "toura",
  "touri",
  "touro",
  "tours",
  "touta",
  "toxia",
  "traca",
  "trace",
  "traco",
  "trada",
  "trade",
  "trado",
  "traem",
  "traga",
  "trago",
  "traia",
  "traio",
  "trair",
  "trais",
  "traiu",
  "traja",
  "traje",
  "trajo",
  "trale",
  "trama",
  "trame",
  "tramo",
  "trana",
  "trane",
  "trano",
  "trans",
  "trapa",
  "trape",
  "trapo",
  "trara",
  "trata",
  "trate",
  "trato",
  "trava",
  "trave",
  "travo",
  "traze",
  "treco",
  "treda",
  "tredo",
  "trefo",
  "trela",
  "trele",
  "treli",
  "trelo",
  "trema",
  "treme",
  "tremi",
  "tremo",
  "trena",
  "trene",
  "treno",
  "trens",
  "trepa",
  "trepe",
  "trepo",
  "tresa",
  "treso",
  "treta",
  "treva",
  "trevo",
  "treze",
  "triai",
  "triam",
  "triar",
  "trias",
  "tribo",
  "trica",
  "trico",
  "tridi",
  "triei",
  "triem",
  "tries",
  "triga",
  "trigo",
  "trila",
  "trile",
  "trilo",
  "trina",
  "trine",
  "trini",
  "trino",
  "trios",
  "triou",
  "tripa",
  "tripe",
  "tripo",
  "trips",
  "trisa",
  "trise",
  "triso",
  "trita",
  "trito",
  "troai",
  "troam",
  "troar",
  "troas",
  "troca",
  "troce",
  "troco",
  "troei",
  "troem",
  "troes",
  "trofa",
  "troia",
  "troie",
  "troio",
  "trois",
  "trole",
  "trona",
  "trone",
  "trono",
  "trons",
  "troou",
  "tropa",
  "trope",
  "tropo",
  "trota",
  "trote",
  "troto",
  "trova",
  "trove",
  "trovo",
  "truao",
  "truca",
  "truco",
  "trude",
  "trudo",
  "trufa",
  "trufe",
  "trufo",
  "trupa",
  "trupe",
  "trupo",
  "truta",
  "truxu",
  "truza",
  "tsela",
  "tuaca",
  "tuaia",
  "tubai",
  "tubam",
  "tubar",
  "tubas",
  "tubei",
  "tubel",
  "tubem",
  "tuber",
  "tubes",
  "tubim",
  "tubis",
  "tubos",
  "tubou",
  "tucao",
  "tucho",
  "tucos",
  "tucum",
  "tudel",
  "tudum",
  "tufai",
  "tufam",
  "tufao",
  "tufar",
  "tufas",
  "tufei",
  "tufem",
  "tufes",
  "tufos",
  "tufou",
  "tugas",
  "tugem",
  "tuges",
  "tugia",
  "tugir",
  "tugis",
  "tugiu",
  "tugue",
  "tuias",
  "tuins",
  "tuira",
  "tuita",
  "tuite",
  "tuiua",
  "tuius",
  "tujam",
  "tujas",
  "tules",
  "tulha",
  "tulia",
  "tulio",
  "tumba",
  "tumbe",
  "tumbo",
  "tumor",
  "tunai",
  "tunal",
  "tunam",
  "tunar",
  "tunas",
  "tunco",
  "tunda",
  "tunde",
  "tundo",
  "tunei",
  "tunel",
  "tunem",
  "tunes",
  "tunga",
  "tungo",
  "tunos",
  "tunou",
  "tupaa",
  "tupam",
  "tupas",
  "tupem",
  "tupes",
  "tupia",
  "tupir",
  "tupis",
  "tupiu",
  "tuqua",
  "turar",
  "turas",
  "turba",
  "turbe",
  "turbo",
  "turca",
  "turco",
  "turfa",
  "turfe",
  "turge",
  "turgi",
  "turia",
  "turja",
  "turjo",
  "turma",
  "turna",
  "turne",
  "turno",
  "turra",
  "turre",
  "turro",
  "turus",
  "turva",
  "turve",
  "turvi",
  "turvo",
  "tusca",
  "tusco",
  "tussa",
  "tusso",
  "tusta",
  "tutia",
  "tutor",
  "tutti",
  "tutum",
  "tutus",
  "tweed",
  "twist",
  "uabui",
  "uacai",
  "uacas",
  "uacia",
  "uacus",
  "uados",
  "uafes",
  "uaias",
  "uaiba",
  "uaica",
  "uaioa",
  "uaios",
  "uaipi",
  "uales",
  "ualua",
  "uambe",
  "uamoi",
  "uanhi",
  "uania",
  "uapes",
  "uaria",
  "uaris",
  "uarus",
  "uassu",
  "uauas",
  "uaucu",
  "uaupe",
  "uaura",
  "ubaia",
  "ubari",
  "ubata",
  "ubera",
  "ubere",
  "ubero",
  "ubins",
  "ubira",
  "ubres",
  "ubucu",
  "uchao",
  "uchas",
  "ucila",
  "ucima",
  "ucola",
  "ucubu",
  "udora",
  "ufana",
  "ufane",
  "ufano",
  "ugado",
  "ugais",
  "ugara",
  "ugava",
  "uguei",
  "uguem",
  "ugues",
  "uhull",
  "uiape",
  "uiara",
  "uique",
  "uiras",
  "uista",
  "uiste",
  "uivai",
  "uivam",
  "uivar",
  "uivas",
  "uivei",
  "uivem",
  "uives",
  "uivos",
  "uivou",
  "ujara",
  "ujica",
  "ulana",
  "ulano",
  "ulcus",
  "uleda",
  "ulema",
  "uliia",
  "ulite",
  "ulmos",
  "ulnal",
  "ulnar",
  "ulnas",
  "uloma",
  "ulose",
  "ulrei",
  "ultor",
  "ultra",
  "uluco",
  "ulula",
  "ulule",
  "ululo",
  "ulvas",
  "umado",
  "umais",
  "umara",
  "umari",
  "umaua",
  "umava",
  "umbia",
  "umbla",
  "umblo",
  "umbra",
  "umbro",
  "umbus",
  "umeis",
  "umera",
  "umero",
  "umida",
  "umido",
  "umiri",
  "unaia",
  "unais",
  "unani",
  "unaus",
  "uncao",
  "uncia",
  "uncos",
  "uncus",
  "undai",
  "undar",
  "undos",
  "ungem",
  "unges",
  "ungia",
  "ungir",
  "ungis",
  "ungiu",
  "ungua",
  "ungue",
  "ungui",
  "unhai",
  "unham",
  "unhao",
  "unhar",
  "unhas",
  "unhei",
  "unhem",
  "unhes",
  "unhou",
  "uniam",
  "uniao",
  "unias",
  "unica",
  "unico",
  "unida",
  "unido",
  "unira",
  "unona",
  "unsia",
  "untai",
  "untam",
  "untar",
  "untas",
  "untei",
  "untem",
  "untes",
  "untos",
  "untou",
  "untre",
  "upado",
  "upais",
  "upara",
  "upava",
  "upeis",
  "upema",
  "uperu",
  "upupa",
  "uraca",
  "uraco",
  "uracu",
  "urago",
  "urali",
  "urana",
  "urane",
  "urano",
  "urato",
  "urbes",
  "urcas",
  "urceo",
  "urcos",
  "urdam",
  "urdas",
  "urdem",
  "urdes",
  "urdia",
  "urdim",
  "urdir",
  "urdis",
  "urdiu",
  "urdus",
  "ureda",
  "uredo",
  "ureia",
  "urena",
  "urese",
  "urgem",
  "urges",
  "urgia",
  "urgir",
  "urgis",
  "urgiu",
  "urica",
  "urico",
  "urida",
  "urina",
  "urine",
  "urino",
  "urite",
  "urjal",
  "urjam",
  "urjas",
  "urnas",
  "uropo",
  "urose",
  "urrai",
  "urram",
  "urrar",
  "urras",
  "urrei",
  "urrem",
  "urres",
  "urros",
  "urrou",
  "ursas",
  "ursos",
  "uruas",
  "uruba",
  "urubu",
  "urucu",
  "urupa",
  "urupe",
  "ururi",
  "urutu",
  "uruxi",
  "urzal",
  "urzes",
  "usada",
  "usado",
  "usais",
  "usara",
  "usava",
  "useis",
  "usina",
  "usine",
  "usino",
  "usita",
  "usite",
  "usito",
  "usmar",
  "usmas",
  "usmem",
  "usmou",
  "usnea",
  "ussia",
  "ustao",
  "ustes",
  "usual",
  "usura",
  "usure",
  "usuro",
  "utado",
  "utais",
  "utara",
  "utata",
  "uteis",
  "utena",
  "utero",
  "utota",
  "uvaca",
  "uvacu",
  "uvada",
  "uvaia",
  "uvais",
  "uveal",
  "uveas",
  "uviar",
  "uvias",
  "uvico",
  "uvida",
  "uvido",
  "uviou",
  "uvula",
  "vaali",
  "vacai",
  "vacal",
  "vacam",
  "vacao",
  "vacar",
  "vacas",
  "vacou",
  "vacua",
  "vacum",
  "vacuo",
  "vacus",
  "vadea",
  "vades",
  "vadia",
  "vadie",
  "vadio",
  "vafro",
  "vagai",
  "vagal",
  "vagam",
  "vagao",
  "vagar",
  "vagas",
  "vagem",
  "vagia",
  "vagir",
  "vagis",
  "vagiu",
  "vagoa",
  "vagos",
  "vagou",
  "vague",
  "vaiai",
  "vaiam",
  "vaiar",
  "vaias",
  "vaiei",
  "vaiem",
  "vaies",
  "vaija",
  "vaiou",
  "vaixa",
  "valai",
  "valam",
  "valao",
  "valar",
  "valas",
  "valda",
  "valdo",
  "valea",
  "valei",
  "valem",
  "valer",
  "vales",
  "valeu",
  "valga",
  "valgo",
  "valha",
  "valho",
  "valia",
  "valis",
  "valoa",
  "valor",
  "valos",
  "valou",
  "valsa",
  "valse",
  "valso",
  "valva",
  "vamos",
  "vampe",
  "vampi",
  "vance",
  "vanda",
  "vande",
  "vando",
  "vante",
  "vanza",
  "vaose",
  "vapor",
  "vaque",
  "varai",
  "varal",
  "varam",
  "varao",
  "varar",
  "varas",
  "varei",
  "varem",
  "vares",
  "varga",
  "varge",
  "varia",
  "varie",
  "vario",
  "varis",
  "variz",
  "varja",
  "varoa",
  "varos",
  "varou",
  "varra",
  "varre",
  "varri",
  "varro",
  "vasas",
  "vasca",
  "vasco",
  "vasol",
  "vasos",
  "vassa",
  "vasso",
  "vasta",
  "vaste",
  "vasto",
  "vatel",
  "vates",
  "vatio",
  "vaulo",
  "vaura",
  "vazai",
  "vazam",
  "vazao",
  "vazar",
  "vazas",
  "vazei",
  "vazem",
  "vazes",
  "vazia",
  "vazie",
  "vazio",
  "vazou",
  "veada",
  "veado",
  "vedai",
  "vedam",
  "vedar",
  "vedas",
  "vedei",
  "vedem",
  "vedes",
  "vedor",
  "vedos",
  "vedou",
  "vedra",
  "vedro",
  "veeme",
  "veiai",
  "veiam",
  "veiar",
  "veias",
  "veiei",
  "veiem",
  "veies",
  "veifa",
  "veiga",
  "veios",
  "veiou",
  "veira",
  "veiro",
  "vejam",
  "vejas",
  "velai",
  "velam",
  "velar",
  "velas",
  "velea",
  "velei",
  "velem",
  "veles",
  "velga",
  "velha",
  "velho",
  "velia",
  "velos",
  "velou",
  "veloz",
  "velum",
  "vemos",
  "venal",
  "venca",
  "vence",
  "venci",
  "venco",
  "venda",
  "vende",
  "vendi",
  "vendo",
  "venha",
  "venho",
  "venia",
  "venta",
  "vente",
  "vento",
  "venus",
  "vepsa",
  "verao",
  "veras",
  "veraz",
  "verba",
  "verbo",
  "verca",
  "verde",
  "verei",
  "verem",
  "veres",
  "verga",
  "verge",
  "vergo",
  "veria",
  "veril",
  "verio",
  "verme",
  "verna",
  "verne",
  "verno",
  "veros",
  "versa",
  "verse",
  "verso",
  "verta",
  "verte",
  "verti",
  "verto",
  "verve",
  "vesca",
  "vesco",
  "vesga",
  "vesgo",
  "vespa",
  "vessa",
  "vesse",
  "vesso",
  "vesta",
  "veste",
  "vesti",
  "vesto",
  "vetai",
  "vetam",
  "vetar",
  "vetas",
  "vetei",
  "vetem",
  "vetes",
  "vetor",
  "vetos",
  "vetou",
  "vexai",
  "vexam",
  "vexar",
  "vexas",
  "vexei",
  "vexem",
  "vexes",
  "vexou",
  "vezai",
  "vezam",
  "vezar",
  "vezas",
  "vezei",
  "vezem",
  "vezer",
  "vezes",
  "vezos",
  "vezou",
  "viada",
  "viado",
  "viage",
  "viaja",
  "viaje",
  "viajo",
  "viana",
  "vibal",
  "vibra",
  "vibre",
  "vibro",
  "vicai",
  "vicam",
  "vicar",
  "vicas",
  "vicei",
  "vicem",
  "vices",
  "vichi",
  "vicia",
  "vicie",
  "vicio",
  "vicoa",
  "vicor",
  "vicos",
  "vicou",
  "victo",
  "vidai",
  "vidam",
  "vidar",
  "vidas",
  "videi",
  "videm",
  "video",
  "vides",
  "vidou",
  "vidra",
  "vidre",
  "vidro",
  "vieis",
  "viela",
  "vielo",
  "viera",
  "vigai",
  "vigam",
  "vigar",
  "vigas",
  "vigei",
  "vigem",
  "viger",
  "viges",
  "vigeu",
  "vigia",
  "vigie",
  "vigil",
  "vigio",
  "vigna",
  "vigor",
  "vigou",
  "vigue",
  "vijam",
  "vijas",
  "vilao",
  "vilar",
  "vilas",
  "vileu",
  "vilia",
  "vilie",
  "vilio",
  "vilis",
  "vilta",
  "vilte",
  "vilto",
  "vimai",
  "vimam",
  "vimar",
  "vimas",
  "vimba",
  "vimei",
  "vimem",
  "vimes",
  "vimos",
  "vimou",
  "vinas",
  "vinca",
  "vinco",
  "vinda",
  "vinde",
  "vindo",
  "vinea",
  "vineo",
  "vinga",
  "vingo",
  "vinha",
  "vinho",
  "vinil",
  "vinis",
  "vinta",
  "vinte",
  "viola",
  "viole",
  "violo",
  "virai",
  "viral",
  "viram",
  "virao",
  "virar",
  "viras",
  "virei",
  "virem",
  "vires",
  "virga",
  "virgo",
  "viria",
  "viril",
  "viris",
  "virol",
  "virou",
  "virus",
  "visai",
  "visam",
  "visao",
  "visar",
  "visas",
  "visco",
  "visei",
  "visem",
  "vises",
  "visgo",
  "visor",
  "visos",
  "visou",
  "vispa",
  "vispe",
  "vispo",
  "visse",
  "vista",
  "viste",
  "visto",
  "vital",
  "vitia",
  "vitor",
  "vitre",
  "vitus",
  "viuva",
  "viuve",
  "viuvo",
  "vivam",
  "vivas",
  "vivaz",
  "vivei",
  "vivem",
  "viver",
  "vives",
  "viveu",
  "vivia",
  "vivio",
  "vivis",
  "vivos",
  "vizir",
  "vlogs",
  "voado",
  "voais",
  "voamo",
  "voara",
  "voava",
  "vocal",
  "voces",
  "vodca",
  "vodka",
  "vodus",
  "voeis",
  "voeja",
  "voeje",
  "voejo",
  "voemo",
  "vogai",
  "vogal",
  "vogam",
  "vogar",
  "vogas",
  "vogou",
  "vogue",
  "vogul",
  "voila",
  "voile",
  "volar",
  "volca",
  "volei",
  "volos",
  "volta",
  "volte",
  "volto",
  "volts",
  "volva",
  "volve",
  "volvi",
  "volvo",
  "vomer",
  "voras",
  "voraz",
  "vosco",
  "vossa",
  "vosso",
  "votai",
  "votam",
  "votar",
  "votas",
  "votei",
  "votem",
  "votes",
  "votos",
  "votou",
  "vovoa",
  "vovos",
  "vozao",
  "vozes",
  "vubas",
  "vudus",
  "vulga",
  "vulgo",
  "vulto",
  "vulva",
  "vunda",
  "vunza",
  "vunzo",
  "vurma",
  "vurmo",
  "weber",
  "xacra",
  "xador",
  "xaile",
  "xales",
  "xamas",
  "xampu",
  "xango",
  "xanto",
  "xaqua",
  "xaque",
  "xaras",
  "xarda",
  "xareu",
  "xaria",
  "xarus",
  "xaxim",
  "xelim",
  "xengo",
  "xenia",
  "xenio",
  "xenon",
  "xepas",
  "xeque",
  "xerem",
  "xeres",
  "xerez",
  "xerga",
  "xeros",
  "xerox",
  "xerva",
  "xetas",
  "xeura",
  "xexes",
  "xexeu",
  "xexos",
  "xibas",
  "xibio",
  "xibiu",
  "xicia",
  "xicos",
  "xicus",
  "xiita",
  "xilas",
  "xilol",
  "xilon",
  "xilos",
  "ximao",
  "ximbe",
  "ximbo",
  "xinga",
  "xingo",
  "xinta",
  "xinto",
  "xipos",
  "xiras",
  "xiria",
  "xiris",
  "xiroa",
  "xiros",
  "xirus",
  "xisto",
  "xixas",
  "xixis",
  "xocos",
  "xodoa",
  "xodos",
  "xonas",
  "xorca",
  "xotai",
  "xotam",
  "xotar",
  "xotas",
  "xotei",
  "xotem",
  "xotes",
  "xotou",
  "xoxos",
  "xuate",
  "xucro",
  "xungo",
  "xuria",
  "xuris",
  "xurus",
  "zabra",
  "zabro",
  "zacos",
  "zagal",
  "zagas",
  "zaibo",
  "zaina",
  "zaino",
  "zaira",
  "zamba",
  "zambe",
  "zambi",
  "zambo",
  "zampa",
  "zampe",
  "zampo",
  "zanga",
  "zango",
  "zanho",
  "zanza",
  "zanze",
  "zanzo",
  "zapes",
  "zarca",
  "zarga",
  "zargo",
  "zarpa",
  "zarpe",
  "zarpo",
  "zarra",
  "zarro",
  "zarza",
  "zebra",
  "zebre",
  "zebro",
  "zebus",
  "zefir",
  "zeina",
  "zelai",
  "zelam",
  "zelar",
  "zelas",
  "zelei",
  "zelem",
  "zeles",
  "zelos",
  "zelou",
  "zenam",
  "zenas",
  "zenda",
  "zenem",
  "zenes",
  "zenia",
  "zenis",
  "zeniu",
  "zerai",
  "zeram",
  "zerar",
  "zeras",
  "zerei",
  "zerem",
  "zeres",
  "zeros",
  "zerou",
  "zesto",
  "zetas",
  "zeuga",
  "zeugo",
  "zifio",
  "zimbo",
  "zinam",
  "zinas",
  "zinca",
  "zinco",
  "zinem",
  "zines",
  "zinga",
  "zingo",
  "zinho",
  "zinia",
  "zinir",
  "zinis",
  "ziniu",
  "ziper",
  "zipes",
  "zirbo",
  "zirro",
  "zizia",
  "zizie",
  "zizio",
  "zloti",
  "zoada",
  "zoado",
  "zoais",
  "zoara",
  "zoava",
  "zoeis",
  "zoica",
  "zoico",
  "zoide",
  "zoilo",
  "zoina",
  "zomba",
  "zombe",
  "zombo",
  "zomol",
  "zonai",
  "zonal",
  "zonam",
  "zonar",
  "zonas",
  "zonei",
  "zonem",
  "zones",
  "zonou",
  "zonza",
  "zonze",
  "zonzo",
  "zoose",
  "zopos",
  "zorna",
  "zorne",
  "zorno",
  "zoroa",
  "zoros",
  "zorra",
  "zorro",
  "zorta",
  "zorte",
  "zorto",
  "zotes",
  "zuais",
  "zuavo",
  "zuche",
  "zuiam",
  "zuias",
  "zuido",
  "zuira",
  "zulos",
  "zulus",
  "zumba",
  "zumbe",
  "zumbi",
  "zumbo",
  "zunam",
  "zunar",
  "zunas",
  "zunem",
  "zunes",
  "zunga",
  "zungo",
  "zunia",
  "zunir",
  "zunis",
  "zuniu",
  "zuras",
  "zurra",
  "zurre",
  "zurro",
  "zuruo",
  "zurza",
  "zurze",
  "zurzi",
  "zurzo",
  "todes",
];
